
import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

class DemonstrativoDimob extends React.Component {

	constructor(props) {
    	super(props)

		try{
			this.state = {
				contrato: {},
				locador: {},
				locatario: {},
				imovel: {},
				imobiliaria: {},
				meses: {},
				total: {},
				mensagem: 'Carregando ....',
				informacoes: props.match !== undefined ? jwt_decode.decode(props.match.params.identificador) : jwt_decode.decode(props.props),
			}
		}
		catch(erro){
			this.state = {
				contrato: {},
				locador: {},
				locatario: {},
				imovel: {},
				meses: {},
				total: {},
				mensagem: 'Carregando ....',
			}
			console.log(erro.message)
		}
		// console.log("Props",this.state.informacoes)
		// console.log("Props", jwt_decode.decode(props.props))
		// console.log("Props", props.match)
	}
	
	async componentDidMount(){
		await this.contrato()
		await this.dimob()

	}

	contrato = async () => {
		try{

			let contrato = await api_freedom.get(`/demonstrativo/contrato/detalhe/${this.state.informacoes.contrato_id}/${this.state.informacoes.imobiliaria_id ? this.state.informacoes.imobiliaria_id : 53}/${this.state.informacoes.pessoa_id}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
			if(contrato.data.data.indexOf('TOKEN') === -1){
				let locador = contrato.data.data[0].pessoas.filter((x) => {
					return x.tipo === 'Locador' && x.id === this.state.informacoes.pessoa_id
				})

				let locatario = contrato.data.data[0].pessoas.filter((x) => {
					return x.tipo === 'Locatário'
				})

				contrato.data.data[0].data_inicio = new Date(contrato.data.data[0].data_inicio).toLocaleDateString('pt-BR', {timeZone: 'UTC'})

				this.setState({ 
					contrato: contrato.data.data[0],
					imovel: contrato.data.data[0].imovel[0],
					locador: locador[0],
					locatario: locatario[0],
					imobiliaria: contrato.data.data[0].imobiliaria[0]
				})
			}

		}
		catch(error){
			console.log(error.message)
		}
	}

	dimobToPDF = async () => {

		const canvas = await html2canvas(document.getElementById('demonstrativoDimob'));
		const data = canvas.toDataURL("image/png");
		
		const pdf = new jsPDF("portrait", "mm", "a4");
		const imgProperties = pdf.getImageProperties(data);
		const pdfWidth = pdf.internal.pageSize.getWidth();
		
  	const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;


		console.log(imgProperties);
		console.log(pdfWidth, pdfHeight);
		

		pdf.addImage(data, 'JPEG', 0, 0, pdfWidth, pdfHeight);
		pdf.save("DemonstrativoDimob.pdf");

}

	dimob = async () => {
		try{

			let dimob = await api_freedom.get(`/financeiro/dimob/${this.state.informacoes.imobiliaria_id ? this.state.informacoes.imobiliaria_id : 53}/${this.state.informacoes.contrato_id}/${this.state.locador.id}/${this.state.informacoes.ano}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
			if(dimob.data.data.indexOf('TOKEN') === -1){

				let jan = dimob.data.data.filter((x) => {return x.mes === 1})[0]
				let fev = dimob.data.data.filter((x) => {return x.mes === 2})[0]
				let mar = dimob.data.data.filter((x) => {return x.mes === 3})[0]
				let abr = dimob.data.data.filter((x) => {return x.mes === 4})[0]
				let mai = dimob.data.data.filter((x) => {return x.mes === 5})[0]
				let jun = dimob.data.data.filter((x) => {return x.mes === 6})[0]
				let jul = dimob.data.data.filter((x) => {return x.mes === 7})[0]
				let ago = dimob.data.data.filter((x) => {return x.mes === 8})[0]
				let set = dimob.data.data.filter((x) => {return x.mes === 9})[0]
				let out = dimob.data.data.filter((x) => {return x.mes === 10})[0]
				let nov = dimob.data.data.filter((x) => {return x.mes === 11})[0]
				let dez = dimob.data.data.filter((x) => {return x.mes === 12})[0]
	
				let meses = {
					jan: jan,
					fev: fev,
					mar: mar,
					abr: abr,
					mai: mai,
					jun: jun,
					jul: jul,
					ago: ago,
					set: set,
					out: out,
					nov: nov,
					dez: dez,
				}

				let total = {
					rendimento_bruto: 0,
					valor_comissao: 0,
					imposto_retido: 0
				}

				if (meses.jan) {
					total.rendimento_bruto += parseFloat(meses.jan.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.jan.valor_comissao)
					total.imposto_retido += parseFloat(meses.jan.imposto_retido)
				}

				if (meses.fev) {
					total.rendimento_bruto += parseFloat(meses.fev.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.fev.valor_comissao)
					total.imposto_retido += parseFloat(meses.fev.imposto_retido)
				}

				if (meses.mar) {
					total.rendimento_bruto += parseFloat(meses.mar.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.mar.valor_comissao)
					total.imposto_retido += parseFloat(meses.mar.imposto_retido)
				}

				if (meses.abr) {
					total.rendimento_bruto += parseFloat(meses.abr.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.abr.valor_comissao)
					total.imposto_retido += parseFloat(meses.abr.imposto_retido)
				}

				if (meses.mai) {
					total.rendimento_bruto += parseFloat(meses.mai.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.mai.valor_comissao)
					total.imposto_retido += parseFloat(meses.mai.imposto_retido)
				}

				if (meses.jun) {
					total.rendimento_bruto += parseFloat(meses.jun.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.jun.valor_comissao)
					total.imposto_retido += parseFloat(meses.jun.imposto_retido)
				}

				if (meses.jul) {
					total.rendimento_bruto += parseFloat(meses.jul.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.jul.valor_comissao)
					total.imposto_retido += parseFloat(meses.jul.imposto_retido)
				}

				if (meses.ago) {
					total.rendimento_bruto += parseFloat(meses.ago.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.ago.valor_comissao)
					total.imposto_retido += parseFloat(meses.ago.imposto_retido)
				}

				if (meses.set) {
					total.rendimento_bruto += parseFloat(meses.set.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.set.valor_comissao)
					total.imposto_retido += parseFloat(meses.set.imposto_retido)
				}

				if (meses.out) {
					total.rendimento_bruto += parseFloat(meses.out.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.out.valor_comissao)
					total.imposto_retido += parseFloat(meses.out.imposto_retido)
				}

				if (meses.nov) {
					total.rendimento_bruto += parseFloat(meses.nov.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.nov.valor_comissao)
					total.imposto_retido += parseFloat(meses.nov.imposto_retido)
				}

				if (meses.dez) {
					total.rendimento_bruto += parseFloat(meses.dez.rendimento_bruto)
					total.valor_comissao += parseFloat(meses.dez.valor_comissao)
					total.imposto_retido += parseFloat(meses.dez.imposto_retido)
				}

				this.setState({ 
					dimob: dimob.data.data,
					meses: meses,
					total: total
				})
			}

		}
		catch(error){
			console.log(error.message)
		}
	}

	render() {
		return (
		<>
			<div className='demonstrativoDimob' id='demonstrativoDimob'>
				<div className="container" style={{color: '#000000'}}>
					<div style={{
						width: '100%',
						height: '100%',
						margin: '0',
						boxSizing: 'border-box',
						fontFamily: 'sans-serif',
						backgroundColor: '#f9f9f9',
						fontSize: '18px'}}>
						<div style={{
							width: '100%',
							padding: '0px 15px',
							paddingTop: '30px',
							marginLeft: 'auto',
							marginRight: 'auto',
							backgroundColor: '#fff',
							borderBottom: '1px solid #eee'
						}}>
							<div style={{display: 'flex'}}>
								<div style={{width: '70%'}}>
									<h3 style={{margin: '0', fontWeight: 'bold', fontSize: '34px'}}>
									MINISTÉRIO DA FAZENDA
									</h3>
									<p style={{fontSize: '2.5rem'}}>Secretaria da Receita Federal do Brasil</p>
								</div>
								<div style={{width: '30%'}}>
									<h3 style={{margin: '0', textAlign: 'end'}}>
										COMPROVANTE ANUAL DE RENDIMENTOS DE ALUGUEIS
										<p style={{fontSize: '2.5rem'}}>Ano-calendário {this.state.informacoes.ano}</p>
									</h3>
								</div>
							</div>

							<div style={{margin: '20px 0px 0px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td style={{margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333'}} colSpan="1">Beneficiário do Rendimento (Locador)</td>
									</tr>
								</table>
							</div>
							
							<div className='table-responsive'>
								<table className='table' style={{width: '100%'}}>
									<tr style={{backgroundColor: '#eee'}}>
										<th align="left" style={{fontSize: '14px', padding: '5px'}}>Nome/ Nome Empresarial</th>
										<td style={{fontSize: '14px'}}>{this.state.locador.nome}</td>
										<th align="left" style={{fontSize: '14px', padding: '5px'}}>CPF</th>
										<td style={{fontSize: '14px'}}>{this.state.locador.cpf_cnpj}</td>
										<th align="left" style={{fontSize: '14px', padding: '5px'}}></th>
										<td style={{fontSize: '14px'}}></td>
									</tr>
								</table>
							</div>

							<div style={{margin: '20px 0px 0px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td style={{margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333'}} colSpan="1">Fonte Pagadora (Locatário)</td>
									</tr>
								</table>
							</div>
							
							<div className='table-responsive'>
								<table className='table' style={{width: '100%'}}>
									<tr style={{backgroundColor: '#eee'}}>
										<th align="left" style={{fontSize: '14px', padding: '5px'}}>Nome/ Nome Empresarial</th>
										<td style={{fontSize: '14px'}}>{this.state.locatario.nome}</td>
										<th align="left" style={{fontSize: '14px', padding: '5px'}}>CPF</th>
										<td style={{fontSize: '14px'}}>{this.state.locatario.cpf_cnpj}</td>
										<th align="left" style={{fontSize: '14px', padding: '5px'}}></th>
										<td style={{fontSize: '14px'}}></td>
									</tr>
								</table>
							</div>

							<div style={{margin: '20px 0px 0px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td style={{margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333'}} colSpan="2">Rendimento (em reais (R$))</td>
									</tr>
								</table>
							</div>
							
							<div className='table-responsive'>
								<table className='table' style={{width: '100%'}}>
									<tr style={{backgroundColor: '#eee'}}>
										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Mês</th>
										<td style={{fontSize: '14px'}}></td>

										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Rendimento Bruto</th>
										<td style={{fontSize: '14px'}}></td>

										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Valor Comissão</th>
										<td style={{fontSize: '14px'}}></td>

										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Imposto Retido</th>
										<td style={{fontSize: '14px'}}></td>
										
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Janeiro</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.jan ? parseFloat(this.state.meses.jan.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }): 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.jan ? parseFloat(this.state.meses.jan.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.jan ? parseFloat(this.state.meses.jan.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#eee'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Fevereiro</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.fev ? parseFloat(this.state.meses.fev.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.fev ? parseFloat(this.state.meses.fev.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.fev ? parseFloat(this.state.meses.fev.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Março</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.mar ? parseFloat(this.state.meses.mar.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.mar ? parseFloat(this.state.meses.mar.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.mar ? parseFloat(this.state.meses.mar.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#eee'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Abril</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.abr ? parseFloat(this.state.meses.abr.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.abr ? parseFloat(this.state.meses.abr.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.abr ? parseFloat(this.state.meses.abr.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Maio</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.mai ? parseFloat(this.state.meses.mai.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.mai ? parseFloat(this.state.meses.mai.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.mai ? parseFloat(this.state.meses.mai.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#eee'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Junho</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.jun ? parseFloat(this.state.meses.jun.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.jun ? parseFloat(this.state.meses.jun.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.jun ? parseFloat(this.state.meses.jun.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Julho</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.jul ? parseFloat(this.state.meses.jul.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.jul ? parseFloat(this.state.meses.jul.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.jul ? parseFloat(this.state.meses.jul.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#eee'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Agosto</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.ago ? parseFloat(this.state.meses.ago.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.ago ? parseFloat(this.state.meses.ago.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.ago ? parseFloat(this.state.meses.ago.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Setembro</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.set ? parseFloat(this.state.meses.set.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.set ? parseFloat(this.state.meses.set.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.set ? parseFloat(this.state.meses.set.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#eee'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Outubro</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.out ? parseFloat(this.state.meses.out.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.out ? parseFloat(this.state.meses.out.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.out ? parseFloat(this.state.meses.out.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Novembro</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.nov ? parseFloat(this.state.meses.nov.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.nov ? parseFloat(this.state.meses.nov.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.nov ? parseFloat(this.state.meses.nov.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#eee'}}>
										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Dezembro</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{ this.state.meses.dez ? parseFloat(this.state.meses.dez.rendimento_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.dez ? parseFloat(this.state.meses.dez.valor_comissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>

										<td align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{this.state.meses.dez ? parseFloat(this.state.meses.dez.imposto_retido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
										<td style={{fontSize: '14px'}}></td>
									</tr>
									<tr style={{backgroundColor: '#fff'}}>
										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'center'}}>Total</th>
										<td style={{fontSize: '14px'}}></td>

										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{
											this.state.total.rendimento_bruto ? this.state.total.rendimento_bruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
										}</th>
										<td style={{fontSize: '14px'}}></td>

										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{
											this.state.total.valor_comissao ? this.state.total.valor_comissao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
										}</th>
										<td style={{fontSize: '14px'}}></td>

										<th align="left" style={{fontSize: '14px', padding: '5px', textAlign: 'end'}}>{
											this.state.total.imposto_retido ? this.state.total.imposto_retido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
										}</th>
										<td style={{fontSize: '14px'}}></td>
									</tr>
								</table>
							</div>

							<div style={{margin: '20px 0px 20px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td style={{margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333'}} colSpan="2">Informações Complementares</td>
									</tr>
								</table>
							</div>

							<div style={{margin: '0px 10px 50px 10px'}}>
								<div style={{width: '100%'}}>
									<p style={{fontSize: '14px'}}>{`CNPJ ${this.state.imobiliaria.cnpj}`}</p>
									<p style={{fontSize: '14px'}}>{this.state.imobiliaria.razao_social}</p>
									<p style={{fontSize: '14px'}}>{`${this.state.imobiliaria.endereco} ${this.state.imobiliaria.numero}, ${this.state.imobiliaria.bairro}, ${this.state.imobiliaria.cidade} ${this.state.imobiliaria.estado}, ${this.state.imobiliaria.cep}`}</p>
								</div>
								<div style={{width: '100%', margin: '20px 0px 0px 0px'}}>
									<p style={{fontSize: '14px'}}>Dados do Imóvel</p>
								</div>
								<div style={{width: '100%', display: 'flex'}}>
									<p style={{fontSize: '14px', width: '33%'}}>Contrato: {this.state.contrato.id}</p>
									<p style={{fontSize: '14px', width: '33%'}}>Início do Contrato: {this.state.contrato.data_inicio}</p>
									<p style={{fontSize: '14px', width: '33%'}}>Tipo de imóvel: {this.state.contrato.tipo_locacao}</p>
								</div>
								<div style={{width: '100%', margin: '0px 0px 0px 0px'}}>
									<p style={{fontSize: '14px', width: '100%'}}>Endereço do Imóvel: {this.state.imovel.endereco + ', ' + this.state.imovel.numero + ' - ' + this.state.imovel.bairro}</p>
								</div>
								<div style={{width: '100%', margin: '20px 0px 0px 0px', display: 'flex'}}>
									<p style={{fontSize: '14px', width: '20%'}}>UF: {this.state.imovel.estado}</p>
									<p style={{fontSize: '14px', width: '46%'}}>Municipio: {this.state.imovel.cidade}</p>
									<p style={{fontSize: '14px', width: '33%'}}>CEP: {this.state.imovel.cep}</p>
								</div>
								<div style={{width: '100%', margin: '20px 0px 0px 0px'}}>
									<p style={{fontSize: '14px'}}>Atenção:</p>
								</div>
								<div style={{width: '100%', display: 'flex'}}>
									<p style={{fontSize: '14px', width: '100%'}}>Para a inclusão na Declaração do Imposto de Renda Pessoa Física - DIRF dos rendimentos informados
									neste documento, certifique-se de que os mesmos não constam de outro comprovante emitido pela 
									fonte pagadora.</p>
								</div>
							</div>

							<div style={{margin: '20px 0px 20px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td style={{margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333'}} colSpan="2">Reponsável pelas Informações</td>
									</tr>
								</table>
							</div>

							<div style={{margin: '0px 10px 100px 10px'}}>
								<div style={{width: '100%', display: 'flex'}}>
									<p style={{fontSize: '14px', width: '33%', textAlign: 'center'}}>Nome</p>
									<p style={{fontSize: '14px', width: '33%', textAlign: 'center'}}>Data</p>
									<p style={{fontSize: '14px', width: '33%', textAlign: 'center'}}>Assinatura</p>
								</div>
							</div>

							<div style={{margin: '0px 10px 100px 10px'}}>
								<div style={{width: '100%', display: 'flex'}}>
									<p style={{fontSize: '14px', width: '100%', textAlign: 'end'}}>EPAR BUSINESS EXPERTS</p>
								</div>
							</div>
						</div>
					</div>
				{/* <button className='button btn__primary--outline'
					onClick={() => {
						this.dimobToPDF()
					}} 
					><i class="fas fa-file-pdf" />{" "}
					PDF
				</button> */}
				</div>
			</div>
		</>
		);
	}
}

export default DemonstrativoDimob;
