import React from 'react'
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'


class IndiceValoresPage extends React.Component {
    constructor(props){
        super(props)

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 

        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "ReadWriteMaster"){
            window.location.href = "/freedom/contratos"
        }

        this.state = {
            valores: [],
            mes: 1,
            ano: '',
            porcentagem: 0,
            indice: {},
            idEdit: -1
        }

        this.getValores()
    }

    getValores = async () => {
        let {data} = await api_freedom.get("/indice/valores/" + this.props.match.params.id , {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        let re = await api_freedom.get("/indice/" + this.props.match.params.id , {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(data.data.indexOf('TOKEN') === -1 && re.data.data.indexOf("TOKEN") === -1 && re.data.data.length > 0){

            this.setState({
                indice: re.data.data[0],
                valores: data.data,
            })
        }
    }

    formatDate = (d) => {
        if (!d) return d

        let [y, m] = d.split("-")

        return `${m}/${y}`
    }

    abrirModal = (id) => {
        var modal = document.getElementById(id);
        modal.style.display = "flex";
    }

    fecharModal = (id) => {
        var modal = document.getElementById(id);
        modal.style.display = "none";
    }

    twoZeros = (d) => d.toString().length == 1 ? `0${d}` : d

    saveValor = async () => {
        if (!this.state.ano || this.state.ano.length != 4 || !this.state.porcentagem){
            return alert("Preencha os campos")
        }

        let d = this.state.valores.find(e => e.mes_ano == `${this.state.ano}-${this.twoZeros(this.state.mes)}`)

        if (d){
            return alert("Mês já foi contemplado")
        }

        await api_freedom.post("/indice/valores/create", {
            mes: `${this.state.ano}-${this.twoZeros(this.state.mes)}`,
            porcentagem: this.state.porcentagem,
            id: this.props.match.params.id

        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        this.setState({
            mes: '1',
            ano: '',
            porcentagem: 0
        })

        this.fecharModal("modal_valores")
        this.getValores()
    }

    updateValor = async () => {
        if (!this.state.porcentagem){
            return alert("Preencha os campos")
        }

        await api_freedom.put("/indice/valores/" + this.state.idEdit, {
            porcentagem: this.state.porcentagem,

        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        this.setState({
            mes: '1',
            ano: '',
            porcentagem: 0
        })

        this.fecharModal("modal_valores_edit")
        this.getValores()
    }

    render(){
        return (
            <>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <p className="relatorio-title">Valores do indíce {this.state.indice.nome}</p>
                        </div>
                        <div>
                            <button className='tohidden btn__freedom btn--normal' style={{width: '150px'}} onClick={() => this.abrirModal('modal_valores')}>Adicionar valor</button>
                        </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>ID</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Porcentagem</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Mes ano</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Opções</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.valores.map(e => (
                                        <tr>
                                            <td className="tbody__text"><p>{e.id}</p></td>
                                            <td className="tbody__text"><p>{e.porcentagem}%</p></td>
                                            <td className="tbody__text"><p>{this.formatDate(e.mes_ano)}</p></td>
                                            <td>
                                                <select onChange={a => {
                                                    switch (a.target.value) {
                                                        case "1":
                                                            this.setState({
                                                                porcentagem: e.porcentagem,
                                                                idEdit: e.id
                                                            })

                                                            this.abrirModal("modal_valores_edit")
                                                            break;
                                                    
                                                        default:
                                                            break;
                                                    }
                                                }}>
                                                    <option value="0">Ações</option>
                                                    <option value="1">Editar</option>
                                                </select>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="content-modal" id="modal_valores_edit" style={{display: 'none'}}>
                        <div className="modal">
                            <div className="modal-head">
                                Editar valores
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    <div>
                                        <div style={{marginTop: '1%'}}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Porcentagem: </label><br/>
                                                        <div className="grupo__input">
                                                            <input type="number" value={this.state.porcentagem} onChange={a => this.setState({porcentagem: a.target.value})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-foot">
                                <button className='btn__freedom btn--active' style={{height: '30px', borderRadius: '50px'}} onClick={a => this.fecharModal('modal_valores_edit')}>Fechar</button>
                                <button className='btn__freedom btn--normal' style={{height: '30px', borderRadius: '50px'}} onClick={this.updateValor}>Editar</button>
                            </div>
                        </div>
                    </div>

                    <div className="content-modal" id="modal_valores" style={{display: 'none'}}>
                        <div className="modal">
                            <div className="modal-head">
                                Criar valores
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    <div>
                                        <div style={{marginTop: '1%'}}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Mês: </label><br/>
                                                        <div className="grupo__input">
                                                            <select value={this.state.mes} onChange={a => this.setState({mes: a.target.value})}>
                                                                <option value="1">Janeiro</option>
                                                                <option value="2">Fevereiro</option>
                                                                <option value="3">Março</option>
                                                                <option value="4">Abril</option>
                                                                <option value="5">Maio</option>
                                                                <option value="6">Junho</option>
                                                                <option value="7">Julho</option>
                                                                <option value="8">Agosto</option>
                                                                <option value="9">Setembro</option>
                                                                <option value="10">Outubro</option>
                                                                <option value="11">Novembro</option>
                                                                <option value="12">Dezembro</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Ano: </label><br/>
                                                        <div className="grupo__input">
                                                            <input type="number" value={this.state.ano} onChange={a => this.setState({ano: a.target.value})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Porcentagem: </label><br/>
                                                        <div className="grupo__input">
                                                            <input type="number" value={this.state.porcentagem} onChange={a => this.setState({porcentagem: a.target.value})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-foot">
                                <button className='btn__freedom btn--active' style={{height: '30px', borderRadius: '50px'}} onClick={a => this.fecharModal('modal_valores')}>Fechar</button>
                                <button className='btn__freedom btn--normal' style={{height: '30px', borderRadius: '50px'}} onClick={this.saveValor}>Criar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default IndiceValoresPage