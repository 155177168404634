import React, { useEffect, useState } from "react";
import moment from "moment";

const ModalErrosDimob = (props) => {

  const [erros, setErros] = useState(props.erros);


  const gerarCSV = () => {
    let csv = '"Contrato_ID";"Imovel_ID";"Municipio";"Estado";\n';

    for (const erro of erros) {
        csv += `"${erro.contrato_id}";"${erro.imovel_id}";"${erro.cidade}";"${erro.estado}";\n`
    }

    csv = csv.slice(0, csv.length - 1)
    
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `ErrosDimob_${moment().format("DD_MM_YYYY")}.csv`;
    hiddenElement.click();
}


  useEffect(() => {}, []);

  
  return (
    <>
    <div className="content-modal" id="modal" style={{display: "flex"}}>
        <div className="modal">

      <div className="modal-head">Ajuste o nome do(s) município(s)</div>
        <div className="modal-body">
          <div className="modal-scroll">
            <div>
              <label style={{margin: '0px 0px 10px 10px'}}>Total: {erros.length}</label>
            </div>
            <table>
              <thead>
                <tr>
                  <th>
                      <div class="thead__item">
                          <label>ID Contrato</label>
                      </div>
                  </th>
                  <th>
                      <div class="thead__item">
                          <label>ID Imóvel</label>
                      </div>
                  </th>
                  <th>
                      <div class="thead__item">
                          <label>Município</label>
                      </div>
                  </th>
                  <th>
                      <div class="thead__item">
                          <label>Estado</label>
                      </div>
                  </th>
                </tr>
              </thead>
            <tbody>
              {erros.map((item, index) => (
                <tr key={index}>
                  <td className="tbody__text">{item.contrato_id}</td>
                  <td className="tbody__text">{item.imovel_id}</td>
                  <td className="tbody__text">{item.cidade}</td>
                  <td className="tbody__text">{item.estado}</td>
                </tr>
              ))}
            </tbody>
            </table>

          </div>
        </div>
      <div className="modal-foot">
        <button
          className="button btn__primary--outline"
          onClick={props.fechar}
        >
          Fechar
        </button>
        <button
          className="button btn__primary"          
          style={{ width: "112px" }}
          onClick={gerarCSV}
        >
          Gerar CSV
        </button>
      </div>
      </div>
      </div>
    </>
  )
}

export default ModalErrosDimob