import React, { useState, useEffect, useRef } from "react";
import { api_freedom } from "../services/apis";
import jwt_decode from 'jwt-decode';

const HistoricoRepasseGarantido = () => {
    const [listaRepasses, setListaRepasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [months, setMonths] = useState(new Date().getMonth() + 1);
    const [years, setYears] = useState(new Date().getFullYear());

    useEffect(() => {
        if (years && months)
            buscaHistoricoRepasseGarantido();
    }, [years, months])

    const buscaHistoricoRepasseGarantido = async () => {
        try {
            setIsLoading(true);
            await api_freedom.get(`/boleto/repasse-garantido/historico/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}/${years}-${months}`,
                { headers: { "authorization": localStorage.getItem('autenticado_freedom') } }).then((repasses) => {
                    setListaRepasses(repasses.data.data);
                })

        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false);
        }
    }

    const capitalize = (string) => {
        var [ano, mes] = string.split("-");
        var data = new Date(`${ano}-${+mes + 1}`).toLocaleString('pt-BR', { month: 'long' })
        return data.charAt(0).toUpperCase() + data.slice(1)
    }

    const fotprint = () => {
        setTimeout(() => {
            window.print();
        }, 350);
    }

    const genCSV = () => {
        let csv = 'ID,Contrato,Valor,Destinatário,Locador,Data envio,Data repasse,Status,Mês ano\n';

        for (const e of listaRepasses) {
            console.log(e);
            csv += `"${e.id}","${e.contrato_id}","${e.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}","${e.name_destino}","${e.pessoa_nome} | ${e.cpf_cnpj}","${e.transferencia_enviada ? new Date(e.transferencia_enviada).toLocaleString() : "---"}","${e.payment_date ? new Date(e.payment_date).toLocaleDateString() : "---"}","${e.status}","${e.mes_ano.split("-")[1]}/${e.mes_ano.split("-")[0]}",\n`
        }

        csv = csv.slice(0, csv.length - 1)

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'historico_repasse.csv';
        hiddenElement.click();
    }

    const twoNumber = (d) => {
        return d.toString().length == 1 ? `0${d}` : d
    }

    const changeDate = (e) => {
        setMonths(e.split("-")[1]);
        setYears(e.split("-")[0]);
        // months.current = (e.split("-")[1]);
        // years.current = (e.split("-")[0]);
        buscaHistoricoRepasseGarantido();
    }

    return (<div>
        <div style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 8 }}>
            <p className="relatorio-title">Histórico de Repasse Garantido</p>
        </div>
        <div>
            <button className='tohidden btn__primary button' onClick={fotprint}>Imprimir relatório</button>
            <button className='tohidden btn__primary button' onClick={genCSV}>Gerar CSV</button>
            <br />
            <div className='grupo__campo__form grupo__campo--input--select'>
                <input type="month" value={years + "-" + twoNumber(months)} onChange={e => changeDate(e.target.value)} />
                {/* <input type="month" value={years.current + "-" + twoNumber(months.current)} onChange={e => changeDate(e.target.value)} /> */}
            </div>
        </div>
        <div>
            <div className='table mt-4'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                <div class="thead__item">
                                    <label># Contrato</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Mês Referência</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Valor Repassado</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Nome</label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && listaRepasses.length === 0 && (
                            <tr><th className='text-center' colSpan='8'>Nenhum registro encontrado.</th></tr>
                        )}
                        {isLoading && (
                            <tr><th className='text-center' colSpan='8'>Carregando...</th></tr>
                        )}

                        {listaRepasses.map((repasse, index) => (
                            <tr key={index} >
                                <td>{repasse.contrato_id}</td>
                                <td>{capitalize(repasse.mes_ano)}</td>
                                <td>{(repasse.valor || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td>{repasse.pessoa_nome}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}

export default HistoricoRepasseGarantido;