import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'

class RelatorioTransferenciaPage extends React.Component {
    constructor(props){
        super(props)

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 
        
        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            years: new Date().getFullYear(),
            months: new Date().getMonth() + 1,
            transferencias: []
        }

        this.getData()
    }

    getData = async () => {
        let {data} = await api_freedom.post(`/transferencia/relatoriotransferencia/${this.state.dados_login.imobiliaria_id}`, {
            mes_ano: this.state.years + "-" + this.twoNumber(this.state.months)
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(data.data.indexOf('TOKEN') === -1){
            this.setState({transferencias: data.data})
        }
    }

    twoNumber = (d) => {
        return d.toString().length == 1 ? `0${d}` : d
    }

    changeDate = (e) => {
        this.setState({
            months: e.split("-")[1],
            years: e.split("-")[0],
        }); 

        this.state.months = e.split("-")[1]
        this.state.years = e.split("-")[0]
        
        this.getData()
    }

    fotprint = () => {
        this.setState({inPrint: true})

        setTimeout(() => {
            window.print()

            this.setState({inPrint: false})
        }, 350);
    }

    genCSV = () => {
        let csv = 'ID,Contrato,Valor,Destinatário,Locador,Data envio,Data repasse,Taxa adm,Mês ano\n';

        for (const e of this.state.transferencias) {
            console.log(e);
            csv += `"${e.id}","${e.contrato_id}","${e.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}","${e.name_destino}","${e.pessoa_nome} | ${e.cpf_cnpj}","${e.transferencia_enviada ? new Date(e.transferencia_enviada).toLocaleString() : "---"}","${e.data_transferencia ? new Date(e.data_transferencia).toLocaleDateString() : "---"}","${e.taxa_adm ? e.taxa_adm.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '---'}","${e.mes_ano.split("-")[1]}/${e.mes_ano.split("-")[0]}",\n`
        }

        csv = csv.slice(0, csv.length - 1)
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'relatorio_transferencia.csv';
        hiddenElement.click();
    }

    render() {
        return <>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h2 style={{display: "none"}} className="toshow">Relatório de transferência</h2>
                    <div>
                        <button className='tohidden btn__primary button' onClick={this.fotprint}>Imprimir relatório</button>
                        <button className='tohidden btn__primary button' onClick={this.genCSV}>Gerar CSV</button>
                        <br/>
                        <div className='grupo__campo__form grupo__campo--input--select'>
                            <input type="month" value={this.state.years + "-" + this.twoNumber(this.state.months)} onChange={e => this.changeDate(e.target.value)}/>
                        </div> 
                    </div>
                </div>
                <div className='table mt-4'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>
                                    <div class="thead__item">
                                        <label>ID</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label># Contrato</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Valor</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Destinatário</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Locador</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Data envio</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Data repasse</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Taxa adm.</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Mês ano</label>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.transferencias.map(e => (
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.contrato_id}</td>
                                        <td>{e.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{e.name_destino}</td>
                                        <td>{e.pessoa_nome} | {e.cpf_cnpj}</td>
                                        <td>{e.transferencia_enviada ? new Date(e.transferencia_enviada).toLocaleString() : "---"}</td>
                                        <td>{e.data_transferencia ? new Date(e.data_transferencia).toLocaleDateString() : "---"}</td>
                                        <td>{e.taxa_adm ? (
                                            <>
                                                {
                                                    (e.taxa_adm.valor * e.percentagem / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                } ({
                                                    e.percentagem
                                                }%)
                                            </>
                                        ) : '---'}</td>
                                        <td>{e.mes_ano.split("-")[1]}/{e.mes_ano.split("-")[0]}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>    
            </div>
        </>
    }
}

export default RelatorioTransferenciaPage