import React, { useEffect, useState } from "react";
import moment from 'moment';
import { api_freedom } from '../services/apis'
import freedomLogo from '../assets/imgs/freedomLogo.png'
import eparImg from '../assets/imgs/epar.png'
import pdfMake, { async } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'


const data = [
    {
      nome: "Mousepad",
      qtdEstoque: 4,
      qtdVendido: 10,
    },
    {
      nome: "Teclado",
      qtdEstoque: 8,
      qtdVendido: 12,
    },
    {
      nome: "Monitor",
      qtdEstoque: 2,
      qtdVendido: 14,
    },
    {
      nome: "Mouse",
      qtdEstoque: 15,
      qtdVendido: 32,
    }  
  ]

const RelatorioSeguroPage = () => {
    const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-DD'));
    const [dataFinal, setDataFinal] = useState(moment().format('YYYY-MM-DD'));
    const [seguros, setSeguros] = useState([]);
    const [mensagem, setMensagem] = useState("Selecione uma das opções de pesquisa");
    const [loading, setLoading] = useState(true);
    const [dadosLogin, setDadosLogin] = useState(jwt_decode(localStorage.getItem('autenticado_freedom')));


    const getData = async () => {

        setLoading(true);
        setMensagem("Carregando ....");

        try {
          const dtIni = moment(dataInicial);
          const dtFim = moment(dataFinal);
          const anoIni = moment(dataInicial).format('YYYY')
          const anoFim = moment(dataFinal).format('YYYY')

          if(!dtIni.isValid() && !dtFim.isValid() || dataInicial === '' || parseInt(anoIni) < 1500 || parseInt(anoFim) < 1500){
            setMensagem("Nenhum registro encontrado.");
            setLoading(false);
            return;
          }
            
            const response = await api_freedom.get("/contrato/segudosend", {
                params: {
    
                    data_inicial: dataInicial,
                    data_final: dataFinal,
                    imob: dadosLogin.imobiliaria_id
    
                },
                headers: {"authorization": localStorage.getItem('autenticado_freedom')}
            })
    
            if (response.status === 200) {
                if(response.data.data.indexOf('TOKEN') === -1){
                    if (response.data.data.length === 0) {
                        setMensagem("Nenhum registro encontrado.");
                    }
                    setSeguros(response.data.data);
                    setLoading(false);
                }
            } else {
                setMensagem("Nenhum registro encontrado.");
                setLoading(false);
            }

        } catch (error) {
            
        }
        
    }

    const genCSV = () => {
        let csv = 'Contrato,Locador,Apólice,Seguradora,Vigência\n';

        for (const e of seguros) {
            let loc = e.pessoas.filter(y => y.tipo == "Locador").map(locador => `${locador.nome} | ${locador.cpf_cnpj}`)
            loc = loc.join(', ')
            csv += `"${e.id}","${loc}","${e.apolice}","${e.nome_seguradora}","${moment(e.vigencia).format("DD/MM/YYYY")}",\n`
        }

        csv = csv.slice(0, csv.length - 1)
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'relatorio_seguro.csv';
        hiddenElement.click();
    }

    useEffect(() => {
        getData();
    }, [dataInicial, dataFinal]);

    return (
        <>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                        {/* <button className='tohidden button btn__primary' onClick={gerarRelatorioPDF}>Imprimir relatório</button> */}
                        {/* <button className='tohidden button btn__primary' onClick={fotprint}>Imprimir relatório</button> */}
                        <button className='tohidden btn__primary button' onClick={(e) => {
                                var linkDemonstrativo = jwt.sign({
                                  pesquisa: {
                                    imobiliaria_id: dadosLogin.imobiliaria_id,
                                    data_inicial: dataInicial,
                                    data_final: dataFinal
                                  }
                                }, 'SEGUROS')
                                // this.relatorioToPDF(linkDemonstrativo, e);
                                window.open(`/freedom/relatorioseguropdf/${linkDemonstrativo}`, '_blank');
                            }} disabled={seguros.length === 0 ? true : false}>Imprimir relatório</button>
                        <button className='tohidden button btn__primary ml-2' onClick={genCSV}>Gerar CSV</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                        <p className="relatorio-title">Relatório de seguro</p>
                    </div> 
                    <div style={{padding: '0.2vw'}} className="d-flex">
                        <div class="grupo__campo ml-5">
                            <label>Data Início:</label>
                            <input style={{padding: "10px", width: '150px'}} type="date" class="grupo__campo__form grupo__campo--input--select" value={dataInicial} onChange={(e) => setDataInicial(e.target.value)} />
                        </div>
                        <div class="grupo__campo ml-5">
                            <label>Data Fim:</label>
                            <input style={{padding: "10px", width: '150px'}} type="date" class="grupo__campo__form grupo__campo--input--select" value={dataFinal} onChange={(e) => setDataFinal(e.target.value)} />
                        </div>
                    </div>
                </div>
                
                <div className='table mt-4'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Contrato</th>
                                <th>Locador</th>
                                <th>Apólice</th>
                                <th>Seguradora</th>
                                <th>Vigência</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading || seguros.length === 0 ? (
                                <tr><th className='text-center' colSpan='8'>{mensagem}</th></tr>
                                ) : (
                                seguros.map(e => (
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>
                                            {e.pessoas.filter(y => y.tipo == "Locador").map(locador => (
                                                <div key={locador.id_relacao}><label>{`${locador.nome} | ${locador.cpf_cnpj}`}</label><br/></div>
                                            ))}
                                        </td>
                                        <td>{e.apolice}</td>
                                        <td>{e.nome_seguradora}</td>
                                        <td>{moment(e.vigencia).format("DD/MM/YYYY")}</td>
                                    </tr>
                                )))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default RelatorioSeguroPage