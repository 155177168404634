import React, { useEffect, useState } from "react";
import { api_cep, api_freedom } from "../services/apis";
import jwt_decode from "jwt-decode";
import { cpf, cnpj } from "cpf-cnpj-validator";

const AlterarLocatario = (props) => {
  const [id, setId] = useState(props.locatario.id);
  const [cpfCnpj, setCpfCnpj] = useState(props.locatario.cpf_cnpj);
  const [nome, setNome] = useState(props.locatario.nome);
  const [rgIe, setRgIe] = useState(props.locatario.rg_ie);
  const [email, setEmail] = useState(props.locatario.email);
  const [telefone, setTelefone] = useState(props.locatario.telefone);
  const [celular, setCelular] = useState(props.locatario.celular);
  const [cep, setCep] = useState(props.locatario.cep);
  const [endereco, setEndereco] = useState(props.locatario.endereco);
  const [bairro, setBairro] = useState(props.locatario.bairro);
  const [cidade, setCidade] = useState(props.locatario.cidade);
  const [estado, setEstado] = useState(props.locatario.uf);
  const [numero, setNumero] = useState(props.locatario.numero);
  const [complemento, setComplemento] = useState(props.locatario.complemento);
  const [contratoId, setContratoId] = useState(props.locatarioContrato.id ? props.locatarioContrato.id : "");
  const [contrato, setContrato] = useState("");


  const verificarPessoa = async (cpf_cnpj) => {
    let pessoa = await api_freedom.post(
      `/pessoa/cpfcnpj`,
      {
        cpf_cnpj: cpf_cnpj,
        imobiliaria_id: jwt_decode(localStorage.getItem("autenticado_freedom"))
          .imobiliaria_id,
      },
      {
        headers: { authorization: localStorage.getItem("autenticado_freedom") },
      }
    );

    if (pessoa.data.data.length > 0) {
      return pessoa.data.data[0];
      
    }

    return false;
  };

  const pickEstado = (estado) => {
    switch(estado){
      case "AC": return "Acre"
      case "AL": return "Alagoas"
      case "AP": return "Amapá"
      case "AM": return "Amazonas"
      case "BA": return "Bahia"
      case "CE": return "Ceará"
      case "DF": return "Distrito Federal"
      case "ES": return "Espírito Santo"
      case "GO": return "Goiás"
      case "MA": return "Maranhão"
      case "MT": return "Mato Grosso"
      case "MS": return "Mato Grosso do Sul"
      case "MG": return "Minas Gerais"
      case "PA": return "Pará"
      case "PB": return "Paraíba"
      case "PR": return "Paraná"
      case "PE": return "Pernambuco"
      case "PI": return "Piauí"
      case "RJ": return "Rio de Janeiro"
      case "RN": return "Rio Grande do Norte"
      case "RS": return "Rio Grande do Sul"
      case "RO": return "Rondônia"
      case "RR": return "Roraima"
      case "SC": return "Santa Catarina"
      case "SP": return "São Paulo"
      case "SE": return "Sergipe"
      case "TO": return "Tocantins"
    }
  }

  const changeCepLocatario = (event) => {
    // this.preencheu(`locatario_cep`);
    if (event.target.value.replace(/\D/g, "").length === 8) {
    api_cep
        .get(`/${event.target.value.replace(/\D/g, "")}/json`)
        .then((dados_cep) => {
            setCep(dados_cep.data.cep);
            setEndereco(dados_cep.data.logradouro);
            setBairro(dados_cep.data.bairro);
            setCidade(dados_cep.data.localidade);
            setEstado(dados_cep.data.uf);
            setNumero("");
            setComplemento("");
        });
      } else {
        setEndereco("");
        setBairro("");
        setCidade("");
        setEstado("");
        setNumero("");
        setComplemento("");
      }
      setCep(event.target.value);
  };

  const alterarLocatario = async () => {

      try {
        await api_freedom.post(
          "/contratohaspessoa/update",
          {
            contrato_id: props.contratoId,
            pessoa_id: id,
            id: contratoId,
            tipo: "Locatário",
          },
          {
            headers: {
              authorization: localStorage.getItem("autenticado_freedom"),
            },
          }
        );

        alert("Locatário alterado com sucesso")
        props.fechar()
        props.atualizar()
      } catch(error) {
        props.fechar()
        console.log(error.message);
        alert("Ocorreu um erro");
      }
    }

  const preencheu = (name) => {
    if (document.getElementsByName(name)[0].classList.contains("form--error")) {
      document.getElementsByName(name)[0].classList.remove("form--error");
    }
  };

  const preencher = (name) => {
    if (
      !document.getElementsByName(name)[0].classList.contains("form--error")
    ) {
      document.getElementsByName(name)[0].classList.add("form--error");
    }
  };

  

    useEffect(() => {
      // console.log("",props);
      // verificarPessoa()
    }, 
    [cpfCnpj]);

    
    return (
      <>
      <div className="content-modal" id="modal" style={{display: "flex"}}>
          <div className="modal">

        <div className="modal-head">Alterar Locarário</div>
          <div className="modal-body">
            <div className="modal-scroll">
            <div style={{ marginTop: "1%" }}>
            <div className="grupo__campo col-md-4">
              {/* <div className="col-md-4"> */}
                <div className="grupo__campo" name={`locatario_cpf_cnpj`}>
                  <label style={{ width: "100%" }}>CPF/CNPJ: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      maxLength={18}
                      value={cpfCnpj}
                      onChange={async (event) => {
                        preencheu(`locatario_cpf_cnpj`);

                        var cpf_cnpj;
                        if (
                          event.target.value.replace(/[^\d]/g, "").length <= 11
                        ) {
                          cpf_cnpj = event.target.value
                            .replace(/[^\d]/g, "")
                            .replace(
                              /(\d{3})(\d{3})(\d{3})(\d{2})/,
                              "$1.$2.$3-$4"
                            );
                        } else {
                          cpf_cnpj = event.target.value
                            .replace(/[^\d]/g, "")
                            .replace(
                              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                              "$1.$2.$3/$4-$5"
                            );
                        }
                        setCpfCnpj(cpf_cnpj)

                        if (
                          event.target.value.replace(/[^\d]/g, "").length ===
                            11 ||
                          event.target.value.replace(/[^\d]/g, "").length === 14
                        ) {
                          if (
                            event.target.value.replace(/[^\d]/g, "").length ===
                              11 &&
                            !cpf.isValid(
                              event.target.value.replace(/[^\d]/g, "")
                            )
                          ) {
                            alert("CPF inválido");
                          }
                          if (
                            event.target.value.replace(/[^\d]/g, "").length ===
                              14 &&
                            !cnpj.isValid(
                              event.target.value.replace(/[^\d]/g, "")
                            )
                          ) {
                            alert("CNPJ inválido");
                          }

                          var pessoa = await verificarPessoa(cpf_cnpj);

                          if (pessoa) {
                              setId(pessoa.id)
                              setNome(pessoa.nome)
                              setRgIe(pessoa.rg_ie)
                              setEmail(pessoa.email)
                              setTelefone(pessoa.telefone)
                              setCelular(pessoa.celular)
                              setCep(pessoa.cep)
                              setEndereco(pessoa.endereco)
                              setBairro(pessoa.bairro)
                              setCidade(pessoa.cidade)
                              setEstado(pessoa.uf)
                              setNumero(pessoa.numero)
                              setComplemento(pessoa.complemento)
                              setContrato(contratoId)
                          } else{
                            setId("")
                            setNome("")
                            setRgIe("")
                            setEmail("")
                            setTelefone("")
                            setCelular("")
                            setCep("")
                            setEndereco("")
                            setBairro("")
                            setCidade("")
                            setEstado("")
                            setNumero("")
                            setComplemento("")
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              
            </div>
            <div className="row">
              <div className="d-flex">
                <div className="col-md-6 grupo__campo">
                  <label style={{ width: "100%" }}><strong>Nome: </strong>{nome}</label>
                  <label style={{ width: "100%", marginTop: 10 }}><strong>Endereço: </strong>{endereco}</label>
                  <label style={{ width: "100%", marginTop: 10 }}><strong>Complemento: </strong>{complemento}</label>
                  <label style={{ width: "100%", marginTop: 10 }}><strong>Cidade: </strong>{cidade}</label>
                  <label style={{ width: "100%", marginTop: 10 }}><strong>CEP: </strong>{cep}</label>
                  
                </div>
                <div className="col-md-6 w-100">
                  <label style={{ width: "100%", marginTop: 13 }}><strong>Email: </strong>{email}</label>
                  <label style={{ width: "100%", marginTop: 10}}><strong>Número: </strong>{numero}</label>
                  <label style={{ width: "100%", marginTop: 10}}><strong>Bairro: </strong>{bairro}</label>
                  <label style={{ width: "100%", marginTop: 10}}><strong>Estado: </strong>{pickEstado(estado)}</label>                 
                  
                </div>
              </div>
            </div>

            </div>
          </div>
        <div className="modal-foot">
          <button
            className="button btn__primary--outline"
            onClick={props.fechar}
          >
            Fechar
          </button>
          <button
            className="button btn__primary"
            onClick={alterarLocatario}
          >
            Alterar
          </button>
        </div>
        </div>
        </div>
      </>
    )
}

export default AlterarLocatario