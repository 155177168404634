
import React from "react"
import { api_freedom } from '../services/apis'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'
import '../assets/styles/relatorio.css'

class RelatorioRepassePdf extends React.Component {
	constructor(props) {
		super(props)

		try {
			this.state = {
				loading: false,
				inPrint: false,
				lista: [],
				dataMin: '',
				dataMax: '',
				pesquisa: props.match !== undefined ? jwt_decode.decode(props.match.params.identificador).pesquisa : jwt_decode.decode(props.props),
				servicoslist: [],
			}
		}
		catch (erro) {

		}

	}

	fotprint = () => {
		this.setState({ inPrint: true })

		setTimeout(() => {
			window.print()

			this.setState({ inPrint: false })
		}, 350);
	}

	formataEndereco = (item) => {
		let endereco = '';
		item.endereco ? endereco += item.endereco : endereco += '';
		item.numero ? endereco += ', ' + item.numero : endereco += '';
		item.complemento ? endereco += ', ' + item.complemento : endereco += '';
		item.bairro ? endereco += ', ' + item.bairro : endereco += '';
		item.cidade ? endereco += ', ' + item.cidade : endereco += '';
		item.estado ? endereco += ' - ' + item.estado : endereco += '';
		return endereco
	}

	getContratos = async () => {
		this.setState({ loading: true })
		const response = await api_freedom.post("/contrato/listbytime", {
			datainicio: this.state.pesquisa.data_inicio,
			datafim: this.state.pesquisa.data_fim,
			servicos: this.state.pesquisa.servicos,
			imob: this.state.pesquisa.imobiliaria_id
		}, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

		if (response.status === 200) {
			if (response.data.data.length > 0) {
				this.setState({
					lista: response.data.data,
					servicoslist: response.data.servicos,
					loading: false
				})
			} else {
				this.setState({
					loading: false,
					mensagem: 'Nenhum registro encontrado.'
				})
			}
		} else {
			this.setState({
				loading: false,
				mensagem: 'Nenhum registro encontrado.'
			})
		}

	}

	showMultipleServices(e, id) {
		let filterd = e.servicos.filter(x => x.servico == id)

		return filterd ? (
			<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>
				{
					filterd.map(j => (
						<>
							{parseFloat(j.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
						</>
					))
				}
			</td>
		) : '---';
	}

	convertMesAno = (d) => {
		if (!d) return d

		let [ano, mes] = d.split("-")

		return `${mes}/${ano}`
	}

	async componentDidMount() {
		await this.getContratos()
	}

	render() {
		return (
			<>
				<div className='relatorio_inadimplentes' id='relatorio_inadimplentes'>
					<div style={{ color: '#000000' }}>
						<div style={{
							width: '100%',
							height: '100%',
							margin: '0',
							boxSizing: 'border-box',
							fontFamily: 'sans-serif',
							backgroundColor: '#f9f9f9',
							fontSize: '18px'
						}}>
							<div style={{
								width: '100%',
								padding: '0px 15px',
								paddingTop: '30px',
								// marginLeft: 'auto',
								// marginRight: 'auto',
								backgroundColor: '#fff',
								borderBottom: '1px solid #eee'
							}}>
								<div style={{ display: 'flex' }}>
									<div style={{ width: '70%', marginTop: 'auto' }}>
										{/* <img src={epar} style={{marginLeft: '5rem'}} /> */}
										<img src={require('../assets/imgs/mobilelogo.png')} style={{ marginLeft: '5rem', marginTop: 5 }} alt="" width={50} />
										<img src={require('../assets/imgs/epar_texto.png')} style={{ marginLeft: '1rem' }} alt="" width={70} />
									</div>
									<div style={{ width: '10%' }}>
										<div>
											<p style={{ fontSize: '2rem' }}>NOME DA IMOBILIÁRIA:</p>
										</div>
										<div>
											<p style={{ fontSize: '2rem' }}>Data:</p>
										</div>
									</div>
									<div style={{ width: '20%' }}>
										<div>
											<p style={{ fontSize: '2rem' }}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>
										</div>
										<div>
											<p style={{ fontSize: '2rem' }}><strong>{moment().format('DD/MM/YYYY')}</strong></p>
										</div>
									</div>
								</div>

								<div style={{ margin: '20px 0px 0px 0px' }}>
									<table style={{ width: '100%' }}>
										<tr>
											<td className='text-center' style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '5px' }} colSpan={9}>
												<strong>{`
													RELATÓRIO ANÁLITICO MENSAL
													 - PERÍODO DE: ${moment(this.state.pesquisa.data_inicio).format('DD/MM/YYYY')} ATÉ ${moment(this.state.pesquisa.data_termino).format('DD/MM/YYYY')}
											`}</strong>
											</td>
											<td id="printPageButton" className='text-center' style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '5px' }} colSpan="2">
												{
													!this.state.loading ? (
														<button className='button btn__primary--outline'
															onClick={() => {
																this.fotprint()
															}}
														><i class="fas fa-file-pdf" />{" "}
															PDF
														</button>
													) : null
												}
											</td>
										</tr>
									</table>
								</div>

								<div className='table-responsive'>
									<table className='table_relatorio' style={{ width: '100%' }}>
										<thead>
											<tr>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>ITEM</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}># CONTRATO</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>ATIVO</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>VALOR BOLETO</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF' }}>VENCIMENTO BOLETO</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF' }}>BOLETO PAGO</th>
												{
													this.state.servicoslist.map(e => (
														[2, 17].includes(e.id) ? (null) : (
															<th style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>
																{e.nome.toUpperCase()}
															</th>
														)
													))
												}
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '200px', color: '#FFF' }}>TAXA DE ADMINISTRAÇÃO</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>TAXA DE CONTRATO</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>REPASSE DE ALUGUEL</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>DATA REPASSE</th>
												<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>COMPETÊNCIA</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.loading ? (
													<tr>
														<td className='text-center' style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '10px' }} colSpan={12}>
															Carregando...
														</td>
													</tr>
												) :
													this.state.lista.map((e, index) => (
														<tr id={'pop' + index} key={index} style={{ backgroundColor: '#CCC', wordBreak: 'break-word' }}>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{String(index + 1).padStart(String(this.state.lista.length).length, '0')}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.contrato_id}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.ativo == 1 ? "Sim" : "Não"}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.boleto_valor ? parseFloat(e.boleto_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "---"}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.boleto_data_vencimento ? moment(e.boleto_data_vencimento).format('DD/MM/YYYY') : "---"}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.pago ? "Sim, dia " + moment(e.pago).format('DD/MM/YYYY').toString() : "Não"}</td>
															{
																this.state.servicoslist.map(i => (
																	[2, 17].includes(i.id) ? (null) : (
																		this.showMultipleServices(e, i.id)
																	)
																))
															}

															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.servicos.find(x => x.servico == 17) ? parseFloat(e.servicos.find(x => x.servico == 17).valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "---"}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.perc_taxa_administracao || 0}%</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.servicos.find(x => x.servico == 2) ? parseFloat(e.servicos.find(x => x.servico == 2).valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "---"}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.payment_date ? moment(e.payment_date).format('DD/MM/YYYY') : '---'}</td>
															<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{this.convertMesAno(e.mes_ano)}</td>
														</tr>
													))
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}


export default RelatorioRepassePdf;
