
import React, { useEffect, useState } from "react"
import {api_freedom} from '../services/apis'
// import jwt_decode from 'jwt-decode'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'
import epar from '../assets/imgs/logo.png'

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import '../assets/styles/relatorio.css'
import { jwt } from 'jsonwebtoken';

class RelatorioContratos extends React.Component {

	// const [lista, setLista] = useState([]);
	// const [loading, setLoading] = useState(false);
	constructor(props) {
		super(props)

	try{
		this.state = {
			loading: false,
			inPrint: false,
			lista: [],
			dataMin: '',
			dataMax: '',
			pesquisa: props.match !== undefined ? jwt_decode.decode(props.match.params.identificador).pesquisa : jwt_decode.decode(props.props)
		}
	}
	catch(erro){
		
	}

}

fotprint = () => {
	this.setState({inPrint: true})

	setTimeout(() => {
			window.print()

			this.setState({inPrint: false})
	}, 350);
}

formataEndereco = (item) => {
	let endereco = '';
	item.endereco ? endereco += item.endereco : endereco += '';
	item.numero ? endereco += ', '+item.numero : endereco += '';
	item.complemento ? endereco += ', '+item.complemento : endereco += '';
	item.bairro ? endereco += ', '+item.bairro : endereco += '';
	item.cidade ? endereco += ', '+item.cidade : endereco += '';
	item.estado ? endereco += ' - '+item.estado : endereco += '';
	return endereco
}

	listarInadimplentes = async () => {
		try{
			this.setState({ loading: true })
				let inadimplencia = await api_freedom.get(`/contrato/`, {
					params: this.state.pesquisa,
					headers: {"authorization": localStorage.getItem('autenticado_freedom')}
				})
				if(inadimplencia.data.data.indexOf('TOKEN') === -1){
					let lista = []
					for(const item of inadimplencia.data.data) {
						let locadores = []
						let obj = Object.create(null)
						obj = item
						for(const pessoas of item.pessoas) {
							if(pessoas.tipo === 'Locador'){
								locadores.push(pessoas)
							} else {
								obj['locatario'] = pessoas
							}
						}
						obj['endereco_completo'] = this.formataEndereco(item)
						obj['locador'] = locadores
						lista.push(obj)
						delete item.pessoas
					}
					this.setState({ 
						lista: lista,
						dataMin: inadimplencia.data.data.periodo_data_min,
						dataMax: inadimplencia.data.data.periodo_data_max
					})
				}
				this.setState({ loading: false })
		}
		catch(error){
				console.log(error.message)
				this.setState({ loading: false })
		}
	}

	formataTipoContrato = () => {
		if(parseInt(this.state.pesquisa.ativo) === 1) return 'ATIVOS'
		else if(parseInt(this.state.pesquisa.ativo) === 0) return 'INATIVOS'
		else if(this.state.pesquisa.data_inicio && this.state.pesquisa.data_termino) return ' - PERÍODO DE: '+moment(this.state.pesquisa.data_inicio).format('DD/MM/YYYY')+' ATÉ '+moment(this.state.pesquisa.data_termino).format('DD/MM/YYYY')
		else return ''
	}

	async componentDidMount(){
		await this.listarInadimplentes()
	}

	render() {
	return (
		<>
			<div className='relatorio_inadimplentes' id='relatorio_inadimplentes'>
				<div style={{color: '#000000'}}>
					<div style={{
						width: '100%',
						height: '100%',
						margin: '0',
						boxSizing: 'border-box',
						fontFamily: 'sans-serif',
						backgroundColor: '#f9f9f9',
						fontSize: '18px'}}>
						<div style={{
							width: '100%',
							padding: '0px 15px',
							paddingTop: '30px',
							// marginLeft: 'auto',
							// marginRight: 'auto',
							backgroundColor: '#fff',
							borderBottom: '1px solid #eee'
						}}>
							<div style={{display: 'flex'}}>
								<div style={{width: '70%', marginTop: 'auto'}}>
									{/* <img src={epar} style={{marginLeft: '5rem'}} /> */}
									<img src={require('../assets/imgs/mobilelogo.png')} style={{marginLeft: '5rem', marginTop: 5}} alt="" width={50} />
									<img src={require('../assets/imgs/epar_texto.png')} style={{marginLeft: '1rem'}} alt="" width={70} />
								</div>
								<div style={{width: '10%'}}>
									<div>
										<p style={{fontSize: '2rem'}}>NOME DA IMOBILIÁRIA:</p>											
									</div>
									<div>
										<p style={{fontSize: '2rem'}}>Data:</p>
									</div>
								</div>
								<div style={{width: '20%'}}>
									<div>
										<p style={{fontSize: '2rem'}}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>											
									</div>
									<div>
										<p style={{fontSize: '2rem'}}><strong>{moment().format('DD/MM/YYYY')}</strong></p>
									</div>
								</div>
							</div>

							<div style={{margin: '20px 0px 0px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '5px'}} colSpan={9}>
											<strong>{`
													RELATÓRIO DE CONTRATOS 
													${this.formataTipoContrato()}
											`}</strong>
										</td>
										<td  id="printPageButton" className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '5px'}} colSpan="2">
											{
												!this.state.loading ? (
													<button className='button btn__primary--outline'
														onClick={() => {
															this.fotprint()
														}} 
														><i class="fas fa-file-pdf" />{" "}
														PDF
													</button>
												) : null
											}
										</td>
									</tr>
								</table>
							</div>
							
							<div className='table-responsive'>
								<table className='table_relatorio' style={{width: '100%'}}>
									<thead>
										<tr>
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>CÓDIGO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>VENCIMENTO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>PRAZO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF'}}>LOCADOR</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF'}}>LOCATÁRIO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '200px', color: '#FFF'}}>IMÓVEL</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>ALUGUEL</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>TX ADM %</th>			
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>TX ADM $</th>			
										</tr>
									</thead>
									<tbody>
										{
											this.state.loading ? (
												<tr>
													<td className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '10px'}} colSpan={12}>
														Carregando...
													</td>
												</tr>
											) :
											this.state.lista.map((item, index) => (
												<tr id={'pop'+index} key={index} style={{backgroundColor: '#CCC', wordBreak: 'break-word'}}>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{item.id}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{moment(item.data_fim).format('DD/MM/YYYY')}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>
														<div>{moment(item.data_inicio).format('DD/MM/YYYY')}</div>
														<div>{moment(item.data_fim).format('DD/MM/YYYY')}</div>
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '250px'}}>
														{
															item.locador.map(locador => (
																<>
																	<div>{locador.nome}</div>
																	<div>{locador.cpf_cnpj}</div>
																</>
															))
														}
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '250px'}}>
														<div>{item.locatario?.nome}</div>
														<div>{item.locatario?.cpf_cnpj}</div>
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '200px'}}>
														<div>{item.endereco_completo}</div>														
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{parseFloat(item.valor_aluguel).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{item.perc_taxa_administracao+'%'}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{parseFloat(item.valor_aluguel * (item.perc_taxa_administracao / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
								}
}


export default RelatorioContratos;
