
import React from "react"
import { Route, Switch, Link } from "react-router-dom"
import routes from "../routes.js"
import Menu from "../components/Menu"
import jwt_decode from 'jwt-decode'

class Sistema extends React.Component {

  constructor(props){
    super(props)
    try{
      this.state = {
        dados: jwt_decode(localStorage.getItem('autenticado_freedom')),
      }
    }
    catch(erro){
        this.state = {
          dados: {},
        }
        console.log(erro.message)
    }
    
  }

  componentDidMount(){

  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/freedom") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            name={prop.name}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <>
        {Object.keys(this.state.dados).length > 0 && <Menu />}
        <div className='box__content'>
          <div className="box__header">
              <p>{this.state.dados.imobiliaria}</p>
              <div>
                  <p>{this.state.dados.email}</p>
                  <Link className="out" to='/'>
                      <i className="fas fa-sign-out-alt"></i>
                      <p>Sair</p>
                  </Link>
              </div>
          </div>
          <div className='box__body'>
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
          <p>{process.env.REACT_APP_VERSION}</p>
        </div>
      </>
    );
  }
}

export default Sistema;
