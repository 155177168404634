
import React from "react"
import {api_freedom} from '../services/apis'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

class AlterarParcela extends React.Component {

  constructor(props) {
    super(props)
    if(!localStorage.getItem('autenticado_freedom')){
        props.history.push('/')
    } 

    this.state = {
        contrato: [],
        descricao_parcela: [],
        mensagem: 'Carregando ....',
        total_cobrancas: 0,
        acao: '',
        servicos: [],
        servico: '',
        valor_servico: 0,
        vencimento_servico: '',
        referencia_servico: '',
        mes_ano_servico: '',
        modal_titulo: '',
        modal_corpo: '',
        modal_botoes: ''
    }
    
  }

  componentDidMount(){
    this.contrato()
    this.cobrancas()
    this.servicos()
  }

  contrato = async () => {
    try{
        let contrato = await api_freedom.get(`/contrato/detalhe/${this.props.match.params.contrato_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        if(contrato.data.data.indexOf('TOKEN') === -1){
            this.setState({ contrato: contrato.data.data[0] })
            if(jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id !== contrato.data.data[0].imobiliaria_id){
                this.props.history.push('/freedom/contratos')
            }
        }
    }
    catch(error){
        console.log(error.message)
    }
  }
  
  cobrancas = async () => {
    try{
        let parcelas = await api_freedom.get(`/financeiro/idcontrato/${this.props.match.params.contrato_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        if(parcelas.data.data.indexOf('TOKEN') === -1){
            var parcela = parcelas.data.data.filter((p) => {
                return p.id === parseInt(this.props.match.params.parcela_id)
            })[0]
            if(parcela.boleto_id){
                alert('Boleto já gerado para essa parcela')
                this.props.history.push(`/freedom/parcelas/${this.props.match.params.contrato_id}`)
            }
            var descricao_parcela = parcelas.data.data.filter((p) => {
                return p.mes_ano === parcela.mes_ano
            })

            this.setState({ descricao_parcela: descricao_parcela, 
                            mensagem: 'Nenhum registro encontrado.'
                        })
        }
    }
    catch(error){
        console.log(error.message)
    }
  }

  servicos = async () => {
    try{
        let servicos = await api_freedom.get(`/servico/all`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        if(servicos.data.data.indexOf('TOKEN') === -1){
            this.setState({ servicos: servicos.data.data, 
                            servico: servicos.data.data.filter((s) => {
                                return s.automatico !== 1
                            })[0].id 
            })
        }
    }
    catch(error){
        console.log(error.message)
    }
  }

  listarDescricao = (record, index) => {

    var valor_boleto = 0
    this.state.descricao_parcela.map((d, i) => {
        if(i <= index){
            if(d.credito_debito === 1){
                valor_boleto += d.valor
            } 
            else{
                valor_boleto -= d.valor
            }
        }    
        return null
    })
    

    return (
        <tr key={index}>
            <td>{this.props.match.params.contrato_id}</td>
            <td>{moment(record.data_vencimento).utc(false).format('DD/MM/YYYY')}</td>
            <td>{record.referencia ? record.referencia : `${record.nome} ${record.pessoa_id ? ` para ${record.pessoa_nome}` : ''}`}</td>
            <td>{record.credito_debito === 1 ? record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
            <td style={{color: 'red'}}>{record.credito_debito === 0 ? `-${record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : ''}</td>
            <td>{valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
            <td>
                <button className='button btn__primary'
                    onClick={() => {
                        this.ajustesParcelaAlterarValor(record.id)
                    }} 
                >
                    <i className='fas fa-pen'/>
                </button>
                {record.nome === 'Aluguel' ? null : ( 
                    <button className='button btn__primary--outline'
                        onClick={() => {
                            this.setState({
                                modal_titulo:   `Excluir o serviço referente ${record.nome}`,
                                modal_corpo:    <div className='text-center' style={{padding: '2vw', fontSize: '1.1em'}}>
                                                    Deseja realmente prosseguir?
                                                </div>,
                                modal_botoes:   <>
                                                    <button className='button btn__primary--outline' onClick={() => {this.fecharModal()}}>Não</button>
                                                    <button className='button btn__primary' onClick={() => {this.fecharModal(); this.excluirServico(record.id)}}>Sim</button>
                                                </>
                            })
                            this.abrirModal()
                        }} 
                    ><i className='fas fa-times'/></button>
                )}
            </td>
        </tr>
    )
  }

  abrirModal = () => {
    var modal = document.getElementById('modal');
    modal.style.display = "flex";
  }

  fecharModal = () => {
    var modal = document.getElementById('modal');
    modal.style.display = "none";
    this.servicos()
    this.setState({valor_servico: 0, modal_titulo: '', modal_corpo: '', modal_botoes: ''})
  }

  ajustesParcela = (id) => {

    var vencimento = moment(this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

    var mes_ano = this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].mes_ano

    this.setState({
        vencimento_servico: vencimento,
        mes_ano_servico: mes_ano,
        modal_titulo: 'Concessão de serviços',
        modal_botoes: <>
            <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
            <button className='button btn__primary' onClick={this.salvarAjustes}>Salvar</button>
        </>,
        modal_corpo: <div>
            <div style={{marginTop: '1%'}}>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Serviço: </label><br/>
                    <select className='grupo__campo__form' onChange={(event) => {
                        this.setState({servico: event.target.value})
                    }}>
                        {this.state.servicos.filter((servico) => {
                            return servico.automatico !== 1 
                        }).map((s) => {
                            return (
                                <option key={s.nome} value={s.id}>{s.nome}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Valor a cobrar pelo serviço: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='number' defaultValue={0} min='0' onChange={(event) => {
                            this.setState({valor_servico: event.target.value})
                        }}/>
                    </div>    
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Referência do serviço: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='text' defaultValue={''} onChange={(event) => {
                            this.setState({referencia_servico: event.target.value})
                        }} placeholder='Exemplo: Serviço referente ao período 01/01/2020 a 31/01/2020'/>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Data de vencimento: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='date' value={vencimento} readOnly/>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Mês e ano de cobrança: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='month' value={mes_ano} readOnly/>
                    </div>
                </div>
            </div>
        </div> 
    })
    this.abrirModal()

  }

  ajustesParcelaAlterarValor = (id) => {

    var vencimento = moment(this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

    var mes_ano = this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].mes_ano

    var servico = this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].servico_id

    var referencia = this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].referencia

    var valor = this.state.descricao_parcela.filter((p) => {
        return p.id === id
    })[0].valor

    this.setState({
        vencimento_servico: vencimento,
        mes_ano_servico: mes_ano,
        modal_titulo: 'Concessão de serviços',
        modal_botoes: <>
            <button className='button btn__primary' onClick={this.fecharModal}>Fechar</button>
            <button className='button btn__primary' onClick={() => {this.alterarServico(id)}}>Salvar</button>
        </>,
        modal_corpo: <div>
            <div style={{marginTop: '1%'}}>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Serviço: </label><br/>
                    <select value={servico} className='grupo__campo__form' readOnly>
                        {this.state.servicos.map((s) => {
                            return (
                                <option key={s.nome} value={s.id}>{s.nome}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Valor a cobrar pelo serviço: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='number' defaultValue={valor} min='0' onChange={(event) => {
                            this.setState({valor_servico: event.target.value})
                        }}/>
                    </div>    
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Referência do serviço: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='text' value={referencia} placeholder='Exemplo: Serviço referente ao período 01/01/2020 a 31/01/2020'/>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Data de vencimento: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='date' value={vencimento} readOnly/>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Mês e ano de cobrança: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='month' value={mes_ano} readOnly/>
                    </div>
                </div>
            </div>
        </div> 
    })
    this.abrirModal()

  }

  salvarAjustes = async () => {
    if(this.state.valor_servico && parseFloat(this.state.valor_servico) !== 0){
        let financeiro = await api_freedom.post('/financeiro/create', {
            contrato_id: this.props.match.params.contrato_id,
            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
            servico_id: this.state.servico,
            data_vencimento: this.state.vencimento_servico,
            valor: this.state.valor_servico,
            referencia: this.state.referencia_servico,
            mes_ano: this.state.mes_ano_servico
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(financeiro.data.data.insertId){
            alert('Inserido com sucesso')
            this.fecharModal()
            this.contrato()
            this.cobrancas()
            this.servicos()
            this.setState({valor_servico: 0, referencia_servico: '', modal_titulo: '', modal_corpo: '', modal_botoes: ''})
        }
        else{
            alert('Ocorreu um erro, tente novamente')
        }
    }
    else{
        alert('Informe o valor cobrado para o serviço')
    }
}

excluirServico = async (id) => {

    let excluir = await api_freedom.post('/financeiro/delete', {
        id: id
    }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

    if(excluir.data.data.affectedRows && excluir.data.data.affectedRows === 1){
        this.contrato()
        this.cobrancas()
        this.servicos()
    }
    else{
        alert('Ocorreu um erro, tente novamente')
    }
    
}

alterarServico = async (id) => {
    if(this.state.valor_servico && parseFloat(this.state.valor_servico) !== 0){
        let alterar = await api_freedom.post('/financeiro/updatevalor', {
            id: id,
            valor: this.state.valor_servico
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        
        if(alterar.data.data.affectedRows && alterar.data.data.affectedRows === 1){
            alert('Alterado com sucesso')
            this.fecharModal()
            this.contrato()
            this.cobrancas()
            this.servicos()
            this.setState({valor_servico: 0, modal_titulo: '', modal_corpo: '', modal_botoes: ''})
        }
        else{
            alert('Ocorreu um erro, tente novamente')
        }
    }
    else{
        alert('Informe o valor cobrado para o serviço')
    }

  }
 
  render() {
    return (
      <>
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <label>Total de cobranças: {this.state.descricao_parcela.length}</label><br/>   
                </div>    
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className='btn__freedom btn--active' style={{width: '180px'}}
                        onClick={() => {
                            this.ajustesParcela(parseInt(this.props.match.params.parcela_id))
                        }} 
                    >Concessão de serviços</button>
                </div>
            </div>
            <div className='table mt-4'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th># Contrato</th>
                            <th>Data</th>
                            <th>Histórico / Descrição</th>
                            <th>Crédito</th>
                            <th>Débito</th>
                            <th>Saldo</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.descricao_parcela.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                        {this.state.descricao_parcela.map(this.listarDescricao)}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="content-modal" id="modal" style={{display: 'none'}}>
            <div className="modal">
                <div className="modal-head">
                    {this.state.modal_titulo}
                </div>
                <div className="modal-body">
                    <div className='modal-scroll'>
                        {this.state.modal_corpo}
                    </div>
                </div>
                <div className="modal-foot">
                    {this.state.modal_botoes}
                </div>
            </div>
        </div>
      </>
    );
  }
}

export default AlterarParcela;
