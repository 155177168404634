import React from 'react'
import { api_freedom } from '../services/apis'
import jwt from 'jsonwebtoken'


class RelatorioRepassePage extends React.Component {
    constructor(props){
        super(props)

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 

        let d = new Date()
        d.setMonth(d.getMonth() - 1)
        d = d.toISOString().split("T")[0]

        this.state = {
            servicos: [],
            servicosAtivos: {},
            dataInicio: d,
            dataFim: new Date().toISOString().split("T")[0],

            
            ano: new Date().getFullYear(), 
            mes: new Date().getMonth() + 1
        }

        this.getServicos()
    }

    getServicos = async () => {
        let {data} = await api_freedom.get("/servico/all", {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        
        if(data.data.indexOf('TOKEN') === -1){
            let s = {}
    
            for (const i of data.data) {
                s[i.id] = false
            }

            s[2] = true
            s[17] = true
    
            this.setState({
                servicos: data.data,
                servicosAtivos: s
            })
        }
    }

    openPrinter = () => {
        try {
            if (Number(this.state.ano) < 1000 || Number(this.state.ano) > 9999) {
                alert("Preencha um ano correto")
                return
            }
        } catch (error) {
            alert("Preencha um ano correto")
            return
        }

        let link = jwt.sign({ 
            datafim: this.state.dataFim,
            datainicio: this.state.dataInicio,
            servicos: Object.keys(this.state.servicosAtivos).filter(e => this.state.servicosAtivos[e])
        }, 'PRINTER')

        console.log(Object.keys(this.state.servicosAtivos).filter(e => this.state.servicosAtivos[e]));
        console.log(link);
        window.open("/freedom/imprimirrelatoriorepasse/" + link, "_blank")
    }

    render(){
        return <>
            <div>
                <div>
                    <p className="relatorio-title">Relatório de repasses</p>

                    <br />

                    <p className="mb-2">Período</p>
                    <div className="d-flex w-50">

                        <div className="grupo__campo">
                            <label>Data de inicio:</label>
                            <div class="grupo__campo__form grupo__campo--input--select ml-2">
                                <input type="date" value={this.state.dataInicio} class="grupo__input" onChange={a => this.setState({dataInicio: a.target.value})}/>
                            </div>
                        </div>

                        <div className="grupo__campo">
                            <label>Data final</label>
                            <div class="grupo__campo__form grupo__campo--input--select ml-2">
                                <input type="date" value={this.state.dataFim} class="grupo__input" onChange={a => this.setState({dataFim: a.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <p className="mb-3 mt-4">Serviços</p>
                    <div className="row w-100 mb-4">
                        {
                            this.state.servicos.map(e => (
                                [2, 17].includes(e.id) ? (null) : (
                                    <div class="col-md-4 d-flex">
                                        <label class="mb-0 checkbox-label">
                                            <input type="checkbox" value={e.id} onChange={a => this.state.servicosAtivos[e.id] = a.target.checked}/>
                                            <span class="checkbox-custom rectangular"></span>
                                        </label>
                                        <label className="ml-3">{e.nome}</label>
                                    </div>
                                )
                            ))
                        }
                    </div>

                    <button className="btn__primary button ml-2" onClick={this.openPrinter}>Ver relatório</button>
                </div>
            </div>
        </>
    }
}

export default RelatorioRepassePage