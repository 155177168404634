
import React from "react"
import {api_freedom} from '../services/apis'
// import jwt_decode from 'jwt-decode'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'
import '../assets/styles/relatorio.css'

class RelatorioExtratoImob extends React.Component {

	// const [lista, setLista] = useState([]);
	// const [loading, setLoading] = useState(false);
	constructor(props) {
		super(props)

	try{
		const payload = props.match !== undefined ? jwt_decode.decode(props.match.params.identificador) : jwt_decode.decode(props.props)
		this.state = {
			loading: false,
			inPrint: false,
			imobiliaria_id: payload?.imobiliaria_id,
			balanco: 0,
			contratos: [],
			data_inicio: payload?.data_inicio,
			data_fim: payload?.data_fim,
			extrato: [],
			// contratos: payload?.contratos,
			// data_inicio: payload?.data_inicio,
			// data_fim: payload?.data_fim,
			// extrato: payload?.extrato,
			// saldo: payload?.extrato[0].EntryValue
		}
	}
	catch(erro){
		
	}

}

fotprint = () => {
	this.setState({inPrint: true})

	setTimeout(() => {
			window.print()

			this.setState({inPrint: false})
	}, 350);
}

calculaSaldo = async (extrato) => {
	let ext = []
	let saldo = 0.00;
	for(const item of extrato) {
		let obj = Object.create(null)
		obj = item
		saldo += item.EntryValue
		obj['saldo'] = saldo.toFixed(2)
		ext.push(obj)
	}
	return ext
}

getExtrato = async () => {
	try {
		const re = await api_freedom.post("/extrato/buscarestrato/", {
				id: this.state.imobiliaria_id,
				dataInicio: this.state.data_inicio,
				dataFim: this.state.data_fim,
		}, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
		if (re.data.data.toString().indexOf('TOKEN') === -1) {
				if (re.data.data.Success == "true") {
						let entradas = JSON.parse(re.data.data.Entry)
						this.setState({
								extrato: await this.calculaSaldo(entradas),
								contratos: re.data.contratos,
								balanco: re.data.data.Balance,
								saldo: entradas[0].EntryValue
						})
				}
		}
	} catch (error) {
		alert(error);
	}

}

async componentDidMount() {
	await this.getExtrato()
}

	render() {
	return (
		<>
			<div className='relatorio_inadimplentes' id='relatorio_inadimplentes'>
				<div style={{color: '#000000'}}>
					<div style={{
						width: '100%',
						height: '100%',
						margin: '0',
						boxSizing: 'border-box',
						fontFamily: 'sans-serif',
						backgroundColor: '#f9f9f9',
						fontSize: '18px'}}>
						<div style={{
							width: '100%',
							padding: '0px 15px',
							paddingTop: '30px',
							backgroundColor: '#fff',
							borderBottom: '1px solid #eee'
						}}>
							<div style={{display: 'flex'}}>
								<div style={{width: '70%', marginTop: 'auto'}}>
									<img src={require('../assets/imgs/mobilelogo.png')} style={{marginLeft: '5rem', marginTop: 5}} alt="" width={50} />
									<img src={require('../assets/imgs/epar_texto.png')} style={{marginLeft: '1rem'}} alt="" width={70} />
								</div>
								<div style={{width: '10%'}}>
									<div>
										<p style={{fontSize: '2rem'}}>NOME DA IMOBILIÁRIA:</p>											
									</div>
								</div>
								<div style={{width: '20%'}}>
									<div>
										<p style={{fontSize: '2rem'}}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>											
									</div>
								</div>
							</div>

							<div style={{margin: '20px 0px 0px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '5px'}} colSpan={9}>
											<strong>{`
													CONCILIAÇÃO BANCÁRIA - PERÍODO DE ${moment(this.state.data_inicio).format('DD/MM/YYYY')} ATÉ ${moment(this.state.data_fim).format('DD/MM/YYYY')}
											`}</strong>
										</td>
										<td  id="printPageButton" className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '5px'}} colSpan="2">
											{
												!this.state.loading ? (
													<button className='button btn__primary--outline'
														onClick={() => {
															this.fotprint()
														}} 
														><i class="fas fa-file-pdf" />{" "}
														PDF
													</button>
												) : null
											}
										</td>
									</tr>
								</table>
							</div>
							
							<div className='table-responsive'>
								<table className='table_relatorio' style={{width: '100%'}}>
									<thead>
										<tr>
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>DATA</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>HISTÓRICO/ DESCRIÇÃO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>TAG #</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>#CONTRATO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>CRÉDITO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF'}}>DÉBITO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF'}}>SALDO</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.loading ? (
												<tr>
													<td className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '10px'}} colSpan={12}>
														Carregando...
													</td>
												</tr>
											) :
											this.state.extrato.map((item, index) => (
												<tr key={index}>
													{console.log(item)}
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{moment(item.EntryDate).format("DD/MM/YYYY - HH:MM")}</td>
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'left'}}>{item.Details}</td>
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{item.Tags.length > 0 ? item.Tags[0] : '---'}</td>
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{this.state.contratos[item.DocumentNumber] ? this.state.contratos[item.DocumentNumber].contrato_id :'---'}</td>
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'right'}}>{item.EntryValue > 0 ? item.EntryValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'right', color: 'red'}}>{item.EntryValue < 0 ? item.EntryValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
													<td style={{fontSize: '12px', padding: '5px', textAlign: 'right'}}>{parseFloat(item.saldo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
								}
}


export default RelatorioExtratoImob;
