import React, { useEffect, useState } from "react";
import moment from "moment";
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'

import { api_freedom } from "../services/apis"

const ModalEnviarDimobs = ({ fechar, dados }) => {
  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  
  const [dadosLocatario, setDadosLocatario] = useState('');
  const [imobiliaria, setImobiliaria] = useState(false);
  const [locador, setLocador] = useState(false);
  const [dadosOutro, setDadosOutro] = useState('');
  const [outro, setOutro] = useState(false);

  const EnviarEmail = async () => {
    setLoading(true)
    let arrayDados = []
    for(const pdf of dados) {
      arrayDados.push({
        dados: pdf,
        link: jwt.sign({ 
          ano: pdf.ano,
          contrato_id: pdf.contrato_id,
          pessoa_id: pdf.pessoa_id,
          imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
        }, 'DIMOB')
      })
    }

    try {
      const response = await api_freedom.post(`/sendmaildimob`, 
        {
          imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
          para: imobiliaria ? 'imobiliaria' : locador ? 'locador' : outro ? dadosOutro : null,
          dados_envio: arrayDados
        }
      , {headers: {"authorization": localStorage.getItem("autenticado_freedom")}})
  
      if(response.status === 200){
        alert(response.data.message);
        fechar()
      }
      setLoading(false)
    } catch (error) {
      alert(error.message);
      setLoading(false)
    }
  }


  useEffect(() => {
  }, []);

  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div className="modal">
          <div className="modal-head">Enviar Dimob(s)</div>
          <div className="modal-body">
            <div className="modal-scroll" style={{minHeight: '250px'}}>

              <div className='row'>
                <div className="col-md-10">
                  <div className="grupo__campo d-flex">
                    <div className="mt-auto mb-3">
                      <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                        <input type="checkbox" name="check" checked={imobiliaria} onChange={(e)=>{
                          setImobiliaria(e.target.checked)
                          if(e.target.checked === true) {
                            if(locador) {
                              setLocador(false)
                            }
                            if(outro) {
                              setOutro(false)
                            }
                          }
                        }} />
                        <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                    <div className="w-100">
                      <h7 style={{ width: "100%" }}>Imobiliária: </h7>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className='row'>
                <div className="col-md-10">
                  <div className="grupo__campo d-flex">
                    <div className="mt-auto mb-3">
                      <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                        <input type="checkbox" name="check" checked={locador} onChange={(e)=> {
                          setLocador(e.target.checked)
                          if(e.target.checked === true) {
                            if(imobiliaria) {
                              setImobiliaria(false)
                            }
                            if(outro) {
                              setOutro(false)
                            }
                          }
                        }} />
                        <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                    <div className="w-100">
                      <h7 style={{ width: "100%" }}>Locador: </h7>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-md-10">
                  <div className="grupo__campo d-flex w-100">
                    <div className="mt-auto mb-3">
                      <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                        <input type="checkbox" name="check" checked={outro} onChange={(e)=> {
                          setOutro(e.target.checked)
                          if(e.target.checked === true) {
                            if(imobiliaria) {
                              setImobiliaria(false)
                            }
                            if(locador) {
                              setLocador(false)
                            }
                          }
                        }} />
                        <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                    <div className="w-100">
                      <label style={{ width: "100%" }}>Outro: </label>
                      <br />
                      <div className="grupo__campo__form">
                        <input
                          type="email"
                          value={dadosOutro}
                          onChange={(event) => {
                            setDadosOutro(event.target.value);
                          }}
                          disabled={!outro}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={fechar}
            >
              Fechar
            </button>
            <button
              className="button btn__primary"
              disabled={imobiliaria || locador || dadosOutro.length > 0 || !loading ? false : true}
              onClick={()=>EnviarEmail()}
            >
              Enviar
            </button>
          </div>
        </div>
        <div className="content-modal-loading" style={{display: loading ? 'flex' : 'none'}}>
          <div className="modal" style={{width:"auto"}}>
              <img src={require("../assets/imgs/loading.gif")} alt="" style={{height: "200px"}}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEnviarDimobs;
