
import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../routes.js"
import jwt_decode from 'jwt-decode'

class Sistema extends React.Component {

  constructor(props){
    super(props)
    try{
      this.state = {
        dados: jwt_decode(localStorage.getItem('autenticado_freedom')),
      }
    }
    catch(erro){
        this.state = {
          dados: {},
        }
        console.log(erro.message)
    }
    
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/imprimir") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            name={prop.name}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <>
        <Switch>{this.getRoutes(routes)}</Switch>
      </>
    );
  }
}

export default Sistema;
