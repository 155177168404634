import React, { useEffect, useState } from "react";
import moment from "moment";

import { api_freedom } from "../services/apis"

const ModalEnviarDocumentos = (props) => {
  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mensagem, setMensagem] = useState('');
  
  const [dadosLocatario, setDadosLocatario] = useState('');
  const [locatario, setLocatario] = useState(false);
  const [dadosLocador, setDadosLocador] = useState('');
  const [locador, setLocador] = useState(false);
  const [dadosOutro, setDadosOutro] = useState('');
  const [outro, setOutro] = useState(false);

  const LoadDocs = async () => {

    setLoading(true);
    
    try {
      setMensagem('Carregando...');
      
      setDocumentos(props.dados);  
      GetContrato();   

      setLoading(false);
      
    } catch (error) {
      setMensagem('Erro ao carregar o(s) documento(s)');
      setLoading(false);
    }
    
  }

  const GetContrato = async () => {
    const response = await api_freedom.get(`/contrato`, {headers: {"authorization": localStorage.getItem("autenticado_freedom")},
      params:{
        contrato_id: props.dados[0].contrato_id,
        imobiliaria_id: props.dados[0].imobiliaria_id,
        pagina: 1,
        limite: 10
      }})

      let arrayLocador = '';
      response.data.data.data[0].pessoas.map(item => {
        if(item.tipo === 'Locatário') setDadosLocatario(item.email.replace(",","")+'; ');
        else arrayLocador += item.email.replace(",","").replace(" ","")+'; ';
      })
      setDadosLocador(arrayLocador);
  }

  const EnviarEmail = async () => {
    let emails = '';
    if(locatario){
      emails += dadosLocatario.trim();
    }
    if(locador){
      emails += dadosLocador.trim();
    }
    if(outro){
      emails += dadosOutro.trim();
    }

    if(emails === ''){
      alert("Escolha um email para o envio do arquivo.");
      return 0
    }

    const response = await api_freedom.post(`/docs/sendmail`, {
      documentos: documentos,
      email: emails,
      contrato_id: props.dados[0].contrato_id,
      imobiliaria_id: props.dados[0].imobiliaria_id,
    }, {headers: {"authorization": localStorage.getItem("autenticado_freedom")}})

    if(response.data.data[0].response.Success === 'true'){
      alert("Documento enviado com sucesso.");
      props.fechar()
    } else{
      alert('Ocorreu um erro, tente enviar o documento novamente');
      props.fechar()
    }

  }


  useEffect(() => {
    LoadDocs();
  }, []);

  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div className="modal">
          <div className="modal-head">Enviar Documento(s)</div>
          <div className="modal-body">
            <div className="modal-scroll" style={{minHeight: '250px'}}>

              <div className="table mt-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Adicionado</th>
                    </tr>
                  </thead>
                  <tbody>
                      {loading || documentos.length === 0 ? (
                        <tr><th className='text-center' colSpan='8'>{mensagem}</th></tr>
                        ) : documentos.map((item, index) => (
                          <tr key={index}>
                            <td className="tbody__text">{item.nome_arquivo}</td>
                            <td className="tbody__text">{moment(item.data_adicionado).format("DD/MM/YYYY")}</td>
                          </tr>
                        ))
                      }
                  </tbody>
                </table>
              </div>

              <div className='row'>
                <div className="col-md-10">
                  <div className="grupo__campo d-flex">
                    <div className="mt-auto mb-3">
                      <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                        <input type="checkbox" name="check" checked={locatario} onChange={(e)=>setLocatario(e.target.checked)} />
                        <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                    <div className="w-100">
                      <label style={{ width: "100%" }}>Locatário: </label>
                      <br />
                      <div className="grupo__campo__form">
                        <input
                          type="text"
                          value={dadosLocatario}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className='row'>
                <div className="col-md-10">
                  <div className="grupo__campo d-flex">
                    <div className="mt-auto mb-3">
                      <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                        <input type="checkbox" name="check" checked={locador} onChange={(e)=>setLocador(e.target.checked)} />
                        <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                    <div className="w-100">
                      <label style={{ width: "100%" }}>Locador: </label>
                      <br />
                      <div className="grupo__campo__form">
                        <input
                          type="text"
                          value={dadosLocador}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-md-10">
                  <div className="grupo__campo d-flex w-100">
                    <div className="mt-auto mb-3">
                      <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                        <input type="checkbox" name="check" checked={outro} onChange={(e)=>setOutro(e.target.checked)} />
                        <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                    <div className="w-100">
                      <label style={{ width: "100%" }}>Outro: </label>
                      <br />
                      <div className="grupo__campo__form">
                        <input
                          type="email"
                          value={dadosOutro}
                          onChange={(event) => {
                            setDadosOutro(event.target.value);
                          }}
                          disabled={!outro}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={props.fechar}
            >
              Fechar
            </button>
            <button
              className="button btn__primary"
              onClick={()=>EnviarEmail()}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEnviarDocumentos;
