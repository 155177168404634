
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./layouts/Login";
import Sistema from "./layouts/Sistema";
import Imprimir from "./layouts/Imprimir";

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/style.css';
import './assets/styles/new_style.css';

import './assets/styles/cards.css';
import './assets/styles/modal.css';
import './assets/styles/remaster-style.css';
import './assets/styles/table.css';
import './assets/styles/tags.css';

import './assets/font/fontawesome-free-5.13.0-web/css/all.min.css';
import './assets/font/font-samsungsharpsans/samsungsharpsans_1.woff';
import './assets/font/font-samsungsharpsans/samsungsharpsans-bold_1.woff';
import './assets/font/font-samsungsharpsans/samsungsharpsans-medium_1.woff';
import './assets/font/font-dosis/font-dosis.css';
import './assets/font/font-poppins/poppins.css';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/imprimir" component={Imprimir} />
      <Route path="/freedom" component={Sistema} />
      <Route path="/" component={Login} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
  