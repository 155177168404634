
import React from "react"
import {api_admin} from '../services/apis'

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      // usuario: 'casamineira',
      // senha: 'casamineira@2020'
      usuario: '',
      senha: ''
    }
  }

  componentDidMount(){
    localStorage.clear()
  }

  login = async () => {
    if(!this.state.usuario && !this.state.senha){
      alert('Necessário informar usuário e senha para acessar o sistema.')
    }
    else{
      try{
        let login = await api_admin.post('/imobon/loginfreedom', {usuario: this.state.usuario, senha: this.state.senha, sistema: 6})
        if(login.data.token.indexOf('Dados inválidos') === -1){
            localStorage.setItem('autenticado_freedom', login.data.token)
            this.props.history.push('/freedom/contratos')
        }
        else{
            alert(login.data.token)
        }
      }catch(error){
        console.log(error.message)
      }
    }
  }


  render() {
    return (
      <>
        <section className="fxt-template-animation fxt-template-layout19">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-md-6 fxt-bg-color">
                      <div className="fxt-content">   
                          <div className="fxt-header-esquerda">
                          <img src={require('../assets/imgs/logo.png')} alt="Logo" />
                          </div>
                          <div className="fxt-form">
                              <h2>Acesso</h2>
                              <p className="subtitulo" style={{marginBottom: '50px'}}>Digite seus dados abaixo</p>
                                  <div className="form-group">                                                
                                      <div className="fxt-transformY-50 fxt-transition-delay-3">  
                                          <div className="titulos-entrada">E-mail ou Usuário</div>
                                          <input style={{fontSize: '1em'}} type="text" value={this.state.usuario} className="form-control" name="usuario" placeholder="" required="required" 
                                          onChange={(event) => {
                                            this.setState({usuario: event.target.value})
                                          }}/>
                                      </div>
                                  </div>
                                  <div className="form-group">
                                      <div className="fxt-transformY-50 fxt-transition-delay-3">  
                                          <div className="fxt-checkbox-area">
                                              <a href="/" className="switcher-text2">Esqueceu sua senha?</a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="form-group">                                                
                                      <div className="fxt-transformY-50 fxt-transition-delay-4">
                                          <div className="titulos-entrada">Senha</div>
                                          <input style={{fontSize: '1em'}} id="password" value={this.state.senha} type="password" className="form-control" name="password" placeholder="" required="required" 
                                          onChange={(event) => {
                                            this.setState({senha: event.target.value})
                                          }}
                                          onKeyPress={(click) => {
                                            if(click.key === 'Enter'){
                                              this.login()
                                            } 
                                          }}/>
                                      </div>
                                  </div>
                                  
                                  <div className="form-group">
                                      <div className="fxt-transformY-50 fxt-transition-delay-5">
                                          <div className="text-center">
                                              <button className="btn__freedom btn--active" onClick={this.login}>Entrar</button>
                                          </div>
                                      </div>
                                  </div>
                          </div> 
                          
                      </div>
                      <div className="chat-esquerda"><img src={require('../assets/imgs/chat.png')} alt="Chat" /></div>
                  </div>    
                  <div className="col-md-6 fxt-bg-img" style={{backgroundImage: `url(${require('../assets/imgs/fundo.jpg')})`}}>
                      <div className="fxt-header">
                        {/* <p className="texto-top">Ainda não tem cadastro? <a href="#" className="fxt-btn-ghost">Criar Conta</a></p>  */}
                      </div>
                      
                      <div className="fxt-header-rodape">
                          <p className="texto-rodape"><b>Epar Gestão de Contratos © 2020</b><br/>
                          Todos os Direitos Reservados - Tel: (31) 3615-4004</p>
                      </div>
                  </div>
                  
              </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;
