import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'


class IndicesPage extends React.Component {
    constructor(props){
        super(props)

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 

        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "ReadWriteMaster"){
            window.location.href = "/freedom/contratos"
        }

        this.state = {
            meses: '0',
            name: '',
            mesesValores: {},
            indices: [],
            editId: -1
        }

        this.getIndices()
    }

    abrirModal = (id) => {
        var modal = document.getElementById(id);
        modal.style.display = "flex";
    }

    fecharModal = (id) => {
        var modal = document.getElementById(id);
        modal.style.display = "none";
    }

    twoZeros = (d) => d.toString().length == 1 ? `0${d}` : d

    genMeses = (qt) => {
        let a = new Date()
        let meses = {}

        for(let i = 0; i < qt; i++){
            meses[`${a.getFullYear()}-${this.twoZeros(a.getMonth() + 1)}`] = 0
            a.setMonth(a.getMonth() - 1)
        }

        this.setState({
            mesesValores: meses
        })
    }

    formatDate = (d) => {
        if (!d) return d

        let [y, m] = d.split("-")

        return `${m}/${y}`
    }

    getIndices = async () => {
        let {data} = await api_freedom.get("/indice/all", {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(data.data.indexOf('TOKEN') === -1){
            this.setState({
                indices: data.data,
            })
        }
    }

    saveIndice = async () => {
        if (this.state.meses == "0" || !this.state.name){
            return alert("Preencha os campos")
        }

        await api_freedom.post("/indice/create", {
            name: this.state.name,
            meses: this.state.mesesValores
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        this.setState({
            name: ''
        })

        this.fecharModal('modal_indice')
        this.getIndices()
    }

    editIndice = async () => {
        await api_freedom.put("/indice/" + this.state.editId, {
            name: this.state.name,
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        this.setState({
            name: '',
            editIndice: -1
        })

        this.fecharModal('modal_indice_edit')
        this.getIndices()
    }

    render(){
        return (
            <>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <p className="relatorio-title">Índices</p>
                        </div>
                        <div>
                            <button className='tohidden button btn__primary' onClick={() => this.abrirModal('modal_indice')}>Adicionar Índices</button>
                        </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>ID</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Nome</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Opções</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.indices.map(e => (
                                        <tr>
                                            <td className="tbody__text"><p>{e.id}</p></td>
                                            <td className="tbody__text"><p>{e.nome}</p></td>
                                            <td className="tbody__text">
                                                <select onChange={a => {
                                                    switch (a.target.value) {
                                                        case "1":
                                                            window.location.href = `/freedom/indice/${e.id}/valores`
                                                            break;

                                                        case "2":
                                                            this.setState({
                                                                name: e.nome,
                                                                editId: e.id
                                                            })
                                                            this.abrirModal('modal_indice_edit')
                                                            break;
                                                    
                                                        default:
                                                            break;
                                                    }
                                                }}>
                                                    <option>Ações</option>
                                                    <option value="1">Ver valores</option>
                                                    <option value="2">Editar indíce</option>
                                                </select>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="content-modal" id="modal_indice_edit" style={{display: 'none'}}>
                        <div className="modal">
                            <div className="modal-head">
                                Editar indíce
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    <div>
                                        <div style={{marginTop: '1%'}}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Nome: </label><br/>
                                                        <div className="grupo__input">
                                                            <input type="text" value={this.state.name} onChange={a => this.setState({name: a.target.value})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-foot">
                                <button className='btn__freedom btn--active' style={{height: '30px', borderRadius: '50px'}} onClick={a => this.fecharModal('modal_indice_edit')}>Fechar</button>
                                <button className='btn__freedom btn--normal' style={{height: '30px', borderRadius: '50px'}} onClick={this.editIndice}>Editar</button>
                            </div>
                        </div>
                    </div>

                    <div className="content-modal" id="modal_indice" style={{display: 'none'}}>
                        <div className="modal">
                            <div className="modal-head">
                                Criar indíce
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    <div>
                                        <div style={{marginTop: '1%'}}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Nome: </label><br/>
                                                        <div className="grupo__input">
                                                            <input type="text" value={this.state.name} onChange={a => this.setState({name: a.target.value})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{width: '100%'}}>Quantidade de meses passados: </label><br/>
                                                        <div className="grupo__input">
                                                            <select value={this.state.meses} onChange={a => {this.setState({meses: Number(a.target.value)}); this.genMeses(Number(a.target.value))}}>
                                                                <option value="0">Selecione uma quantidade</option>
                                                                <option value="6">6</option>
                                                                <option value="12">12</option>
                                                                <option value="18">18</option>
                                                                <option value="24">24</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    Object.keys(this.state.mesesValores).map(e => (
                                                        <div className="col-md-12">
                                                            <div className='grupo__campo'>
                                                                <label style={{width: '100%'}}>{this.formatDate(e)}:</label><br/>
                                                                <div className="grupo__input">
                                                                    <input type="number" value={this.state.mesesValores[e]} onChange={a => this.setState({mesesValores: {...this.state.mesesValores, [e]: a.target.value}})}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="modal-foot">
                                <button className='btn__freedom btn--active' style={{height: '30px', borderRadius: '50px'}} onClick={a => this.fecharModal('modal_indice')}>Fechar</button>
                                <button className='btn__freedom btn--normal' style={{height: '30px', borderRadius: '50px'}} onClick={this.saveIndice}>Criar</button>
                            </div>
                        </div>
                    </div>
                </div>
        
            </>
        )
    }
}

export default IndicesPage