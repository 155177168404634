import React from "react"
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Dropdown from "./DropdownMenuComponents/Dropdown";
import Element from "./DropdownMenuComponents/Element";

class Menu extends React.Component {

  openMenu = () => {
    let menu = document.getElementById('menu');
    let body = document.getElementsByTagName('body')[0];

    menu.classList.toggle('nav__mobile');
    body.classList.toggle('scroll__block');
  }

  disable_button() {
    return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read"
  }

  disable_button_master() {
    return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "ReadWriteMaster"
  }

  disable_button_write_and_master() {
    return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo  === "ReadWriteMaster" || "ReadWrite" ? false : true
  }

  render() {
       return (
          <>
            <nav id="menu" class="pointer">
                <div className="nav__header" id="menuMob">
                    <div className="menu__img">
                        <img src={require('../assets/imgs/mobilelogo.png')} alt=""/>
                        <img src={require('../assets/imgs/epar_texto.png')} class="nav__max__img" alt=""/>
                    </div>
                    <i class="fas fa-times pointer" id="close" onClick={this.openMenu}></i>
                    <i class="fas fa-bars pointer" id="bars" onClick={this.openMenu}></i>
                </div>

                <div class="nav__body">
                    <ul>
                        {/* <Link className="nav--item" to="/freedom/dashboard">
                            <li id={window.location.pathname.startsWith('/freedom/dashboard') ? 'nav--active': ''}>
                                <i className="fas fa-chart-line"></i>
                                <p className="nav--item__p">Dashboard</p>
                            </li>
                        </Link>
                        <Link className="nav--item" to="/freedom/contratos">
                            <li id={window.location.pathname.startsWith('/freedom/contratos') ? 'nav--active': ''}>
                                <i className="fas fa-file-signature"></i>
                                <p className="nav--item__p">Contratos</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button()}  className="nav--item" to="/freedom/servicos">
                            <li id={window.location.pathname.startsWith('/freedom/servicos') ? 'nav--active': ''}>
                                <i className="fas fa-hand-holding-usd"></i>
                                <p className="nav--item__p">Serviços</p>
                            </li>
                        </Link>

                        <Link hidden={this.disable_button_master()}  className="nav--item" to="/freedom/indices">
                            <li id={window.location.pathname.startsWith('/freedom/indices') ? 'nav--active': ''}>
                                <i className="fab fa-buffer"></i>
                                <p className="nav--item__p">Índices</p>
                            </li>
                        </Link>

                        <Link hidden={this.disable_button_master()}  className="nav--item" to="/freedom/reajustecontratos">
                            <li id={window.location.pathname.startsWith('/freedom/reajustecontratos') ? 'nav--active': ''}>
                                <i className="fas fa-calendar-week"></i>
                                <p className="nav--item__p">Reajuste de contratos</p>
                            </li>
                        </Link>

                        <Link hidden={this.disable_button()} className="nav--item" to="/freedom/gerarboletos">
                            <li id={window.location.pathname.startsWith('/freedom/gerarboletos') ? 'nav--active': ''}>
                                <i className="fas fa-money-bill-wave"></i>
                                <p className="nav--item__p">Gerar boletos</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button()} className="nav--item" to="/freedom/gerarrepasses">
                            <li id={window.location.pathname.startsWith('/freedom/gerarrepasses') ? 'nav--active': ''}>
                                <i className="fas fa-handshake"></i>
                                <p className="nav--item__p">Gerar repasses</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button()} className="nav--item" to="/freedom/gerartransferencias">
                            <li id={window.location.pathname.startsWith('/freedom/gerartransferencias') ? 'nav--active': ''}>
                                <i className="fas fa-university"></i>
                                <p className="nav--item__p">Gerar transferências</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_master()} className="nav--item" to="/freedom/nfse">
                            <li id={window.location.pathname.startsWith('/freedom/nfse') ? 'nav--active': ''}>
                                <i className="fas fa-receipt"></i>
                                <p className="nav--item__p">Gerar NFSE</p>
                            </li>
                        </Link>
                        <Link className="nav--item" to="/freedom/dimob">
                            <li id={window.location.pathname.startsWith('/freedom/dimob') ? 'nav--active': ''}>
                                <i className="fas fa-archway"></i>
                                <p className="nav--item__p">Dimob</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_write_and_master()} className="nav--item" to="/freedom/relatorioinadimplencia">
                            <li id={window.location.pathname.startsWith('/freedom/relatorioinadimplencia') ? 'nav--active': ''}>
                                <i className="fas fa-dollar-sign"></i>
                                <p className="nav--item__p">Relatório de inadimplentes</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_write_and_master()} className="nav--item" to="/freedom/relatoriotransferencia">
                            <li id={window.location.pathname.startsWith('/freedom/relatoriotransferencia') ? 'nav--active': ''}>
                                <i className="fas fa-random"></i>
                                <p className="nav--item__p">Relatório de transferência</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_write_and_master()}className="nav--item" to="/freedom/relatoriorepasse">
                            <li id={window.location.pathname.startsWith('/freedom/relatoriorepasse') ? 'nav--active': ''}>
                                <i className="fas fa-dolly-flatbed"></i>
                                <p className="nav--item__p">Relatório de repasse</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_write_and_master()} className="nav--item" to="/freedom/relatorioseguro">
                            <li id={window.location.pathname.startsWith('/freedom/relatorioseguro') ? 'nav--active': ''}>
                                <i className="fas fa-user-shield"></i>
                                <p className="nav--item__p">Relatório de Seguro</p>
                            </li>
                        </Link>
                        <Link className="nav--item" to="/freedom/pessoas">
                            <li id={window.location.pathname.startsWith('/freedom/pessoas') ? 'nav--active': ''}>
                                <i className="fas fa-users"></i>
                                <p className="nav--item__p">Pessoas</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button()} className="nav--item" to="/freedom/modelos">
                            <li id={window.location.pathname.startsWith('/freedom/modelos') ? 'nav--active': ''}>
                                <i className="fas fa-folder-open"></i>
                                <p className="nav--item__p">Modelos</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_write_and_master()} className="nav--item" to="/freedom/imobstatement">
                            <li id={window.location.pathname.startsWith('/freedom/imobstatement') ? 'nav--active': ''}>
                                <i className="fas fa-book-open"></i>
                                <p className="nav--item__p">Extratos</p>
                            </li>
                        </Link>
                        <Link hidden={this.disable_button_master()} className="nav--item" to="/freedom/logs">
                            <li id={window.location.pathname.startsWith('/freedom/logs') ? 'nav--active': ''}>
                                <i className="fas fa-book"></i>
                                <p className="nav--item__p">Logs</p>
                            </li>
                        </Link> */}
                        <Dropdown titleId={1} />
                        <Dropdown titleId={2} />
                        <Dropdown titleId={3} />
                        <Dropdown titleId={4} />
                        <Dropdown titleId={5} />
                        <Dropdown titleId={6} />
                        <Dropdown titleId={7} />
                        <Dropdown titleId={8} />
                        <Link className="nav--item" to="/">
                            <li>
                                <i className="fas fa-sign-out-alt"></i>
                                <p className="nav--item__p">Sair</p>
                            </li>
                        </Link>
                    </ul>
                </div>

            </nav>
          </>
        );
    // return (
    //   <>
    //     <div>
            // <div className="menu__mob" id="menuMob">
            //     <div className="menu__img">
            //         <img src={require('../assets/imgs/epar_texto.png')} alt="EPAR" />
            //         <i className="fas fa-bars" onClick={this.openMenu}></i>
            //     </div>
            // </div>

    //         <div className="menu" id="menu">
    //             <div className="menu__img">
    //                 <img src={require('../assets/imgs/epar_texto.png')} alt="EPAR" />
    //                 <i className="fas fa-times" onClick={this.openMenu}></i>
    //             </div>
                // <Link className="menu__item" to="/freedom/dashboard">
                //     <div>
                //         <i className="fas fa-chart-line"></i>
                //         <p>Dashboard</p>
                //     </div>
                // </Link>
                // <Link className="menu__item" to="/freedom/contratos">
                //     <div>
                //         <i className="fas fa-file-signature"></i>
                //         <p>Contratos</p>
                //     </div>
                // </Link>
                // <Link hidden={this.disable_button()}  className="menu__item" to="/freedom/servicos">
                //     <div>
                //         <i className="fas fa-hand-holding-usd"></i>
                //         <p>Serviços</p>
                //     </div>
                // </Link>

                // <Link hidden={this.disable_button_master()}  className="menu__item" to="/freedom/indices">
                //     <div>
                //         <i className="fab fa-buffer"></i>
                //         <p>Índices</p>
                //     </div>
                // </Link>

                // <Link hidden={this.disable_button_master()}  className="menu__item" to="/freedom/reajustecontratos">
                //     <div>
                //         <i className="fas fa-calendar-week"></i>
                //         <p>Reajuste de contratos</p>
                //     </div>
                // </Link>

                // <Link hidden={this.disable_button()} className="menu__item" to="/freedom/gerarboletos">
                //     <div>
                //         <i className="fas fa-money-bill-wave"></i>
                //         <p>Gerar boletos</p>
                //     </div>
                // </Link>
                // <Link hidden={this.disable_button()} className="menu__item" to="/freedom/gerarrepasses">
                //     <div>
                //         <i className="fas fa-handshake"></i>
                //         <p>Gerar repasses</p>
                //     </div>
                // </Link>
                // <Link hidden={this.disable_button()} className="menu__item" to="/freedom/gerartransferencias">
                //     <div>
                //         <i className="fas fa-university"></i>
                //         <p>Gerar transferências</p>
                //     </div>
                // </Link>
                // <Link className="menu__item" to="/freedom/relatorioinadimplencia">
                //     <div>
                //         <i className="fas fa-dollar-sign"></i>
                //         <p>Relatório de inadimplentes</p>
                //     </div>
                // </Link>
                // <Link className="menu__item" to="/freedom/relatoriotransferencia">
                //     <div>
                //         <i className="fas fa-random"></i>
                //         <p>Relatório de transferência</p>
                //     </div>
                // </Link>
                // <Link className="menu__item" to="/freedom/relatoriorepasse">
                //     <div>
                //         <i className="fas fa-dolly-flatbed"></i>
                //         <p>Relatório de repasse</p>
                //     </div>
                // </Link>
                // <Link className="menu__item" to="/freedom/pessoas">
                //     <div>
                //         <i className="fas fa-users"></i>
                //         <p>Pessoas</p>
                //     </div>
                // </Link>
                // <Link hidden={this.disable_button()} className="menu__item" to="/freedom/modelos">
                //     <div>
                //         <i className="fas fa-folder-open"></i>
                //         <p>Modelos</p>
                //     </div>
                // </Link>
                // <Link hidden={this.disable_button_master()} className="menu__item" to="/freedom/imobstatement">
                //     <div>
                //         <i className="fas fa-book-open"></i>
                //         <p>Extratos</p>
                //     </div>
                // </Link>
                // <Link className="menu__item" to="/">
                //     <div>
                //         <i className="fas fa-sign-out-alt"></i>
                //         <p>Sair</p>
                //     </div>
                // </Link>
    //         </div>
    //     </div>
    //   </>
    // );
  }
}

export default Menu;