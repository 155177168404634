import React, { useEffect, useState } from "react";
import { api_freedom } from "../services/apis";
import jwt_decode from "jwt-decode";
import { cpf, cnpj } from "cpf-cnpj-validator";

const AdicionarLocador = (props) => {

  const [id, setId] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [nome, setNome] = useState("");
  const [rgIe, setRgIe] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [contratoId, setContratoId] = useState("");
  const [contrato, setContrato] = useState("");

  const [porcentImovel, setPorcentImovel] = useState("");
  const [banco, setBanco] = useState();
  const [agencia, setaAencia] = useState("");
  const [conta, setConta] = useState("");
  const [correntePoupanca, setCorrentePoupanca] = useState(0);
  const [beneficiarioNome, setBeneficiarioNome] = useState("");
  const [beneficiarioCpfCnpj, setBeneficiarioCpfCnpj] = useState("");


  const verificarPessoa = async (cpf_cnpj) => {
    let pessoa = await api_freedom.post(
      `/pessoa/cpfcnpj`,
      {
        cpf_cnpj: cpf_cnpj,
        imobiliaria_id: jwt_decode(localStorage.getItem("autenticado_freedom"))
          .imobiliaria_id,
      },
      {
        headers: { authorization: localStorage.getItem("autenticado_freedom") },
      }
    );

    if (pessoa.data.data.length > 0) {
      return pessoa.data.data[0];
      
    }

    return false;
  };

  const pickEstado = (estado) => {
    switch(estado){
      case "AC": return "Acre"
      case "AL": return "Alagoas"
      case "AP": return "Amapá"
      case "AM": return "Amazonas"
      case "BA": return "Bahia"
      case "CE": return "Ceará"
      case "DF": return "Distrito Federal"
      case "ES": return "Espírito Santo"
      case "GO": return "Goiás"
      case "MA": return "Maranhão"
      case "MT": return "Mato Grosso"
      case "MS": return "Mato Grosso do Sul"
      case "MG": return "Minas Gerais"
      case "PA": return "Pará"
      case "PB": return "Paraíba"
      case "PR": return "Paraná"
      case "PE": return "Pernambuco"
      case "PI": return "Piauí"
      case "RJ": return "Rio de Janeiro"
      case "RN": return "Rio Grande do Norte"
      case "RS": return "Rio Grande do Sul"
      case "RO": return "Rondônia"
      case "RR": return "Roraima"
      case "SC": return "Santa Catarina"
      case "SP": return "São Paulo"
      case "SE": return "Sergipe"
      case "TO": return "Tocantins"
    }
  }

  const adicionarLocador = async () => {

    if(!cpfCnpj){
      preencher("locatario_cpf_cnpj");
      return 0;
    }

    if(!porcentImovel){
      preencher("locador_porcentagem_imovel");
      return 0;
    }

    if(!banco){
      preencher("locador_banco");
      return 0;
    }

    if(!agencia){
      preencher("locador_agencia");
      return 0;
    }

    if(!conta){
      preencher("locador_conta");
      return 0;
    }

    if(!beneficiarioNome){
      preencher("locador_beneficiario_nome");
      return 0;
    }

    if(!beneficiarioCpfCnpj){
      preencher("locador_beneficiario_cpf_cnpj");
      return 0;
    }

      try {

        const response = await api_freedom.post(
          "/contratohaspessoa/create",
          {
            contrato_id: props.contratoId,
            imobiliaria_id: jwt_decode(
              localStorage.getItem("autenticado_freedom")
            ).imobiliaria_id,
            pessoa_id: id,
            tipo: "Locador",
            percentagem: parseInt(porcentImovel),
            banco: banco,
            agencia: agencia,
            conta: conta,
            corrente_poupanca: correntePoupanca,
            beneficiario_nome: beneficiarioNome,
            beneficiario_cpf_cnpj: beneficiarioCpfCnpj,
          },
          {
            headers: {
              authorization: localStorage.getItem("autenticado_freedom"),
            },
          }
          );
          // console.log(response);

        alert("Locador adicionado com sucesso")
        props.fechar()
        props.atualizar()
      } catch(error) {
        props.fechar()
        console.log(error.message);
        alert("Ocorreu um erro");
      }
    }

  const preencher = (name) => {
    if(!document.getElementsByName(name)[0].classList.contains('form--error')){
        document.getElementsByName(name)[0].classList.add('form--error')
    }
  }

  const preencheu = (name) => {
    if (document.getElementsByName(name)[0].classList.contains("form--error")) {
      document.getElementsByName(name)[0].classList.remove("form--error");
    }
  };


  useEffect(() => {}, [cpfCnpj]);

  
  return (
    <>
    <div className="content-modal" id="modal" style={{display: "flex"}}>
        <div className="modal">

      <div className="modal-head">Adicionar Locador</div>
        <div className="modal-body">
          <div className="modal-scroll">
          <div style={{ marginTop: "1%" }}>
          <div className="grupo__campo col-md-4">
            {/* <div className="col-md-4"> */}
              <div className="grupo__campo" name={`locatario_cpf_cnpj`}>
                <label style={{ width: "100%" }}>CPF/CNPJ: *</label>
                <br />
                <div className="grupo__campo__form">
                  <input
                    type="text"
                    maxLength={18}
                    value={cpfCnpj}
                    onChange={async (event) => {
                      preencheu(`locatario_cpf_cnpj`);

                      var cpf_cnpj;
                      if (
                        event.target.value.replace(/[^\d]/g, "").length <= 11
                      ) {
                        cpf_cnpj = event.target.value
                          .replace(/[^\d]/g, "")
                          .replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          );
                      } else {
                        cpf_cnpj = event.target.value
                          .replace(/[^\d]/g, "")
                          .replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            "$1.$2.$3/$4-$5"
                          );
                      }
                      setCpfCnpj(cpf_cnpj)

                      if (
                        event.target.value.replace(/[^\d]/g, "").length ===
                          11 ||
                        event.target.value.replace(/[^\d]/g, "").length === 14
                      ) {
                        if (
                          event.target.value.replace(/[^\d]/g, "").length ===
                            11 &&
                          !cpf.isValid(
                            event.target.value.replace(/[^\d]/g, "")
                          )
                        ) {
                          alert("CPF inválido");
                        }
                        if (
                          event.target.value.replace(/[^\d]/g, "").length ===
                            14 &&
                          !cnpj.isValid(
                            event.target.value.replace(/[^\d]/g, "")
                          )
                        ) {
                          alert("CNPJ inválido");
                        }

                        var pessoa = await verificarPessoa(cpf_cnpj);

                        if (pessoa) {
                            setId(pessoa.id)
                            setNome(pessoa.nome)
                            setRgIe(pessoa.rg_ie)
                            setEmail(pessoa.email)
                            setTelefone(pessoa.telefone)
                            setCelular(pessoa.celular)
                            setCep(pessoa.cep)
                            setEndereco(pessoa.endereco)
                            setBairro(pessoa.bairro)
                            setCidade(pessoa.cidade)
                            setEstado(pessoa.uf)
                            setNumero(pessoa.numero)
                            setComplemento(pessoa.complemento)
                            setContrato(contratoId)
                        } else{
                          setId("")
                          setNome("")
                          setRgIe("")
                          setEmail("")
                          setTelefone("")
                          setCelular("")
                          setCep("")
                          setEndereco("")
                          setBairro("")
                          setCidade("")
                          setEstado("")
                          setNumero("")
                          setComplemento("")
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="d-flex">
              <div className="col-md-6 grupo__campo">
                <label style={{ width: "100%" }}><strong>Nome: </strong>{nome}</label>
                <label style={{ width: "100%", marginTop: 10 }}><strong>Endereço: </strong>{endereco}</label>
                <label style={{ width: "100%", marginTop: 10 }}><strong>Complemento: </strong>{complemento}</label>
                <label style={{ width: "100%", marginTop: 10 }}><strong>Cidade: </strong>{cidade}</label>
                <label style={{ width: "100%", marginTop: 10 }}><strong>CEP: </strong>{cep}</label>
                
              </div>
              <div className="col-md-6 w-100">
                <label style={{ width: "100%", marginTop: 13 }}><strong>Email: </strong>{email}</label>
                <label style={{ width: "100%", marginTop: 10}}><strong>Número: </strong>{numero}</label>
                <label style={{ width: "100%", marginTop: 10}}><strong>Bairro: </strong>{bairro}</label>
                <label style={{ width: "100%", marginTop: 10}}><strong>Estado: </strong>{pickEstado(estado)}</label>                 
                
              </div>
            </div>
          </div>

          <div className="row">
            <div className='col-md-4'>
              <div className='grupo__campo' name={`locador_porcentagem_imovel`}>
                  <label style={{width: '100%'}}>Porcentagem do imóvel: *</label><br/>
                  <div className='grupo__campo__form'>
                      <input type='number' value={porcentImovel} min={0} onChange={(event) => {
                          if(event.target.value > 100.00){
                              alert('Porcentagem deve ser menor que 100%')
                              setPorcentImovel("")
                          }
                          else if(event.target.value < 0){
                              alert('Porcentagem deve ser maior ou igual a 0%')
                              setPorcentImovel("")
                          }
                          else {
                              preencheu(`locador_porcentagem_imovel`)
                              setPorcentImovel(event.target.value)
                          }
                      }}/>
                  </div>    
              </div>
            </div>
          </div>

          <label style={{marginTop: '30px', fontSize: '1.1em', fontWeight: 'bolder'}}>Dados bancários</label>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador_banco`}>
                            <label style={{width: '100%'}}>Banco: *</label><br/>
                            <div className='grupo__campo__form'>
                                <input type='text' value={banco} onChange={(event) => {
                                    preencheu(`locador_banco`)
                                    setBanco(event.target.value)
                                }}/>
                            </div>    
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador_agencia`}>
                            <label style={{width: '100%'}}>Agência: *</label><br/>
                            <div className='grupo__campo__form'>
                                <input type='text' value={agencia} onChange={(event) => {
                                    preencheu(`locador_agencia`)
                                    setaAencia(event.target.value)
                                }}/>
                            </div>    
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador_conta`}>
                            <label style={{width: '100%'}}>Conta: *</label><br/>
                            <div className='grupo__campo__form'>
                                <input type='text' value={conta} onChange={(event) => {
                                    preencheu(`locador_conta`)
                                    setConta(event.target.value)
                                }}/>
                            </div>    
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador_corrente_poupanca`}>
                            <label style={{width: '100%'}}>Corrente ou poupança: *</label><br/>
                            <div className='grupo__campo__form'>
                                <select value={correntePoupanca} onChange={(event) => {
                                    preencheu(`locador_corrente_poupanca`)
                                    setCorrentePoupanca(event.target.value)
                                }}>
                                    <option value={0}>Corrente</option>
                                    <option value={1}>Poupança</option>
                                </select>
                            </div>    
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador_beneficiario_nome`}>
                            <label style={{width: '100%'}}>Beneficiário Nome: *</label><br/>
                            <div className='grupo__campo__form'>
                                <input type='text' value={beneficiarioNome} onChange={(event) => {
                                    preencheu(`locador_beneficiario_nome`)
                                    setBeneficiarioNome(event.target.value)
                                }}/>
                            </div>    
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador_beneficiario_cpf_cnpj`}>
                            <label style={{width: '100%'}}>Benediciário CPF/CNPJ: *</label><br/>
                            <div className='grupo__campo__form'>
                                <input type='text' value={beneficiarioCpfCnpj} onChange={(event) => {
                                    preencheu(`locador_beneficiario_cpf_cnpj`)
                                    setBeneficiarioCpfCnpj(event.target.value)
                                }}/>
                            </div>    
                        </div>
                    </div>
                </div>
                <hr style={{backgroundColor: '#A704BF', marginTop: '30px'}}/>

          </div>
        </div>
        
      <div className="modal-foot">
        <button
          className="button btn__primary--outline"
          onClick={props.fechar}
        >
          Fechar
        </button>
        <button
          className="button btn__primary"
          onClick={adicionarLocador}
        >
          Adicionar
        </button>
      </div>
      </div>
      </div>
    </>
  )
}

export default AdicionarLocador