import React from "react";
import { api_freedom } from "../services/apis";
import moment from "moment"


class ModelosListPage extends React.Component{
    constructor(props) {
        super(props)

        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            modelos: []
        }

        this.getDados()
    }
    
    getDados = async () => {
        let re = await api_freedom.get("/modelo/list", { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        if(re.data.data.indexOf('TOKEN') === -1){
            this.setState({ modelos: re.data.data })
        }

        
    }

    render() {
        return (
            <>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <button className='tohidden btn__primary button' onClick={() => this.props.history.push('/freedom/modeloscreate')}>
                                Adicionar modelo
                            </button>
                            <br />
                            <label style={{margin: '10px 0px 0px 10px'}}>Total de modelos: {this.state.modelos.length}</label>
                        </div>
                    </div>

                    <br/>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>Id</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Nome</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Data</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Editado</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Ações</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.modelos.map(e => (
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.nome}</td>
                                        <td>{moment(e.date).format("DD/MM/YYYY HH:mm")}</td>
                                        <td>{e.dateEdit ? moment(e.dateEdit).format("DD/MM/YYYY HH:mm") : "---"}</td>
                                        <td>
                                            <select onChange={i => {
                                                switch (i.target.value) {
                                                    case "1":
                                                        this.props.history.push("/freedom/modelosedit/" + e.id)
                                                        break;
                                                
                                                    default:
                                                        break;
                                                }
                                            }}>
                                                <option>Opções</option>
                                                <option value="1">Editar</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

export default ModelosListPage