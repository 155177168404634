
import React from "react"
import { Doughnut, HorizontalBar, Line } from "react-chartjs-2"
import { api_freedom } from "../services/apis"
import jwt_decode from 'jwt-decode'
import moment from "moment"


class Dashboard extends React.Component {

  constructor(props) {
    super(props)
    let dadosUser = {}

    if (!localStorage.getItem('autenticado_freedom')) {
      props.history.push('/')
    }
    else {
      try {
        dadosUser = jwt_decode(localStorage.getItem('autenticado_freedom'))
      } catch (error) {
        props.history.push('/')
      }
    }

    this.pags = window.innerWidth;

    this.state = {
      active: 1,
      detalhes: false,
      activeDetalhe: 1,
      dashboard: true,
      dashboardDetalhes: false,
      date: new Date().toISOString().split("T")[0],
      valorMedioAlugueis: 0,
      somaTaxaAdm: 0,
      dadosLine: {},
      dadosVenc: [],
      boletosAtrasados: 0,
      pagos: 0,
      naoPagos: 0,
      vencidos: 0,
      total: 0,
      pagina: 1,
      mensagem: 'Sem dados',
      buscar_contrato: '',
      totalAdim: 0,
      valorTotalAdim: 0,
      valorTotalIndim: 0,
      valorTotalvenc: 0,
      mediaDias: 0,
      dadosUser: dadosUser,

      type: "",
      dadosConteudo: {},
      allData: []
    }

    this.getData()
    this.getBoletosAtrasados();
  }

  componentDidMount() {
    this.openMenu();
  }

  openMenu = () => {
    var menu = document.getElementById('menu');
    var btn = document.getElementById('menu__btn');
    var box = document.getElementsByClassName('box__content');
    var windowWidth = window.innerWidth;

    if (windowWidth > 768) {
      if (menu.style.display == 'block') {
        menu.style.display = 'none';
        box[0].style.paddingLeft = '20px';
        btn.style.left = '0px';
      }

      else {
        menu.style.display = 'block';
        box[0].style.paddingLeft = '240px'
        btn.style.left = '220px';
      }
    }

    else {
      btn.style.display = 'none';
    }

  }

  getData = async () => {
    let re = await api_freedom.post("/dashboard/dados", {
      date: this.state.date,
      imob: this.state.dadosUser.imobiliaria_id
    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
    this.genGraphs(re.data)
  }

  getBoletosAtrasados = async () => {
    let re = await api_freedom.get(`/boleto/repasse-garantido/boletos-vencidos/nao-repassados/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}/all`,
      { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
    console.log("rerpasse" + re.data.total[0].total);
    this.state.boletosAtrasados = re.data.total[0].total;
  }

  genGraphs = (dados) => {
    let gl = {}
    let totalAdim = 0
    let totalInadim = 0
    let totalVenc = 0
    let data = []

    for (const i of dados.adimplentes) {
      let dataBR = this.formateDate(i.pago.split("T")[0])

      if (!gl[dataBR]) {
        gl[dataBR] = 0
      }

      gl[dataBR] += i.valor
      totalAdim += i.valor
    }

    let pagos = 0
    let naoPagos = 0
    let vencidos = 0
    let gven = {}
    let totalDays = 0

    for (const i of dados.boletos) {
      let t = "pago"
      if (i.pago) {
        pagos += 1
      }

      else {
        if (new Date(i.data_vencimento.split("T")[0] + "T23:59:59").getTime() > new Date().getTime()) {
          naoPagos += 1
          t = "nao pago"
          let dataBR = this.formateDate(i.data_vencimento.split("T")[0])

          if (!gl[dataBR]) {
            gven[dataBR] = 0
          }

          gven[dataBR] += i.valor
          totalVenc += i.valor
        }
        else {
          vencidos += 1
          totalInadim += i.valor
          t = "inadimplencia"

          let diff = parseInt((new Date().getTime() - new Date(i.data_vencimento.split("T")[0] + "T23:59:59").getTime()) / 1000 / 24 / 60 / 60)
          totalDays += diff
        }
      }

      i.pessoas = dados.pessoas.filter(e => e.contrato_id == i.contrato_id)
      i.tipo = t
      data.push(i)
    }

    gven = this.proccessValues(gven)

    this.setState({
      dadosLine: gl,
      dadosVenc: gven,
      pagos,
      total: dados.boletos.length,
      totalAdim: dados.adimplentes.length,
      valorMedioAlugueis: dados.media_alugueis,
      somaTaxaAdm: dados.soma_taxa_adm,
      naoPagos,
      vencidos,
      valorTotalAdim: totalAdim,
      valorTotalIndim: totalInadim,
      valorTotalvenc: totalVenc,
      allData: data,
      mediaDias: parseInt(totalDays / vencidos)
    })
  }

  proccessValues = (v) => {
    let dados = []

    for (const i in v) {
      dados.push({
        "label": i,
        "data": [v[i]],
        "backgroundColor": "#E6D8FC"
      })
    }

    return dados
  }

  normalizeValue = (e) => {
    if (e < 1000) {
      return e.toString()
    }
    else if (e < 1000000) {
      return (Math.round(Number(e) / 10) / 100).toString() + "Mil"
    }
    else if (e < 1000000000) {
      return (Math.round(Number(e) / 10000) / 100).toString() + "Mi"
    }
    return (Math.round(Number(e) / 10000000) / 100).toString() + "Bi"
  }

  changeDate = async (e) => {
    this.setState({ date: e.target.value })
    this.state.date = e.target.value

    if (e.target.value.match(/(\d\d\d\d)-(\d\d)-(\d\d)/)) {
      await this.getData()
      this.updateDadosContratos(this.state.type)
    }
  }

  updateDadosContratos = (type) => {
    let d = {}

    if (type == 'adi') {
      d.valor = this.state.valorTotalAdim
      d.name = 'Adimplentes'
      d.qtde = this.state.totalAdim
      d.porcentagem = Math.round(((this.state.totalAdim * 100) / this.state.total))
    }
    else if (type == 'ven') {
      d.valor = this.state.valorTotalvenc
      d.name = 'A vencer'
      d.qtde = this.state.naoPagos
      d.porcentagem = Math.round(((this.state.naoPagos * 100) / this.state.total))
    }
    else if (type == 'ina') {
      d.valor = this.state.valorTotalIndim
      d.name = 'Inadimplentes'
      d.qtde = this.state.vencidos
      d.porcentagem = Math.round(((this.state.vencidos * 100) / this.state.total))
    }

    this.setState({
      dadosConteudo: d,
    })
  }

  selecionarContrato = async (type) => {
    this.updateDadosContratos(type)

    this.setState({
      detalhes: true,
      dashboardDetalhes: true,
      dashboard: false,
      type: type
    })
  }

  formateDate = (d) => {
    if (!d) return d

    let [ano, mes, dia] = d.split("T")[0].split("-")

    return `${dia}/${mes}/${ano}`
  }

  filtrar = (contratos) => {
    var filtro = contratos.filter((c) => {
      return this.filtrarContrato(c)
    })

    this.quantidade_paginas = Math.ceil(filtro.length / 10)

    return filtro
  }

  filtrarContrato = (contrato) => {
    return (
      !this.state.buscar_contrato ||
      (
        contrato.contrato_id &&
        contrato.contrato_id.toString().toLowerCase().includes(this.state.buscar_contrato)
      )
    ) &&
      (
        (this.state.type == "ina" && contrato.tipo == "inadimplencia") ||
        (this.state.type == "ven" && contrato.tipo == "nao pago") ||
        (this.state.type == "adi" && contrato.tipo == "pago") ||
        (this.state.type == "con")
      )
  }

  getMesAno = () => {
    console.log(moment(this.state.date).format('M'));
    switch (moment(this.state.date).format('M')) {
      case '1': return `jan/${moment(this.state.date).format('YYYY')}`
      case '2': return `fev/${moment(this.state.date).format('YYYY')}`
      case '3': return `mar/${moment(this.state.date).format('YYYY')}`
      case '4': return `abr/${moment(this.state.date).format('YYYY')}`
      case '5': return `mai/${moment(this.state.date).format('YYYY')}`
      case '6': return `jun/${moment(this.state.date).format('YYYY')}`
      case '7': return `jul/${moment(this.state.date).format('YYYY')}`
      case '8': return `ago/${moment(this.state.date).format('YYYY')}`
      case '9': return `set/${moment(this.state.date).format('YYYY')}`
      case '10': return `out/${moment(this.state.date).format('YYYY')}`
      case '11': return `nov/${moment(this.state.date).format('YYYY')}`
      case '12': return `dez/${moment(this.state.date).format('YYYY')}`
      default: return ''
    }
  }

  listarContratos = (record, index) => {
    let class_name = {};

    if (record.ativo === 0) {
      class_name = { fontWeight: 'normal', "textDecoration": "line-through" }
    }

    let hoje = new Date()
    let venci = new Date(record.data_vencimento)

    let days = Math.ceil((hoje.getTime() - venci.getTime()) / 1000 / 24 / 60 / 60)

    return (
      <tr style={class_name} key={index}>
        <td>{record.contrato_id}</td>
        <td>{record.endereco}</td>
        <td>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
        {record.pessoas.map((locatario) => {
          return <>
            <td><div key={locatario.cpf_cnpj}><label style={class_name}>{`${locatario.nome}`}</label><br /></div></td>
            <td><div key={locatario.cpf_cnpj}><label style={class_name}>{`${locatario.email}`}</label><br /></div></td>
            <td><div key={locatario.cpf_cnpj}><label style={class_name}>{`${locatario.telefone}`}</label><br /></div></td>
            {/* <td><div key={locatario.cpf_cnpj}><label style={class_name}>{`${locatario.telefone}`}</label><br/></div></td> */}
          </>
        })}
        {this.state.activeDetalhe == 3 || this.state.activeDetalhe == 4 ? <td>{this.formateDate(record.data_vencimento)}</td> : ''}
        {this.state.activeDetalhe == 3 ? days + " dias" : ""}
      </tr>
    )
  }

  render() {
    return (
      <>
        {/* <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <button className='botao_dashboard' onClick={() => {
            this.props.history.push('/freedom/contratos')
        }}>Contratos</button><br/>
        <button className='botao_dashboard' onClick={() => {
            this.props.history.push('/freedom/servicos')
        }}>Serviços</button><br/>
        <button className='botao_dashboard' onClick={() => {
            this.props.history.push('/freedom/gerarboletos')
        }}>Gerar boletos</button><br/>
        <button className='botao_dashboard' onClick={() => {
            this.props.history.push('/freedom/gerarrepasses')
        }}>Gerar repasses</button><br/>
        <button className='botao_dashboard' onClick={() => {
            this.props.history.push('/freedom/pessoas')
        }}>Pessoas</button><br/>
        </div> */}
        <div className="menu__btn" id="menu__btn" onClick={() => this.openMenu()}>
          <i class="fas fa-bars"></i>
        </div>
        <div className="dashboard__head">
          <h5>Dashboard</h5>
          <div style={{ display: this.state.dashboard ? 'block' : 'none' }}>
            <button className={`button ${this.state.active ? 'btn__primary' : 'btn__primary--outline'}`} onClick={() => this.setState({ active: true })}>Cobranças Aluguel</button>
            <button className={`button ${!this.state.active ? 'btn__primary' : 'btn__primary--outline'}`} onClick={() => this.setState({ active: false })}>Atendimentos Realizados</button>
          </div>
          <div style={{ display: this.state.dashboardDetalhes ? 'flex' : 'none' }}>
            <button className="button btn__primary" onClick={() => this.setState({ detalhes: false, dashboardDetalhes: false, dashboard: true })}>Voltar</button>
            <button className={`button ${this.state.activeDetalhe == 1 ? 'btn__primary' : 'btn__primary--outline'}`} onClick={() => { this.setState({ activeDetalhe: 1 }); this.selecionarContrato('con') }}>Consolidado</button>
            <button className={`button ${this.state.activeDetalhe == 2 ? 'btn__primary' : 'btn__primary--outline'}`} onClick={() => { this.setState({ activeDetalhe: 2 }); this.selecionarContrato('adi') }}>Adimplentes</button>
            <button className={`button ${this.state.activeDetalhe == 3 ? 'btn__primary' : 'btn__primary--outline'}`} onClick={() => { this.setState({ activeDetalhe: 3 }); this.selecionarContrato('ina') }}>Inadimplentes</button>
            <button className={`button ${this.state.activeDetalhe == 4 ? 'btn__primary' : 'btn__primary--outline'}`} onClick={() => { this.setState({ activeDetalhe: 4 }); this.selecionarContrato('ven') }}>A vencer</button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <div className="grupo__input__type_2">
              <div className="grupo__campo"><i class="far fa-calendar-alt"></i>
                <input type="date" placeholder="Pesquisar.." value={this.state.date} onChange={this.changeDate} />
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: this.state.dashboard ? this.state.active ? 'block' : 'none' : 'none' }}>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>{this.state.total}</h3>
                    <p>Total de Contratos</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="far fa-file-alt"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__item" onClick={() => { this.selecionarContrato('adi'); this.setState({ activeDetalhe: 2 }) }}>
                <div className="dashboard__item__legenda">
                  <p>Adimplentes</p>
                </div>
                <div className="dashboard__item__text">
                  <div>
                    <h3>{this.state.totalAdim}</h3>
                    <p>Contratos</p>
                  </div>
                  <div>
                    <h3>{this.normalizeValue(this.state.valorTotalAdim)}</h3>
                    <p>Valor</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__item" onClick={() => { this.selecionarContrato('ina'); this.setState({ activeDetalhe: 3 }) }}>
                <div className="dashboard__item__legenda">
                  <p>Inadimplentes</p>
                </div>
                <div className="dashboard__item__text">
                  <div>
                    <h3>{this.state.vencidos}</h3>
                    <p>Contratos</p>
                  </div>
                  <div>
                    <h3>{this.normalizeValue(this.state.valorTotalIndim)}</h3>
                    <p>Valor</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__item" onClick={() => { this.selecionarContrato('ven'); this.setState({ activeDetalhe: 4 }) }}>
                <div className="dashboard__item__legenda">
                  <p>A vencer</p>
                </div>
                <div className="dashboard__item__text">
                  <div>
                    <h3>{this.state.naoPagos}</h3>
                    <p>Contratos</p>
                  </div>
                  <div>
                    <h3>{this.normalizeValue(this.state.valorTotalvenc)}</h3>
                    <p>Valor</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* colocar total de boletos não pagos */}
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>{this.state.boletosAtrasados}</h3>
                    <p>Boletos a repassar</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="far fa-file-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="dashboard__section">
                <p className="dashboard__section__titulo">{`KPIs do mês de ${this.getMesAno()}`}</p>
                <div className="dashboard__item__text">
                  <div>
                    <h3>{this.state.valorMedioAlugueis ? this.state.valorMedioAlugueis.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null}</h3>
                    <p>Valor médio dos aluguéis</p>
                  </div>
                  <div className="line"></div>
                  <div>
                    {/* <h3>{Math.round(((this.state.totalAdim * 100) / this.state.total) * 100) / 100}%</h3> */}
                    <h3>{this.state.somaTaxaAdm ? this.state.somaTaxaAdm.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null}</h3>
                    <p>Taxa de administração total da carteira ativa</p>
                  </div>
                  <div className="line"></div>
                  <div>
                    <h3>{Math.round(((this.state.vencidos * 100) / this.state.total) * 100) / 100}%</h3>
                    <p>Contratos inadimplentes</p>
                  </div>
                  <div className="line"></div>
                  <div>
                    <h3>{this.state.mediaDias}</h3>
                    <p>Média de dias em atraso</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="dashboard__section">
                <p className="dashboard__section__titulo">Curva de pagamento dos aluguéis adimplentes (R$)</p>
                <Line data={{
                  labels: Object.keys(this.state.dadosLine),
                  datasets: [
                    {
                      label: "",
                      data: Object.values(this.state.dadosLine),
                      backgroundColor: "#E6D8FC",
                      borderColor: "#E6D8FC",
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      borderDashOffset: 0.0,
                      pointRadius: 1,
                      pointHitRadius: 10,
                    }
                  ]
                }}></Line>
              </div>
              <div className="dashboard__section">
                <p className="dashboard__section__titulo">Distribuição por data de vencimento dos aluguéis a vencer  (R$)</p>
                <HorizontalBar data={{
                  datasets: this.state.dadosVenc,
                  labels: ["A vencer"]
                }} options={{
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  }
                }}></HorizontalBar>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__section">
                <p className="dashboard__section__titulo">Valores de aluguéis por status de pagamento (R$)</p>

                <Doughnut data={{
                  labels: ["Pago", "A vencer", "Inadimplentes"],
                  datasets: [
                    {
                      backgroundColor: ["#92AFF9", "#3756BF", "#99EAFC"],
                      data: [this.state.pagos, this.state.naoPagos, this.state.vencidos]
                    }
                  ]
                }} options={
                  {
                    legend: {
                      position: "bottom",
                    },
                    responsive: true,
                    maintainAspectRatio: true,

                  }
                } width={this.pags > 768 ? "250" : "auto"} height={this.pags > 768 ? "500" : "auto"}></Doughnut>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: this.state.dashboard ? this.state.active ? 'none' : 'block' : 'none' }}>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>580</h3>
                    <p>Chamados recebidos</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="far fa-file-alt"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>94,8%</h3>
                    <p>Chamados finalizados</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="fas fa-check"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>2.733</h3>
                    <p>Ligações total</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="fas fa-phone"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>456</h3>
                    <p>Ligações por atendente</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="fas fa-headphones"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="dashboard__item">
                <div className="dashboard__item__text">
                  <div>
                    <h3>3.362</h3>
                    <p>Qtd. dde e-mails</p>
                  </div>
                </div>
                <div className="dashboard__item__icon">
                  <div className="dashboard__item__circle">
                    <i class="far fa-envelope"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="dashboard__section">
                <p className="dashboard__section__titulo">Acionamentos (Qtd.)</p>
              </div>
              <div className="dashboard__section">
                <p className="dashboard__section__titulo">Percentual de chamados finalizados  (%)</p>
              </div>
            </div>

          </div>
        </div>

        <div style={{ display: this.state.detalhes ? 'block' : 'none', marginTop: '20px' }}>
          <div className="row justify-content-between">
            {
              this.state.activeDetalhe == 1 ? '' :
                <div className="col-md-4">
                  <div className="dashboard__item">
                    <div className="dashboard__item__legenda">
                      <p>{this.state.dadosConteudo.nome}</p>
                    </div>
                    <div className="dashboard__item__text">
                      <div>
                        <h3>{this.state.dadosConteudo.qtde}</h3>
                        <p>Contratos</p>
                      </div>
                      <div>
                        <h3>{this.normalizeValue(this.state.dadosConteudo.valor)}</h3>
                        <p>Valor</p>
                      </div>
                      <div>
                        <h3>{this.state.dadosConteudo.porcentagem}%</h3>
                        <p>% do total mês</p>
                      </div>
                    </div>
                    <div className="dashboard__item__icon">
                      <div className="dashboard__item__circle">
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                    </div>
                  </div>
                </div>
            }

            <div className="col-md-4">
              <div className="grupo__input__type_2">
                <label>Pesquisar</label>
                <div className="grupo__campo">
                  <i class="fas fa-search"></i>
                  <input type="text" placeholder="Pesquisar por ID." value={this.state.buscar_contrato} onChange={e => this.setState({ buscar_contrato: e.target.value, pagina: 1 })} />
                </div>
              </div>
            </div>
          </div>
          <div className='table mt-4'>
            <table className='table'>
              <thead>
                <tr>
                  <th>COD.</th>
                  <th>Imóvel</th>
                  <th>Aluguel</th>
                  <th>Locatário</th>
                  <th>E-mail</th>
                  <th>Telefone</th>
                  {this.state.activeDetalhe == 3 || this.state.activeDetalhe == 4 ?
                    <>
                      <th>Vencimento</th>
                    </>
                    : ''}
                  {this.state.activeDetalhe == 3 ?
                    <>
                      <th>Dias em atraso</th>
                    </>
                    : ''}
                </tr>
              </thead>
              <tbody>
                {this.filtrar(this.state.allData).length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                {this.filtrar(this.state.allData).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(this.listarContratos)}
              </tbody>
            </table>
          </div>
          {this.filtrar(this.state.allData).length > 0 && <div className='box-paginacao'>
            <div className='paginacao text-center p-2' style={{ width: '30px', cursor: 'pointer' }} onClick={() => {
              this.setState({ pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1 })
            }}>
              {'<'}
            </div>
            {
              [...Array(this.quantidade_paginas)].map((pagina, index) => {
                if ((this.state.pagina === 1 && index + 1 < 4) ||
                  (this.state.pagina === this.quantidade_paginas && index + 1 > this.quantidade_paginas - 3) ||
                  (index + 1 === this.state.pagina - 1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                ) {
                  return (
                    <div key={index + 1} className='paginacao text-center p-2' style={{ width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#f2f2f2' : null, cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ pagina: index + 1 })
                      }}>
                      {index + 1}
                    </div>
                  )
                }
                else {
                  return null
                }
              })
            }
            <div className='paginacao text-center p-2' style={{ width: '30px', cursor: 'pointer' }} onClick={() => {
              this.setState({ pagina: this.state.pagina < this.quantidade_paginas ? this.state.pagina + 1 : this.quantidade_paginas })
            }}>
              {'>'}
            </div>
          </div>}
        </div>
      </>
    );
  }
}

export default Dashboard;