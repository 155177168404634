import React, { useEffect, useState } from "react";
import moment from "moment";

import { api_freedom } from "../services/apis"
import ModalEnviarDocumentos from './ModalEnviarDocumentos';

const ModalDocumentos = (props) => {
  const [documentos, setDocumentos] = useState([]);
  const [documentosUpload, setDocumentosUpload] = useState([]);
  const [documentosSelecionados, setDocumentosSelecionados] = useState([]);
  const [select, setSelect] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalEnviarDocumentos, setModalEnviarDocumentos] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [todos, setTodos] = useState(false);


  const getDocs = async () => {
    
    setLoading(true);
    
    try {
      
      setMensagem('Carregando...');

      const docs = await api_freedom.get(`/docs/list`, {headers: {"authorization": localStorage.getItem("autenticado_freedom")},
      params:{
        contrato_id: props.contratoId,
        imobiliaria_id: props.imobiliaria_id
      }})

      setDocumentos(docs.data.data);

      let array = [];

      for(let i = 0; i < docs.data.data.length; i++){
        array.push(false);
      }

      setSelect(array);

      if(docs.data.data.length === 0) setMensagem('Adicione um documento');

    } catch (error) {
      setMensagem('Sem documentos');
    }

    setLoading(false);
  }

  const ExcluirDoc = async (documento) => {
    
    try {

      const docs = await api_freedom.post(`/docs/delete`, {
        id: documento.id
      }, {headers: {"authorization": localStorage.getItem("autenticado_freedom")}})

      if(docs.data){
        alert(`${documento.nome_arquivo} excluido com sucesso.`)
      } else{
        alert('Ocorreu um erro ao excluir.')
      }

      getDocs();

    } catch (error) {
      setMensagem('Sem documentos');
    }
  }

  const onFileChange = (event) => {

    let arrayFiles = [];

    for(const file of event.target.files){
      
      const fileName = file.name.split('.')

      if((((parseInt(file.size) / 1024) / 1024) < 20)){
        
        const reader = new FileReader();
  
        reader.onload = (e) => {
  
  
            arrayFiles.push({
                descricao: fileName[0],
                nome_arquivo: file.name,
                extensao_arquivo: fileName[parseInt(fileName.length) - 1],
                arquivo_base64: e.target.result,
                data_adicionado: moment().format('YYYY-MM-DD'),
                contrato_id: props.contratoId,
                imobiliaria_id: props.imobiliaria_id,
            })
            setDocumentosUpload(arrayFiles)
        };
        reader.readAsDataURL(file);
        
      } else{
        alert(`Arquivo Maior que 20Mb`)
        setDocumentosUpload([])
        return
      }

    }

};

const uploadFiles = async () => {

    if(documentosUpload.length > 0){
        
        const docs_contrato = await api_freedom.post('/docs/insert', {
            docs: documentosUpload
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})   

        getDocs();

        if(docs_contrato.data){
          alert(`${documentosUpload.length} Arquivo(s) adicionado(s) com sucesso`)
        }

    } else{

      alert("Adicione um arquivo");

    }

}

const DownloadDoc = async (documento) => {
  var hiddenElement = document.createElement("a");
    hiddenElement.href = encodeURI(documento.arquivo_base64);
    hiddenElement.target = "_blank";
    hiddenElement.download = documento.nome_arquivo;
    hiddenElement.click();
}

const ChangedCheck = async (event, index) => {

  let newArr = [...select];
  let arrAdd = [];

  newArr[index] = event.target.checked ? true : false;

  for(let i = 0; i < documentos.length; i++){
    if(newArr[i]){
      arrAdd.push(documentos[i]);
    }
  }

  setDocumentosSelecionados(arrAdd);

  setSelect(newArr);

}

const CheckTodos = async (event) => {

  let array = [];

  if(event.target.checked){
    
    setDocumentosSelecionados(documentos);

    for(let i = 0; i < documentos.length; i++){
      array.push(true);
    }
    setSelect(array);

  } else{

    setDocumentosSelecionados([]);

    for(let i = 0; i < documentos.length; i++){
      array.push(false);
    }
    setSelect(array);

  }

  setTodos(event.target.checked);

}

const FecharModal = async () => {
  setModalEnviarDocumentos('');
}

useEffect(() => {
  getDocs();
}, []);

  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div className="modal">
          <div className="modal-head d-flex justify-content-between" style={{minHeight: "78px"}}>
            <div>
              Documento(s)
            </div>
            {documentosSelecionados.length > 0 ? (
              <div>
                <button
                  className="button btn__primary"
                  onClick={() => {
                    setModalEnviarDocumentos(<ModalEnviarDocumentos dados={documentosSelecionados} fechar={()=>FecharModal()} />)
                  }}
                >
                  Enviar Documentos
                </button>
              </div>
            ) : null}
          </div>
          <div className="modal-body">
            <div className="modal-scroll">

              <div className="table mt-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <label class="mb-0 checkbox-label" style={{marginLeft: "4px"}}>
                          <input type="checkbox" name="check" checked={todos} onChange={(e)=>CheckTodos(e)} />
                          <span class="checkbox-custom rectangular"></span>
                        </label>
                      </th>
                      <th>Nome</th>
                      <th>Contrato</th>
                      <th>Adicionado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                      {loading || documentos.length === 0 ? (
                        <tr><th className='text-center' colSpan='8'>{mensagem}</th></tr>
                        ) : documentos.map((item, index) => (
                          <tr key={index}>
                            <td className="tbody__text">
                              <label class="mb-0 checkbox-label">
                                <input type="checkbox" name={`check${index}`}checked={select[index]} onChange={(e)=>ChangedCheck(e, index)} />
                                <span class="checkbox-custom rectangular"></span>
                              </label>
                            </td>
                            <td className="tbody__text">{item.nome_arquivo}</td>
                            <td className="tbody__text">{item.contrato_id}</td>
                            <td className="tbody__text">{moment(item.data_adicionado).format("DD/MM/YYYY")}</td>
                            <td className="tbody__text">
                              <button className='btn__freedom btn--normal' style={{marginTop: '-1px', width: '30px', height: '23px', marginLeft: '20px'}}
                                onClick={() => DownloadDoc(item)} 
                                ><i className='fas fa-file-download'/>
                              </button>
                              <button className='btn__freedom btn--normal' style={{marginTop: '-1px', width: '30px', height: '23px', marginLeft: '20px'}}
                                onClick={() => ExcluirDoc(item)} 
                                ><i className='fas fa-trash'/>
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                  </tbody>
                </table>
              </div>
              <div className='grupo__campo d-flex mt-5' name={`docs`}>
                  <div>
                      <label style={{width: '100%', paddingLeft: '0px'}}>Adicionar Documentos *Máx. 20Mb</label><br/>
                      <input type="file" accept=".xls,.xlsx,.csv,.doc,.docx,.pdf,.rar,.zip,image/*" onChange={(e) => onFileChange(e)} multiple />
                  </div>
                  <div className="ml-5 mt-3">
                    <button
                      className="button btn__primary"
                      style={{ width: "112px" }}
                      onClick={() => uploadFiles()}
                    >
                      Upload
                    </button>
                  </div>
              </div>
              {modalEnviarDocumentos && modalEnviarDocumentos}

            </div>
          </div>
          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={props.fechar}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalDocumentos;
