import React from "react";
import { api_freedom } from "../services/apis";
import jwt_decode from 'jsonwebtoken';
import moment from "moment";
import '../assets/styles/relatorio.css';

class RelatorioAtivosPdf extends React.Component {
   constructor (props) {
      super(props)

      this.state = {
         mensagem: '',
         loading: false,
         inPrint: false,
         lista: [],
         dataMin: '',
         dataMax: '',
         pesquisa: props.match !== undefined ? jwt_decode.decode(props.match.params.identificador).pesquisa : jwt_decode.decode(props.props)
      }
   };

   fotprint = () => {
      this.setState({inPrint: true})

      setTimeout(() => {
         window.print();

         this.setState({inPrint: false})
      }, 350);
   };

   formataEndereco = (item) => {
      let endereco = '';
      item.endereco ? endereco += item.endereco : endereco += '';
      item.numero ? endereco += ', ' + item.numero : endereco += '';
		item.complemento ? endereco += ', ' + item.complemento : endereco += '';
		item.bairro ? endereco += ', ' + item.bairro : endereco += '';
		item.cidade ? endereco += ', ' + item.cidade : endereco += '';
		item.estado ? endereco += ' - ' + item.estado : endereco += '';
		return endereco
   };

   getContratos = async () => {
      this.setState({loading: true});
      try {
         const response = await api_freedom.get('/contrato/relatorioativo-pdf', {
            headers: {'authorization': localStorage.getItem('autenticado_freedom')},
            params: this.state.pesquisa,
         });

         if (response.data.data.indexOf('TOKEN') === -1) {
            console.log('response:', response.data.data);
            let lista = [];
            for (const item of response.data.data) {
               let obj = Object.create(null);
               let locadores = [];
               obj = item

               for (const pessoa of item.pessoas) {
                  if (pessoa.tipo === 'Locador') {
                     locadores.push(pessoa);
                  } else {
                     obj['locatario'] = pessoa;
                  }
               };

               obj['endereco_completo'] = this.formataEndereco(item);
               obj['locador'] = locadores;
               lista.push(obj);
               delete item.pessoas;
            };

            this.setState({
               lista: lista,
               loading: false,
            })
         } else {
            this.setState({
               loading: false,
               mensagem: 'Nenhum registro encontrado.',
            })
         }
      } catch (err) {
         console.log(err.message);
         this.setState({loading: false})
      }      
   };

   convertMesAno = (d) => {
      if (!d) return d
      
      let [ano, mes] = d.split("-")

      return `${mes}/${ano}`
   };
   
   async componentDidMount() {
      await this.getContratos()
   };

   render() {
      return <>
         <div className="relatorio_inadimplentes" id="relatorio_inadimplentes">
            <div style={{ color: '#000000' }}>
               <div style={{
                  width: '100%',
                  height: '100%',
                  margin: '0',
                  boxSizing: 'border-box',
                  fontFamily: 'sans-serif',
                  backgroundColor: '#f9f9f9',
                  fontSize: '18px'
               }}>
                  <div style={{
                     width: '100%',
                     padding: '0px 15px',
                     paddingTop: '30px',
                     backgroundColor: '#fff',
                     borderBottom: '1px solid #eee'
                  }}>
                     <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%', marginTop: 'auto' }}>
                           <img src={require('../assets/imgs/mobilelogo.png')} style={{ marginLeft: '5rem', marginTop: 5 }} alt="" width={50} />
                           <img src={require('../assets/imgs/epar_texto.png')} style={{ marginLeft: '1rem' }} alt="" width={70} />
                        </div>
                        <div style={{ width: '10%' }}>
                           <div>
                              <p style={{ fontSize: '2rem' }}>NOME DA IMOBILIÁRIA:</p>
                           </div>
                           <div>
                              <p style={{ fontSize: '2rem' }}>Data:</p>
                           </div>
                        </div>
                        <div style={{ width: '20%' }}>
                           <div>
                              <p style={{ fontSize: '2rem' }}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>
                           </div>
                           <div>
                              <p style={{ fontSize: '2rem' }}><strong>{moment().format('DD/MM/YYYY')}</strong></p>
                           </div>
                        </div>
                     </div>

                     <div style={{ margin: '20px 0px 0px 0px' }}>
                        <table style={{ width: '100%' }}>
                           <tr>
                              <td className="text-center" style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '5px' }} colSpan={9}>
                                 <strong>{`
                                    RELATÓRIO DE CONTRATOS ATIVOS
                                 `}</strong>
                              </td>
                              <td id="printPageButton" className="text-center" style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '5px' }} colSpan='2'>
                                 {
                                    !this.state.loading ? (
                                       <button className="button btn__primary--outline"
                                          onClick={() => {
                                             this.fotprint()
                                          }}
                                       >
                                          <i class="fas fa-file-pdf" />{" "}
                                          PDF
                                       </button>
                                    ) : null
                                 }
                              </td>
                           </tr>
                        </table>
                     </div>

                     <div className="table-responsive">
                        <table className="table_relatorio" style={{ width: '100%' }}>
                           <thead>
                              <tr>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}># CONTRATO</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>VENCIMENTO</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>PRAZO</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF' }}>LOCADOR</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF' }}>LOCATÁRIO</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '200px', color: '#FFF' }}>IMÓVEL</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>ALUGUEL</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>TX ADM %</th>
                                 <th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>TX ADM $</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 this.state.loading ? (
                                    <tr>
                                       <td className="text-center" style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '10px '}} colSpan={12}>
                                          Carregando...
                                       </td>
                                    </tr>
                                 ) :
                                    this.state.lista.map((e, index) => (
                                       <tr id={'pop' + index} key={index} style={{ backgroundColor: '#CCC', wordBreak: 'break-word' }}>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.id}</td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{moment(e.data_fim).format('DD/MM/YYYY')}</td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>
                                             <div>{moment(e.data_inicio).format('DD/MM/YYYY')}</div>
                                             <div>{moment(e.data_fim).format('DD/MM/YYYY')}</div>
                                          </td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '250px' }}>
                                             {
                                                e.locador.map(locador => (
                                                   <>
                                                      <div>{locador?.nome}</div>
                                                      <div>{locador?.cpf_cnpj}</div>
                                                   </>
                                                ))
                                             }
                                          </td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '250px' }}>
                                             <div>{e.locatario?.nome}</div>
                                             <div>{e.locatario?.cpf_cnpj}</div>
                                          </td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '200px' }}>
                                             <div>{e.endereco_completo}</div>
                                          </td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{parseFloat(e.valor_aluguel).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{e.perc_taxa_administracao+'%'}</td>
                                          <td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{parseFloat(e.valor_aluguel * (e.perc_taxa_administracao / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                       </tr>
                                    ))
                              }
                           </tbody>
                        </table>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </>
   }
}

export default RelatorioAtivosPdf;