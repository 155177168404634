import React from 'react';

class PrintButton extends React.Component {
    constructor(props) {
        super(props)
    }

    handlePrint = () => {
        window.print()
    }

    render() {
        return (
            <div id='noprint' class='wizard__dots d-flex justify-content-end'>
                <button id='noprint'
                    class="button btn__primary"
                    onClick={this.handlePrint}
                >
                    Imprimir
                </button>
            </div>
        )
    }
}

export default PrintButton;