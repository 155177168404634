import React from 'react'
import jwt_decode from "jwt-decode"
import { api_freedom } from '../../services/apis'
import moment from 'moment'

class ImprimirModeloContratoPage extends React.Component{
    constructor(props){
        super(props)

        let dadosUser

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 
        else{
            try {
                dadosUser = jwt_decode(localStorage.getItem('autenticado_freedom'))
            } catch (error) {
                props.history.push('/')
            }
        }

        this.state = {
            modelo: '',
            nome: '',
            contrato: {},
            dadosUser: dadosUser,
            inPrint: false
        }

        this.getContrato()
    }

    getContrato = async () => {
        let re = await api_freedom(`/contrato/getunique/${this.props.match.params.id}/${this.state.dadosUser.imobiliaria_id}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(re.data.data.indexOf('TOKEN') === -1){
            if (re.data.data.length > 0){
                this.state.contrato = re.data.data[0]
                this.setState({ 
                    contrato: re.data.data[0],
                })
                this.getModelo()
            }
        }
    }

    requestSign = async () => {
        api_freedom.post("/modelo/genPDF", {
            html: document.querySelector("html").innerHTML,
            contrato: this.state.contrato.id,
            pessoas: this.state.contrato.pessoas,
            nome: this.state.nome,
            imob: this.state.dadosUser.imobiliaria_id

        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        alert("Enviado com sucesso")
    }

    getModelo = async () => {
        let re = await api_freedom(`/modelo/getunique/${this.props.match.params.modelo}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        
        if(re.data.data.indexOf('TOKEN') === -1) {
            let modelo = re.data.data[0].modelo
            let imovel = this.state.contrato.imovel
            let pessoas = this.state.contrato.pessoas
            let pessoasLocador = pessoas.filter(e => e.tipo == "Locador")
            let pessoasLocatario = pessoas.filter(e => e.tipo == "Locatário")[0]
            let imob = this.state.contrato.imobiliaria[0]

            modelo = modelo.replace(/@idContrato/g, this.state.contrato.id)
            modelo = modelo.replace(/@dateStart/g, moment(this.state.contrato.data_inicio).format("DD/MM/YYYY"))
            modelo = modelo.replace(/@dateEnd/g, moment(this.state.contrato.data_fim).format("DD/MM/YYYY"))
            modelo = modelo.replace(/@imovel/g, `${imovel[0].endereco}, ${imovel[0].numero} ${imovel[0].complemento}, ${imovel[0].bairro} - ${imovel[0].cidade} / ${imovel[0].estado}`)
            modelo = modelo.replace(/@valorAluguel/g, this.state.contrato.valor_aluguel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
            
            modelo = modelo.replace(/@idImob/g, imob.id)
            modelo = modelo.replace(/@CNPJImob/g, imob.cnpj)
            modelo = modelo.replace(/@razaoImob/g, imob.razao_social)
            modelo = modelo.replace(/@telImob/g, imob.telefone)
            modelo = modelo.replace(/@endImob/g, `${imob.endereco}, ${imob.numero} ${imob.complemento}, ${imob.bairro} - ${imob.cidade} / ${imob.estado}`)

            modelo = modelo.replace(/@nomeLocatario/g, pessoasLocatario.nome)
            modelo = modelo.replace(/@endLocatario/g, `${pessoasLocatario.endereco || ''}, ${pessoasLocatario.numero || ''} ${pessoasLocatario.complemento || ''}, ${pessoasLocatario.bairro || ''} - ${pessoasLocatario.cidade || ''} / ${pessoasLocatario.uf || ''}`)
            modelo = modelo.replace(/@telLocatario/g, pessoasLocatario.telefone)
            modelo = modelo.replace(/@emailLocatario/g, pessoasLocatario.email)
           
            modelo = modelo.replace(/@locador/g, pessoasLocador.map(e => e.nome).join(", "))

            for (let i = 0; i < 7; i++) {
                console.log(pessoasLocador);
                if (pessoasLocador[i] == undefined) {
                    break
                }

                modelo = modelo.replace(new RegExp(`@nome${i + 1}Locador`, 'g'), pessoasLocador[i].nome)
                modelo = modelo.replace(new RegExp(`@tel${i + 1}Locador`, 'g'), pessoasLocador[i].telefone)
                modelo = modelo.replace(new RegExp(`@porc${i + 1}Locador`, 'g'), pessoasLocador[i].percentagem + "%")
                modelo = modelo.replace(new RegExp(`@end${i + 1}Locador`, 'g'), `${pessoasLocador[i].endereco || ''}, ${pessoasLocador[i].numero || ''} ${pessoasLocador[i].complemento || ''}, ${pessoasLocador[i].bairro || ''} - ${pessoasLocador[i].cidade || ''} / ${pessoasLocador[i].uf || ''}`)
                modelo = modelo.replace(new RegExp(`@cpf${i + 1}Locador`, 'g'), pessoasLocador[i].cpf_cnpj)
                modelo = modelo.replace(new RegExp(`@email${i + 1}Locador`, 'g'), pessoasLocador[i].email)
            }

            this.setState({
                modelo: modelo,
                nome: re.data.data[0].nome
            })
        }
    }

    render() {
        return (
            <>
                <div className='box__content__print' style={{paddingLeft: "20px"}} dangerouslySetInnerHTML={{__html: this.state.modelo}}>
                </div>

                {
                    this.state.inPrint ? (null) : (
                        <div className="row">
                            <div className="col-md-3">
                                <button className="tohidden button btn__primary--outline" onClick={this.requestSign}>Enviar com ZapSign</button>
                            </div>
                            <div className="col-md-3">
                                <button className="tohidden button btn__primary" onClick={a => window.print()}>Imprimir</button>
                            </div>
                        </div>
                    )
                }

            </>
        )
    }
}

export default ImprimirModeloContratoPage