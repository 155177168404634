import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { api_freedom } from '../services/apis';


class ModelosEditPage extends React.Component {
    constructor(props) {
        super(props);

        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            modelo: "",
            nome: "",
        }

        this.getData()
    }

    getData = async () => {
        let re = await api_freedom.get("/modelo/getunique/" + this.props.match.params.id, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
        
        if(re.data.data.indexOf('TOKEN') === -1){
            this.setState({ 
                modelo: re.data.data[0].modelo,
                nome: re.data.data[0].nome,
            })
        }
    }

    genModLocador = (editor) => {
        let modLocador = []

        for (let i = 1; i <= 7; i++) {
            modLocador.push(...[
                {
                    type: 'menuitem',
                    text: 'Nome locador ' + i,
                    onAction: function (_) {
                        editor.insertContent(`@nome${i}Locador`);
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Telefone locador ' + i,
                    onAction: function (_) {
                        editor.insertContent(`@tel${i}Locador`);
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Porcentagem locador ' + i,
                    onAction: function (_) {
                        editor.insertContent(`@porc${i}Locador`);
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Endereço locador ' + i,
                    onAction: function (_) {
                        editor.insertContent(`@end${i}Locador`);
                    }
                },
                {
                    type: 'menuitem',
                    text: 'CPF/CNPJ locador ' + i,
                    onAction: function (_) {
                        editor.insertContent(`@cpf${i}Locador`);
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Email locador ' + i,
                    onAction: function (_) {
                        editor.insertContent(`@email${i}Locador`);
                    }
                },
            ])
            
        }

        return modLocador
    }

    saveEditedModelo = async () => {
        if (!this.state.nome || !this.state.modelo) {
            return alert("Preencha os campos")
        }

        await api_freedom.post("/modelo/edit", {
            nome: this.state.nome,
            modelo: this.state.modelo,
            id: this.props.match.params.id
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.props.history.push("/freedom/modelos")
    }

    render() {
        return (
            <>
                <h4>
                    Editar modelo de documento
                </h4>
                <div className="row">
                    <div className='grupo__campo col-md-5'>
                        <label style={{width: '50%'}}>Nome: </label><br/>
                        <div className='grupo__campo__form grupo__campo--input--select'>
                            <input type='month' defaultValue={this.state.nome} onChange={(event) => {
                                this.setState({nome: event.target.value})
                            }}/>
                        </div>
                    </div>
                </div>
                <Editor
                    value={this.state.modelo}
                    apiKey="occ4ghutecdaq87x2848mvdhjlxbusf5l8615kzqopriexe1"
                    onEditorChange={(e, ed) => this.setState({ modelo: e })}
                    init={{
                        plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste imagetools wordcount"
                        ],
                        toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect image | lista",
                        height: 500,
                        setup: (editor) => {
                            let mod = this.genModLocador(editor)
                            editor.ui.registry.addMenuButton('lista', {
                                icon: 'image-options',
                                fetch: function (callback) {
                                    let items = [
                                        
                                        {
                                            type: 'menuitem',
                                            text: 'ID do contrato',
                                            onAction: function (_) {
                                                editor.insertContent("@idContrato");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Endereço completo imóvel',
                                            onAction: function (_) {
                                                editor.insertContent("@imovel");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Inicio do contrato',
                                            onAction: function (_) {
                                                editor.insertContent("@dateStart");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Termino do contrato',
                                            onAction: function (_) {
                                                editor.insertContent("@dateEnd");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Valor do aluguel',
                                            onAction: function (_) {
                                                editor.insertContent("@valorAluguel");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Todos os locadores',
                                            onAction: function (_) {
                                                editor.insertContent("@locador");
                                            }
                                        },

                                        ...mod,

                                        {
                                            type: 'menuitem',
                                            text: 'Nome Locátario',
                                            onAction: function (_) {
                                                editor.insertContent("@nomeLocatario");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Endereço Locátario',
                                            onAction: function (_) {
                                                editor.insertContent("@endLocatario");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Telefone Locátario',
                                            onAction: function (_) {
                                                editor.insertContent("@telLocatario");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Email Locátario',
                                            onAction: function (_) {
                                                editor.insertContent("@emailLocatario");
                                            }
                                        },
                                        
                                        {
                                            type: 'menuitem',
                                            text: 'ID da imobiliária',
                                            onAction: function (_) {
                                                editor.insertContent("@idImob");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Razão social',
                                            onAction: function (_) {
                                                editor.insertContent("@razaoImob");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Imobiliária CNPJ',
                                            onAction: function (_) {
                                                editor.insertContent("@CNPJImob");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Imobiliária Telefone',
                                            onAction: function (_) {
                                                editor.insertContent("@telImob");
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Imobiliária Endereço',
                                            onAction: function (_) {
                                                editor.insertContent("@endImob");
                                            }
                                        },
                                    ];
                                    callback(items);
                                }
                            })
                        }
                        
                    }}
                />

                <button className='btn__primary button mt-4' onClick={this.saveEditedModelo}>Salvar modelo</button>

            </>
        )
    }
}

export default ModelosEditPage