
import React from "react"
import { api_freedom } from '../services/apis'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

class GerarRepasses extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            repasses: [],
            mensagem: 'Carregando ....',
            buscar_contrato: '',
            total_repasses: 0,
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: ''
        }

    }

    componentDidMount() {
        this.repasses()
    }

    repasses = async () => {
        try {
            console.log(jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id)
            let repasses = await api_freedom.get(`/boleto/repasses/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}/all`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (repasses.data.data.indexOf('TOKEN') === -1) {
                this.setState({
                    mensagem: 'Carregando ....',
                    total_repasses: repasses.data.data[0].total,
                    repasses: []
                })

                if (repasses.data.data[0].total == 0) {
                    this.setState({ mensagem: 'Nenhum registro encontrado.' })
                }

                for (var i = 1; i <= Math.ceil(repasses.data.data[0].total / 10); i++) {

                    if (document.getElementById('button_repasses')) {
                        document.getElementById('button_repasses').style.display = 'none'
                    }

                    let repasses_page = await api_freedom.get(`/boleto/repasses/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}/${i}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                    this.setState({ repasses: this.state.repasses.concat(repasses_page.data.data) })
                    console.log("localStorage: " + jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id);
                    if (i === Math.ceil(repasses.data.data[0].total / 10)) {
                        this.setState({ mensagem: 'Nenhum registro encontrado.' })
                        this.fecharModal()
                    }

                    if (document.getElementById('button_repasses')) {
                        if (i === Math.ceil(repasses.data.data[0].total / 10)) {
                            document.getElementById('button_repasses').style.display = 'block'
                        }
                    }

                }
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }


    listarRepasses = (record, index) => {

        return (
            <tr key={index}>
                <td>{record.contrato_id}</td>
                <td>{this.capitalize(new Date(record.data_vencimento).toLocaleString('pt-BR', { month: 'long' }))}</td>
                <td>{record.data_vencimento ? moment(record.data_vencimento).utc(false).format('DD/MM/YYYY') : 'Não gerado'}</td>
                <td>{record.pago ? `Sim, dia ${moment(record.pago).utc(false).format('DD/MM/YYYY')}` : `Não`}</td>
                <td>{(record.valor_aluguel || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td>{(record.valor || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td>{(record.valor_repasse || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td><button hidden={this.disable_button()} className='ml-3 button btn__primary--outline'
                    onClick={() => { this.gerarRepasse(record.id) }}> Gerar repasse </button></td>
            </tr>
        )
    }

    capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    abrirModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "flex";
    }

    fecharModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "none";
    }

    repassesGerar = () => {
        this.setState({
            modal_titulo: `Gerando repasses`,
            modal_corpo: <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                AGUARDE....
            </div>,
            modal_botoes: <></>
        })
        this.abrirModal()
        this.filtrar(this.state.repasses).map((r) => {
            this.gerarRepasseEmMassa(r.id)
            return null
        })
    }

    gerarRepasse = async (boleto) => {
        try {
            document.querySelector('.loader__back').classList.add("active")
            let repasse = await api_freedom.post(`/boleto/repasse`, {
                boleto_id: boleto,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (repasse.data.data.indexOf('REPASSE') > -1) {
                alert('REPASSE feito com sucesso')
                this.repasses()
            }
            else {
                alert(`Erro ao gerar o REPASSE`)
            }

            document.querySelector('.loader__back').classList.remove("active")
        } catch (error) {
            console.log(error.message)
        }
    }

    gerarRepasseEmMassa = async (boleto) => {
        try {
            let repasse = await api_freedom.post(`/boleto/repasse`, {
                boleto_id: boleto,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (repasse.data.data.indexOf('REPASSE') > -1) {
                this.repasses()
            }
            else {
                alert(`Erro ao gerar o REPASSE do ${boleto}`)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    filtrar = (repasses) => {
        var filtro = repasses.filter((c) => {
            return this.filtrarContrato(c)
        })

        return filtro
    }

    filtrarContrato = (repasse) => {
        return !this.state.buscar_contrato || (repasse.contrato_id && repasse.contrato_id.toString().toLowerCase().includes(this.state.buscar_contrato))
    }

    disable_button() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    render() {
        return (
            <>
                <div className="loader__back">
                    <div className="loader__rolling">
                        <div className="loader"></div>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <label>Total de repasses: {this.state.total_repasses}</label><br />
                            Contrato:
                            <div className="grupo__campo__form grupo__campo--input--select">
                                <input type='text' value={this.state.buscar_contrato} onChange={(event) => {
                                    this.setState({ buscar_contrato: event.target.value })
                                }} />
                            </div>
                        </div>
                        {this.filtrar(this.state.repasses).length > 0 && <button id='button_repasses' className='button btn__primary'
                            onClick={() => {
                                this.setState({
                                    modal_titulo: `Gerar repasses`,
                                    modal_corpo: <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                                        Deseja realmente prosseguir? <br />Serão gerados {this.filtrar(this.state.repasses).length} repasses
                                    </div>,
                                    modal_botoes: <>
                                        <button className='button btn__primary--outline' onClick={() => { this.fecharModal() }}>Não</button>
                                        <button className='button btn__primary' onClick={() => { this.fecharModal(); this.repassesGerar() }}>Sim</button>
                                    </>
                                })
                                this.abrirModal()
                            }}> Gerar repasses </button>}
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label># Contrato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Mês</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Vencimento do boleto</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Pago</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor do aluguel</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor do boleto</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor do repasse</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Gerar repasse</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.filtrar(this.state.repasses).length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                {this.filtrar(this.state.repasses).map(this.listarRepasses)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="content-modal" id="modal" style={{ display: 'none' }}>
                    <div className="modal">
                        <div className="modal-head">
                            {this.state.modal_titulo}
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                {this.state.modal_corpo}
                            </div>
                        </div>
                        <div className="modal-foot">
                            {this.state.modal_botoes}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GerarRepasses;
