import React, { useEffect, useState } from "react";
import moment from "moment";
import { api_cep, api_freedom } from "../services/apis";
import jwt_decode from "jwt-decode";
import { cpf, cnpj } from "cpf-cnpj-validator";

const NovoLocatario = (props) => {
  const [id, setId] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [nome, setNome] = useState("");
  const [rgIe, setRgIe] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [contrato, setContrato] = useState("");


  const verificarPessoa = async (cpf_cnpj) => {
    let pessoa = await api_freedom.post(
      `/pessoa/cpfcnpj`,
      {
        cpf_cnpj: cpf_cnpj,
        imobiliaria_id: jwt_decode(localStorage.getItem("autenticado_freedom"))
          .imobiliaria_id,
      },
      {
        headers: { authorization: localStorage.getItem("autenticado_freedom") },
      }
    );

    if (pessoa.data.data.length > 0) {
      return pessoa.data.data[0];
    }

    return false;
  };

    useEffect(() => {
      console.log(props);
    }, [nome]);

    
    return (
      <>
      <div className="content-modal" id="modal" style={{display: "flex"}}>
          <div className="modal">

        <div className="modal-head">Novo Locarário</div>
          <div className="modal-body">
            <div className="modal-scroll">
            <div style={{ marginTop: "1%" }}>
            <div className="grupo__campo">
              {/* <div className="col-md-4"> */}
                <div className="grupo__campo" name={`locatario_cpf_cnpj`}>
                  <label style={{ width: "100%" }}>CPF/CNPJ: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      maxLength={18}
                      value={cpfCnpj}
                      onChange={async (event) => {
                        // this.preencheu(`locatario_cpf_cnpj`);

                        var cpf_cnpj;
                        if (
                          event.target.value.replace(/[^\d]/g, "").length <= 11
                        ) {
                          cpf_cnpj = event.target.value
                            .replace(/[^\d]/g, "")
                            .replace(
                              /(\d{3})(\d{3})(\d{3})(\d{2})/,
                              "$1.$2.$3-$4"
                            );
                        } else {
                          cpf_cnpj = event.target.value
                            .replace(/[^\d]/g, "")
                            .replace(
                              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                              "$1.$2.$3/$4-$5"
                            );
                        }
                        setCpfCnpj(cpf_cnpj)

                        if (
                          event.target.value.replace(/[^\d]/g, "").length ===
                            11 ||
                          event.target.value.replace(/[^\d]/g, "").length === 14
                        ) {
                          if (
                            event.target.value.replace(/[^\d]/g, "").length ===
                              11 &&
                            !cpf.isValid(
                              event.target.value.replace(/[^\d]/g, "")
                            )
                          ) {
                            alert("CPF inválido");
                          }
                          if (
                            event.target.value.replace(/[^\d]/g, "").length ===
                              14 &&
                            !cnpj.isValid(
                              event.target.value.replace(/[^\d]/g, "")
                            )
                          ) {
                            alert("CNPJ inválido");
                          }

                          var pessoa = await verificarPessoa(cpf_cnpj);

                          if (pessoa) {
                              setId(pessoa.id)
                              setNome(pessoa.nome)
                              setRgIe(pessoa.rg_ie)
                              setEmail(pessoa.email)
                              setTelefone(pessoa.telefone)
                              setCelular(pessoa.celular)
                              setCep(pessoa.cep)
                              setEndereco(pessoa.endereco)
                              setBairro(pessoa.bairro)
                              setCidade(pessoa.cidade)
                              setEstado(pessoa.uf)
                              setNumero(pessoa.numero)
                              setComplemento(pessoa.complemento)
                              // this.state.ltario_id
                              //   ? this.state.locatario_contrato_backup
                              //   : ""
                          } else{
                            setId("")
                            setNome("")
                            setRgIe("")
                            setEmail("")
                            setTelefone("")
                            setCelular("")
                            setCep("")
                            setEndereco("")
                            setBairro("")
                            setCidade("")
                            setEstado("")
                            setNumero("")
                            setComplemento("")
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              
                <div className="grupo__campo" name={`locatario_nome`}>
                  <label style={{ width: "100%" }}>Nome: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      name="locatario_nome"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                    />
                  </div>
                </div>
              
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="grupo__campo">
                  <label style={{ width: "100%" }}>RG: </label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={rgIe}
                      onChange={(event) => setRgIe(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="grupo__campo" name={`locatario_email`}>
                  <label style={{ width: "100%" }}>Email: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="grupo__campo">
                  <label style={{ width: "100%" }}>Telefone: </label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={telefone}
                      onChange={(event) => setTelefone(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grupo__campo">
                  <label style={{ width: "100%" }}>Celular: </label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={celular}
                      onChange={(event) => setCelular(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="grupo__campo" name={`locatario_cep`}>
                  <label style={{ width: "100%" }}>CEP: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="grupo__campo" name={`locatario_endereco`}>
                  <label style={{ width: "100%" }}>Endereço: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={endereco}
                      onChange={(event) => setEndereco(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="grupo__campo" name={`locatario_numero`}>
                  <label style={{ width: "100%" }}>Número: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={numero}
                      onChange={(event) => setNumero(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grupo__campo">
                  <label style={{ width: "100%" }}>Complemento: </label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={complemento}
                      onChange={(event) => setComplemento(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grupo__campo" name={`locatario_bairro`}>
                  <label style={{ width: "100%" }}>Bairro: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={bairro}
                      onChange={(event) => setBairro(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="grupo__campo" name={`locatario_cidade`}>
                  <label style={{ width: "100%" }}>Cidade: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <input
                      type="text"
                      value={cidade}
                      onChange={(event) => setCidade(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grupo__campo" name={`locatario_estado`}>
                  <label style={{ width: "100%" }}>Estado: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <select
                      value={estado}
                      onChange={(event) => setEstado(event.target.value)}
                    >
                      <option>Selecione</option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        <div className="modal-foot">
          <button
            className="button btn__primary--outline"
            onClick={props.fechar}
          >
            Fechar
          </button>
          <button
            className="button btn__primary"
            // onClick={this.adicionarServico}
          >
            Adicionar
          </button>
        </div>
        </div>
        </div>
      </>
    )
}

export default NovoLocatario