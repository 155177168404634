import moment from 'moment'
import React from "react"
import { api_freedom, api_freedom_sender } from '../services/apis'
import jwt_decode from 'jwt-decode'

class GerarTransferencias extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            repasses: [],
            mensagem: 'Carregando ....',
            total_repasses: 0,
            modal: false,
            id: '',
            mes_ano: '',
            imobiliaria: '',
            contrato_id: '',
            modal_titulo: '',
            modal_body: '',
            modal_foot: '',
        }

    }

    componentDidMount() {
        this.repasses()
    }

    repasses = async () => {
        try {
            let repasses = await api_freedom.get(`/boleto/semted/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (repasses.data.data.indexOf('TOKEN') === -1) {
                console.log(repasses.data.data)
                this.setState({
                    repasses: repasses.data.data,
                    mensagem: 'Nenhum registro encontrado.',
                    total_repasses: repasses.data.data.length
                })

            }
        }
        catch (error) {
            console.log(error.message)
        }
    }


    listarRepasses = (record, index) => {

        return (
            <tr key={index}>
                <td>{record.id}</td>
                <td>{record.contrato_id}</td>
                <td>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td>{record.nome}</td>
                <td>{record.cpf_cnpj}</td>
                {record.repasse_garantido == 'RG' && (<td>{(<p>Repasse Garantido</p>)}</td>)}
                {record.repasse_garantido != 'RG' && (
                    <td>{
                        moment(record.data_transferencia).utc(false).format('DD/MM/YYYY') === 'Invalid date'
                            ? 'Data Indisponivel'
                            : moment(record.data_transferencia).utc(false).format('DD/MM/YYYY')
                    }</td>
                )}
                <td><button hidden={this.disable_button()} className='btn__primary--outline button'
                    onClick={() => { this.gerarTransferencia(record.id) }}> Gerar transferência </button></td>
                {this.disable() ? <td><button className="btn__primary--outline button" onClick={() => this.openModal(record)}>Excluir repasse</button></td> : ''}
            </tr>
        )
    }

    gerarTransferencia = async (repasse) => {
        try {
            let transferencia = await api_freedom.get(`/boleto/ted/${repasse}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            // const transferencia = await api_freedom_sender.get(`/transferencia/ted/${repasse}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
            console.log(transferencia.data.message);
            if (transferencia.status === 200) {
                if (transferencia.data.message) {
                    alert(transferencia.data.message)
                } else {
                    alert(transferencia.data.data)
                }
                this.repasses()
            }
            else {
                alert(`${transferencia.data.data}`)
            }
        } catch (error) {
            console.log(error.message)
            alert(`${error.message}`)
        }
    }

    disable_button() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    disable() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "ReadWriteMaster") {
            return true
        } else {
            return false
        }
    }

    excluirRepasse = async () => {
        try {
            let del = await api_freedom.post(`/financeiro/repasse/delete`, {
                imobiliaria_id: this.state.imobiliaria_id,
                contrato_id: this.state.contrato_id,
                id: this.state.id,
                mes_ano: this.state.mes_ano
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (del.data.data) {
                await api_freedom.post(`/financeiro/repasse/servicos/delete`, {
                    imobiliaria_id: this.state.imobiliaria_id,
                    contrato_id: this.state.contrato_id,
                    mes_ano: this.state.mes_ano
                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                this.setState({
                    modal: false
                })

                this.repasses();
            } else {
                this.setState({
                    modal_titulo: 'Ocorreu um erro',
                    modal_body: 'Não foi possível excluir o repasse!',
                    modal_foot: <>
                        <button className="btn__freedom" onClick={() => this.setState({ modal: false })}>Fechar</button></>
                })

                this.repasses();
            }

        } catch {
            this.setState({
                modal_titulo: 'Ocorreu um erro',
                modal_body: 'Tente novamente!',
                modal_foot: <>
                    <button className="btn__freedom" onClick={() => this.setState({ modal: false })}>Fechar</button></>
            })
        }
    }

    openModal = (line) => {
        this.setState({
            modal: true,
            id: line.id,
            mes_ano: line.mes_ano,
            contrato_id: line.contrato_id,
            imobiliaria_id: line.imobiliaria_id,
            modal_titulo: 'Excluir repasse',
            modal_body: 'Deseja realmente Excluir o repasse?',
            modal_foot: <><button className="btn__freedom btn--active" onClick={() => this.excluirRepasse()}>Excluir</button>
                <button className="btn__freedom" onClick={() => this.setState({ modal: false })}>Cancelar</button></>
        })
    }

    render() {
        return (
            <>
                <div className="content-modal" style={{ display: this.state.modal ? 'flex' : 'none' }}>
                    <div className="modal" style={{ width: "auto" }}>
                        <div className="modal-head">
                            {this.state.modal_titulo}
                        </div>
                        <div className="modal-body">
                            <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                                {this.state.modal_body}
                            </div>
                        </div>
                        <div className="modal-foot">
                            <div className="btns">
                                {this.state.modal_foot}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <label>Total de repasses: {this.state.total_repasses}</label><br />
                        </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>Repasse</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label># Contrato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor da transferência</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Pessoa</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>CPF / CNPJ</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Data Transferencia</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Gerar transferência</label>
                                        </div>
                                    </th>
                                    {
                                        this.disable ? (
                                            <th>
                                                <div class="thead__item">
                                                    <label>Excluir Repasse</label>
                                                </div>
                                            </th>
                                        ) : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.repasses.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                {this.state.repasses.map(this.listarRepasses)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default GerarTransferencias;
