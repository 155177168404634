import React from "react";
import { api_freedom } from "../services/apis";
import jwt_decode from 'jwt-decode'

class NfePage extends React.Component {
    constructor(props) {
        super(props)

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 
        
        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            years: new Date().getFullYear(),
            months: new Date().getMonth() + 1,
            transferencias: [],
            mensagem: "Nenhum dado encontrado no mês selecionado.",
        }

        this.getData()
    }

    twoNumber = (d) => {
        return d.toString().length == 1 ? `0${d}` : d
    }

    getData = async () => {
        this.setState({ mensagem: "Carregando..." })
        const re = await api_freedom.post(`/transferencia/tranferenciasfinalizadas/${this.state.dados_login.imobiliaria_id}`, {
            mes_ano: this.state.years + "-" + this.twoNumber(this.state.months)
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(re.data.data.indexOf('TOKEN') === -1){
            this.setState({transferencias: re.data.data})
            if(re.data.data.length === 0) this.setState({ mensagem: "Nenhum dado encontrado no mês selecionado." })
        }
    }

    genNfe = async (data, unique = true) => {
        let re = await api_freedom.post(
            `/nfse/gerar/${this.state.dados_login.imobiliaria_id}`, 
            {
                contrato: data.contrato_id,
                locador_id: data.pessoa_id,
                valor: data.valor,
                transferencia: data.id
            }, 
            {headers: {"authorization": localStorage.getItem('autenticado_freedom')}}
        )

        if (re.data.indexOf("Error") != -1) {
            switch (re.data) {
                case "Error:i":
                    alert("Dados da configuração da imobiliária e/ou locador não econtrados")
                    break

                case "Error:l":
                    alert("Locador está com informações faltando")
                    break

                case "Error:c":
                    alert("Cidade não encontrada no estado do locador")
                    break

                case "Error:s":
                    alert("Erro ao gerar nota fiscal")
                    break

                default:
                    alert("Erro ao gerar nota fiscal")
                    break;
            }
        } 
        else if (unique) {
            alert("Nota criada com sucesso")
            this.getData()
        }
    }

    cancelNfse = async () => {
        const re = await api_freedom.post(
            `/nfse/cancelar/${this.state.dados_login.imobiliaria_id}`, 
            {}, 
            {headers: {"authorization": localStorage.getItem('autenticado_freedom')}}
        )

        if (re.data.indexOf("Error") != -1) {
            switch (re.data) {
                case "Error:n":
                    alert("Nota não encontrada no sistema")
                    break

                case "Error:c":
                    alert("Error ao remover a nota fiscal")
                    break

                default:
                    alert("Error ao remover a nota fiscal")
                    break;
            }
        } 
        else {
            alert("Nota cancelada com sucesso")
            this.getData()
        }
    }

    actionNfse = (e, data) => {
        switch (e.target.value) {
            case "1":
                break

            case "2":
                this.genNfe(data)
                break

            case "3":
                this.cancelNfse()
                break

            case "4":
                var link = document.createElement('a');
                link.target = '_blank';
                link.innerHTML = 'Download PDF file';
                link.download = `NFSE_${data.id}_Contrato_${data.contrato_id}.pdf`;
                link.href = 'data:application/octet-stream;base64,' + data.nfse_pdf;
                link.click();
                // window.open(link, "_blank")var link = document.createElement('a');
                // window.open(data.nfse_pdf, "_blank")
                break

            default:
                alert("Opção inválida")
                break

        }
    }

    changeDate = (e) => {
        this.setState({
            months: e.split("-")[1],
            years: e.split("-")[0],
        }); 

        this.state.months = e.split("-")[1]
        this.state.years = e.split("-")[0]
        
        this.getData()
    }

    fotprint = () => {
        this.setState({inPrint: true})

        setTimeout(() => {
            window.print()

            this.setState({inPrint: false})
        }, 350);
    }

    genCSV = () => {
        let csv = 'ID,Contrato,Valor,Destinatário,Locador,Data envio,Data repasse,Mês ano\n';

        for (const e of this.state.transferencias) {
            console.log(e);
            csv += `"${e.id}","${e.contrato_id}","${e.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}","${e.name_destino}","${e.pessoa_nome} | ${e.cpf_cnpj}","${e.transferencia_enviada ? new Date(e.transferencia_enviada).toLocaleString() : "---"}","${e.data_transferencia ? new Date(e.data_transferencia).toLocaleDateString() : "---"}","${e.mes_ano.split("-")[1]}/${e.mes_ano.split("-")[0]}",\n`
        }

        csv = csv.slice(0, csv.length - 1)
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'relatorio_transferencia.csv';
        hiddenElement.click();
    }

    render() {
        return (
            <>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h2 style={{display: "none"}} className="toshow">Notas fiscais</h2>
                        <div>
                            <button className='tohidden btn__primary button' onClick={this.fotprint}>Imprimir relatório</button>
                            <button className='tohidden btn__primary button' onClick={this.genCSV}>Gerar CSV</button>
                            <br/>
                            <div className='grupo__campo__form grupo__campo--input--select'>
                                <input type="month" value={this.state.years + "-" + this.twoNumber(this.state.months)} onChange={e => this.changeDate(e.target.value)}/>
                            </div> 
                        </div>
                    </div>
                    <div className="table mt-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>ID</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label># Contrato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Destinatário</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Locador</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Data envio</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Data repasse</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Mês ano</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Status</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Status NF</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Ação</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.transferencias.length === 0 ? (
                                    <tr><th className='text-center' colSpan='12'>{this.state.mensagem}</th></tr>
                                ) :
                                this.state.transferencias.map(e => (
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{e.contrato_id}</td>
                                        <td>{e.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{e.name_destino}</td>
                                        <td>{e.pessoa_nome} | {e.cpf_cnpj}</td>
                                        <td>{e.transferencia_enviada ? new Date(e.transferencia_enviada).toLocaleString() : "---"}</td>
                                        <td>{e.data_transferencia ? new Date(e.data_transferencia).toLocaleDateString() : "---"}</td>
                                        <td>{e.mes_ano.split("-")[1]}/{e.mes_ano.split("-")[0]}</td>
                                        <td>{e.status}</td>
                                        <td>{e.nfse_status ? (e.nfse_status) : ("Ainda não gerado")}</td>
                                        <td>
                                            {e.nfse_status ? (
                                                e.nfse_status === "CONCLUIDO" ? String(e.nfse_pdf).length > 100 ? (
                                                    <select onChange={i => this.actionNfse(i, e)}>
                                                        <option value="1">Ação</option>
                                                        <option value="4">Baixar PDF</option>
                                                        <option value="3">Cancelar nota</option>
                                                    </select>
                                                ) : (
                                                    <select onChange={i => this.actionNfse(i, e)}>
                                                        <option value="1">Ação</option>
                                                        <option value="3">Cancelar nota</option>
                                                    </select>
                                                ) : (
                                                    e.nfse_status === "CANCELADO" ? (
                                                        <select onChange={i => this.actionNfse(i, e)}>
                                                            <option value="1">Ação</option>
                                                            <option value="2">Regerar</option>
                                                        </select>
                                                    ) : (null)
                                                )
                                            ) : (
                                                <select onChange={i => this.actionNfse(i, e)}>
                                                    <option value="1">Ação</option>
                                                    <option value="2">Gerar</option>
                                                </select>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

export default NfePage