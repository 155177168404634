import React, { useEffect, useState } from "react";
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { api_freedom } from "../services/apis";

const RepasseGarantido = () => {
    const [boletosVencidos, setBoletosVencidos] = useState([]);
    const [totalBoletos, setTotalBoletos] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        buscaTotalBoletosVencidos();
        buscaBoletosVencidos();
    }, [])

    const buscaTotalBoletosVencidos = async () => {
        await api_freedom.get(`/boleto/repasse-garantido/boletos-vencidos/nao-repassados/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}/all`,
            { headers: { "authorization": localStorage.getItem('autenticado_freedom') } }).then((boletos) => {
                setTotalBoletos(boletos.data.total[0].total);
            })
    }

    const buscaBoletosVencidos = async () => {
        setIsLoading(true);
        await api_freedom.get(`/boleto/repasse-garantido/boletos-vencidos/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`,
            { headers: { "authorization": localStorage.getItem('autenticado_freedom') } }).then((boletos) => {
                // console.log(boletos);
                console.log(boletos.data.boletos_vencidos[0])
                setBoletosVencidos(boletos.data.boletos_vencidos);
            })
        setIsLoading(false);
    }

    const calculaDiasInadimplentes = (data) => {
        const dataAtual = moment();
        const dataVencimento = moment(data, 'YYYY-MM-DD').startOf('day');
        const diferencaEmDias = dataAtual.diff(dataVencimento, 'days');
        return diferencaEmDias;
    }

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const geraRepasse = async (idBoleto) => {
        try {
            console.log(idBoleto);
            api_freedom.post(`/boleto/repasse-garantido/repasse`, {
                boleto_id: idBoleto,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                .then((resultado) => {
                    console.log(resultado);
                    alert('Repasse feito com sucesso.');
                    buscaTotalBoletosVencidos();
                    buscaBoletosVencidos();
                }).catch((error) => {
                    alert('Erro ao gerar repasse.' + error);
                })
        } catch (error) {
            console.log(error);
        }
    }

    return (<>
        <label>
            <p>Total de boletos atrasados e não repassados: {totalBoletos}</p>
        </label>
        <div>
            <div className='table mt-4'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                <div class="thead__item">
                                    <label># Contrato</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Mês Referência</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Vencimento do boleto</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Dias após vencimento</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Dia do Repasse Garantido</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Valor do aluguel</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Valor do boleto</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Valor do repasse</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Repassar</label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && boletosVencidos.length === 0 && (
                            <tr><th className='text-center' colSpan='8'>Nenhum registro encontrado.</th></tr>
                        )}
                        {isLoading && (
                            <tr><th className='text-center' colSpan='8'>Carregando...</th></tr>
                        )}
                        {boletosVencidos.map((boleto, index) => (
                            <tr key={index} >
                                <td>{boleto.contrato_id}</td>
                                <td>{capitalize(new Date(boleto.mes_ano).toLocaleString('pt-BR', { month: 'long' }))}</td>
                                <td><p className="text-danger"><strong>{moment(boleto.data_vencimento).utc(false).format('DD/MM/YYYY')}</strong></p></td>
                                <td><p className="text-danger"><strong>{calculaDiasInadimplentes(boleto.data_vencimento)}</strong></p></td>
                                <td>{boleto.dia_repasse_garantido}</td>
                                <td>{(boleto.valor_aluguel || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td>{(boleto.valor || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td>{(boleto.valor_repasse || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td><button className='ml-3 button btn__primary--outline'
                                    onClick={() => { geraRepasse(boleto.id) }}> Repassar </button>
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </>)
}

export default RepasseGarantido;