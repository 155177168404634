import React, { useEffect, useState } from "react";
import { api_freedom } from "../services/apis";

const AdicionarMunicipio = (props) => {

  const [municipio, setMunicipio] = useState("");
  const [estado, setEstado] = useState("");
  const [codigo, setCodigo] = useState("");

  const adicionarMunicipio = async () => {

    if(!municipio){
      preencher("municipio");
      return 0;
    }

    if(!estado){
      preencher("estado");
      return 0;
    }

    if(!codigo){
      preencher("codigo_dimob");
      return 0;
    }

    
      try {

        const response = await api_freedom.post(
          "/municipiosdimob/create",
          {
            municipio: municipio,
            estado: estado,
            codigo: codigo,
          },
          {
            headers: {
              "authorization": localStorage.getItem('autenticado_freedom')
            }
          }
        );

        if(response.status === 201){
          
          alert(response.data.mensagem)
          props.fechar();
          props.atualizar();

        } else{
          
          alert(response.data.mensagem)
          
        }

      } catch(error) {
        props.fechar();
        console.log(error);
        alert("Ocorreu um erro");
      }
    }

  const preencher = (name) => {
    if(!document.getElementsByName(name)[0].classList.contains('form--error')){
        document.getElementsByName(name)[0].classList.add('form--error')
    }
  }

  const preencheu = (name) => {
    if (document.getElementsByName(name)[0].classList.contains("form--error")) {
      document.getElementsByName(name)[0].classList.remove("form--error");
    }
  };


  useEffect(() => {}, []);

  
  return (
    <>
    <div className="content-modal" id="modal" style={{display: "flex"}}>
        <div className="modal">

      <div className="modal-head">Adicionar Município</div>
        <div className="modal-body">
          <div className="modal-scroll">
            <div style={{ marginTop: "1%" }}>
              <div className="grupo__campo">

                <div className="row">
                  <div className='col-md-12'>
                    <div className='grupo__campo' name={`municipio`}>
                      <label style={{width: '100%'}}>Município *</label><br/>
                      <div className='grupo__campo__form'>
                        <input type='text' value={municipio} onChange={(event) => {  
                          preencheu(`municipio`);
                          setMunicipio(event.target.value)
                        }} required/>
                      </div>    
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="grupo__campo" name={`estado`}>
                      <label style={{ width: "100%" }}>Estado: *</label>
                      <br />
                      <div className="grupo__campo__form">
                        <select
                          value={estado}
                          onChange={(event) => {
                            preencheu(`estado`);
                            setEstado(event.target.value);
                          }}
                          required
                        >
                          <option>Selecione</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='grupo__campo' name={`codigo_dimob`}>
                      <label style={{width: '100%'}}>Código Dimob *</label><br/>
                      <div className='grupo__campo__form'>
                        <input type='text' value={codigo} onChange={(event) => {
                          preencheu(`codigo_dimob`);
                          setCodigo(event.target.value)
                        }} required/>
                      </div>    
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        
      <div className="modal-foot">
        <button
          className="button btn__primary--outline"
          onClick={() => props.fechar()}
        >
          Fechar
        </button>
        <button
          className="button btn__primary"
          onClick={adicionarMunicipio}
        >
          Adicionar
        </button>
      </div>
      </div>
      </div>
    </>
  )
}

export default AdicionarMunicipio