
import React from "react"
import moment from "moment"
import { api_cep, api_freedom } from "../services/apis"
import jwt_decode from "jwt-decode"
import { cpf, cnpj } from "cpf-cnpj-validator"
import calcularImpostoLocador from "../util/calcularImpostoLocador"

class CadastrarContrato extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            dias: [...Array(31)],
            porcentagem: [...Array(101)],
            data_inicio: moment().format('YYYY-MM-DD'),
            data_fim: moment().format('YYYY-MM-DD'),
            data_rescisao: '',
            valor_aluguel: 0,
            tipo_locacao: '',
            obs: '',
            is_repasse_garantido: false,
            is_taxa_fixa: false,
            juros_por_dia: 0,
            multa: 0,
            dia_transferencia: 1,
            somar_dia_transferencia: 0,
            taxa_administracao_fixa: 0,
            perc_taxa_administracao: 0,
            dia_repasse_garantido: 1,
            dia_vencimento_aluguel: 1,
            aberto_fechado: 1,
            imovel_cep: '',
            imovel_endereco: '',
            imovel_bairro: '',
            imovel_cidade: '',
            imovel_estado: '',
            imovel_numero: '',
            imovel_complemento: '',
            imovel_metragem: 0,
            locatario_id: 0,
            locatario_cpf_cnpj: '',
            locatario_nome: '',
            locatario_rg_ie: '',
            locatario_email: '',
            locatario_telefone: '',
            locatario_celular: '',
            locatario_cep: '',
            locatario_endereco: '',
            locatario_bairro: '',
            locatario_cidade: '',
            locatario_estado: '',
            locatario_numero: '',
            locatario_complemento: '',
            id_contrato: '',
            imoveis: [],
            locadores: [{
                id: 0,
                cpf_cnpj: '',
                nome: '',
                rg_ie: '',
                email: '',
                telefone: '',
                celular: '',
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                estado: '',
                numero: '',
                complemento: '',
                porcentagem_imovel: 0,
                banco: '',
                agencia: '',
                conta: '',
                corrente_poupanca: 0,
                beneficiario_nome: '',
                beneficiario_cpf_cnpj: ''
            }],
            contrato: [],
            contrato_id: '',
            contrato_e: false,
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: '',
            avisoTxt: '',

            tipo_garantia: '',
            contem_garantia: 2,
            seguro: "",
            titulo: "",
            fiadores: [{ fiador: '', cpf_fiador: '', fiador_id: '', telefone: '', celular: '' }],
            valor_calcao: '',
            responsabilidade: '',
            vigencia_inicial_seguro: "",
            vigencia_final_seguro: "",
            valor_garantia: "",
            instituicao_financeira: "",

            apolice: '',
            seguradora: '',
            vigencia: '',
            reter_iss: false,
            reter_ir: false,
            emitir_nfe: false,
            docs: [],

            ativo_inativo: "",
            cobrar_ted: "",
            mes_indice_reajuste: 0,
            agua: '',
            luz: '',
            condominio: '',
            iptu: '',
            indices: [],
            indice: '',
        }

    }

    componentDidMount() {

        if (this.disable()) {
            this.props.history.push('contratos')
        } else {
            this.servicos()
            this.contratos();
        }
        this.getIndices();
    }

    getIndices = async () => {
        let { data } = await api_freedom.get("/indice/all", { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        if (data.data.indexOf('TOKEN') == -1) {
            this.setState({
                indices: data.data,
            })
        }
    }

    contratos = async () => {
        let contrato = await api_freedom.get('/contrato/idimobiliaria/' + jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } });
        if (contrato.data.data.indexOf('TOKEN') == -1) {
            this.setState({ contrato: contrato.data.data })
        }
    }

    servicos = async () => {
        try {
            let servicos = await api_freedom.get(`/servico/all`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (servicos.data.data.indexOf('TOKEN') == -1) {
                this.setState({ servicos: servicos.data.data })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    abrirModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "flex";
    }

    fecharModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "none";
        this.setState({ modal_titulo: '', modal_corpo: '', modal_botoes: '' })
    }

    changeCepImovel = (event) => {
        this.preencheu(`imovel_cep`)
        if (event.target.value.replace(/\D/g, '').length == 8) {
            api_cep.get(`/${event.target.value.replace(/\D/g, '')}/json`).then((dados_cep) => {
                this.setState({
                    imovel_cep: dados_cep.data.cep,
                    imovel_endereco: dados_cep.data.logradouro,
                    imovel_bairro: dados_cep.data.bairro,
                    imovel_cidade: dados_cep.data.localidade,
                    imovel_estado: dados_cep.data.uf,
                    imovel_numero: '',
                    imovel_complemento: '',
                })
            })
        }
        else {
            this.setState({
                imovel_cep: event.target.value,
                imovel_endereco: '',
                imovel_bairro: '',
                imovel_cidade: '',
                imovel_estado: '',
                imovel_numero: '',
                imovel_complemento: '',
            })
        }
    }

    changeCepLocatario = (event) => {
        this.preencheu(`locatario_cep`)
        if (event.target.value.replace(/\D/g, '').length == 8) {
            api_cep.get(`/${event.target.value.replace(/\D/g, '')}/json`).then((dados_cep) => {
                this.setState({
                    locatario_cep: dados_cep.data.cep,
                    locatario_endereco: dados_cep.data.logradouro,
                    locatario_bairro: dados_cep.data.bairro,
                    locatario_cidade: dados_cep.data.localidade,
                    locatario_estado: dados_cep.data.uf,
                    locatario_numero: '',
                    locatario_complemento: '',
                })
            })
        }
        else {
            this.setState({
                locatario_cep: event.target.value,
                locatario_endereco: '',
                locatario_bairro: '',
                locatario_cidade: '',
                locatario_estado: '',
                locatario_numero: '',
                locatario_complemento: '',
            })
        }
    }

    verificarPessoa = async (cpf_cnpj) => {
        let pessoa = await api_freedom.post(`/pessoa/cpfcnpj`, {
            cpf_cnpj: cpf_cnpj,
            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        if (pessoa.data.data.length > 0) {
            return pessoa.data.data[0]
        }

        return false
    }


    handleAddFiador = () => {
        this.setState({
            fiadores: [...this.state.fiadores, { fiador: '', cpf_fiador: '' }]
        });
    };

    handleInputChange = async (index, event) => {
        const { name, value } = event.target;
        const newFiadores = [...this.state.fiadores];
        let cpf_cnpj = value;

        if (name == "cpf_fiador") {
            if (cpf_cnpj.replace(/[^\d]/g, "").length <= 11) {
                cpf_cnpj = cpf_cnpj.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            } else {
                cpf_cnpj = cpf_cnpj.replace(/[^\d]/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }

            newFiadores[index][name] = cpf_cnpj;
            this.setState({ fiadores: newFiadores });

            if (cpf_cnpj.replace(/[^\d]/g, "").length == 11 || cpf_cnpj.replace(/[^\d]/g, "").length == 14) {
                if (cpf_cnpj.replace(/[^\d]/g, "").length == 11 && !cpf.isValid(cpf_cnpj.replace(/[^\d]/g, ""))) {
                    alert("CPF inválido");
                } else if (cpf_cnpj.replace(/[^\d]/g, "").length == 14 && !cnpj.isValid(cpf_cnpj.replace(/[^\d]/g, ""))) {
                    alert("CNPJ inválido");
                } else {
                    var pessoa = await this.verificarPessoa(cpf_cnpj);
                    if (pessoa) {
                        newFiadores[index] = {
                            ...newFiadores[index],
                            fiador_id: pessoa.id,
                            fiador: pessoa.nome,
                            telefone: pessoa.telefone,
                            celular: pessoa.celular
                        };
                        this.setState({ fiadores: newFiadores });
                    }
                }
            } else {
                newFiadores[index] = {
                    ...newFiadores[index],
                    fiador_id: "",
                    fiador: "",
                };
                this.setState({ fiadores: newFiadores });
            }
        } else {
            newFiadores[index][name] = value;
            this.setState({ fiadores: newFiadores });
        }
    };

    handleDeleteFiador = (index) => {
        console.log(index)
        const newArray = [...this.state.fiadores];
        newArray.splice(index, 1);
        this.setState({
            fiadores: [...newArray]
        })
    };


    carregarLocadores = (record, index) => {
        var locadores = this.state.locadores
        return (
            <>
                <label style={{ marginTop: '30px', fontSize: '1.1em', fontWeight: 'bolder' }}>Locador {index + 1}</label>
                <div className='row' key={index}>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_cpf_cnpj`}>
                            <label style={{ width: '100%' }}>CPF/CNPJ: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' maxLength={18} value={locadores[index].cpf_cnpj}
                                    onChange={async (event) => {
                                        this.preencheu(`locador${index}_cpf_cnpj`)

                                        var cpf_cnpj

                                        if (event.target.value.replace(/[^\d]/g, '').length <= 11) {
                                            cpf_cnpj = event.target.value.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                                        }
                                        else {
                                            cpf_cnpj = event.target.value.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                                        }
                                        locadores[index].cpf_cnpj = cpf_cnpj
                                        this.setState({ locadores: locadores })

                                        if (event.target.value.replace(/[^\d]/g, '').length == 11 || event.target.value.replace(/[^\d]/g, '').length == 14) {
                                            this.setState({ avisoTxt: '' })
                                            if (event.target.value.replace(/[^\d]/g, '').length == 11 && !cpf.isValid(event.target.value.replace(/[^\d]/g, ''))) {
                                                this.setState({ avisoTxt: 'CPF inválido' })
                                            }
                                            if (event.target.value.replace(/[^\d]/g, '').length == 14 && !cnpj.isValid(event.target.value.replace(/[^\d]/g, ''))) {
                                                this.setState({ avisoTxt: 'CNPJ inválido' })
                                            }

                                            var pessoa = await this.verificarPessoa(cpf_cnpj)

                                            if (pessoa) {
                                                locadores[index].id = pessoa.id
                                                locadores[index].nome = pessoa.nome
                                                locadores[index].rg_ie = pessoa.rg_ie
                                                locadores[index].email = pessoa.email
                                                locadores[index].telefone = pessoa.telefone
                                                locadores[index].celular = pessoa.celular
                                                locadores[index].cep = pessoa.cep
                                                locadores[index].endereco = pessoa.endereco
                                                locadores[index].bairro = pessoa.bairro
                                                locadores[index].cidade = pessoa.cidade
                                                locadores[index].estado = pessoa.uf
                                                locadores[index].numero = pessoa.numero
                                                locadores[index].complemento = pessoa.complemento
                                                this.setState({ locadores: locadores })
                                            }
                                        }
                                        else {
                                            locadores[index].id = 0
                                            locadores[index].nome = ''
                                            locadores[index].rg_ie = ''
                                            locadores[index].email = ''
                                            locadores[index].telefone = ''
                                            locadores[index].celular = ''
                                            locadores[index].cep = ''
                                            locadores[index].endereco = ''
                                            locadores[index].bairro = ''
                                            locadores[index].cidade = ''
                                            locadores[index].estado = ''
                                            locadores[index].numero = ''
                                            locadores[index].complemento = ''
                                            this.setState({ locadores: locadores })
                                        }
                                    }} />
                            </div>
                            <span>{this.state.avisoTxt}</span>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='grupo__campo' name={`locador${index}_nome`}>
                            <label style={{ width: '100%' }}>Nome: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].nome} onChange={(event) => {
                                    this.preencheu(`locador${index}_nome`)
                                    locadores[index].nome = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo'>
                            <label style={{ width: '100%' }}>RG: </label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].rg_ie} onChange={(event) => {
                                    locadores[index].rg_ie = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='grupo__campo' name={`locador${index}_email`}>
                            <label style={{ width: '100%' }}>Email: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].email} onChange={(event) => {
                                    this.preencheu(`locador${index}_email`)
                                    locadores[index].email = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo'>
                            <label style={{ width: '100%' }}>Telefone: </label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].telefone} onChange={(event) => {
                                    locadores[index].telefone = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo'>
                            <label style={{ width: '100%' }}>Celular: </label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].celular} onChange={(event) => {
                                    locadores[index].celular = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_cep`}>
                            <label style={{ width: '100%' }}>CEP: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].cep} onChange={(event) => {
                                    this.preencheu(`locador${index}_cep`)
                                    if (event.target.value.replace(/\D/g, '').length == 8) {
                                        api_cep.get(`/${event.target.value.replace(/\D/g, '')}/json`).then((dados_cep) => {
                                            locadores[index].cep = dados_cep.data.cep
                                            locadores[index].endereco = dados_cep.data.logradouro
                                            locadores[index].bairro = dados_cep.data.bairro
                                            locadores[index].cidade = dados_cep.data.localidade
                                            locadores[index].estado = dados_cep.data.uf
                                            locadores[index].numero = ''
                                            locadores[index].complemento = ''
                                            this.setState({ locadores: locadores })
                                        })
                                    }
                                    else {
                                        locadores[index].cep = event.target.value
                                        locadores[index].endereco = ''
                                        locadores[index].bairro = ''
                                        locadores[index].cidade = ''
                                        locadores[index].estado = ''
                                        locadores[index].numero = ''
                                        locadores[index].complemento = ''
                                        this.setState({ locadores: locadores })
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='grupo__campo' name={`locador${index}_endereco`}>
                            <label style={{ width: '100%' }}>Endereço: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].endereco} onChange={(event) => {
                                    this.preencheu(`locador${index}_endereco`)
                                    locadores[index].endereco = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_numero`}>
                            <label style={{ width: '100%' }}>Número: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].numero} onChange={(event) => {
                                    this.preencheu(`locador${index}_numero`)
                                    locadores[index].numero = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo'>
                            <label style={{ width: '100%' }}>Complemento: </label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].complemento} onChange={(event) => {
                                    locadores[index].complemento = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_bairro`}>
                            <label style={{ width: '100%' }}>Bairro: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].bairro} onChange={(event) => {
                                    this.preencheu(`locador${index}_bairro`)
                                    locadores[index].bairro = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_cidade`}>
                            <label style={{ width: '100%' }}>Cidade: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].cidade} onChange={(event) => {
                                    this.preencheu(`locador${index}_cidade`)
                                    locadores[index].cidade = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_estado`}>
                            <label style={{ width: '100%' }}>Estado: *</label><br />
                            <div className='grupo__campo__form'>
                                <select value={locadores[index].estado} onChange={(event) => {
                                    this.preencheu(`locador${index}_estado`)
                                    locadores[index].estado = event.target.value
                                    this.setState({ locadores: locadores })
                                }}>
                                    <option>Selecione</option>
                                    <option value='AC'>Acre</option>
                                    <option value='AL'>Alagoas</option>
                                    <option value='AP'>Amapá</option>
                                    <option value='AM'>Amazonas</option>
                                    <option value='BA'>Bahia</option>
                                    <option value='CE'>Ceará</option>
                                    <option value='DF'>Distrito Federal</option>
                                    <option value='ES'>Espírito Santo</option>
                                    <option value='GO'>Goiás</option>
                                    <option value='MA'>Maranhão</option>
                                    <option value='MT'>Mato Grosso</option>
                                    <option value='MS'>Mato Grosso do Sul</option>
                                    <option value='MG'>Minas Gerais</option>
                                    <option value='PA'>Pará</option>
                                    <option value='PB'>Paraíba</option>
                                    <option value='PR'>Paraná</option>
                                    <option value='PE'>Pernambuco</option>
                                    <option value='PI'>Piauí</option>
                                    <option value='RJ'>Rio de Janeiro</option>
                                    <option value='RN'>Rio Grande do Norte</option>
                                    <option value='RS'>Rio Grande do Sul</option>
                                    <option value='RO'>Rondônia</option>
                                    <option value='RR'>Roraima</option>
                                    <option value='SC'>Santa Catarina</option>
                                    <option value='SP'>São Paulo</option>
                                    <option value='SE'>Sergipe</option>
                                    <option value='TO'>Tocantins</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_porcentagem_imovel`}>
                            <label style={{ width: '100%' }}>Porcentagem do imóvel: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='number' value={locadores[index].porcentagem_imovel} min={0} onChange={(event) => {
                                    if (event.target.value > 100.00) {
                                        alert('Porcentagem deve ser menor que 100%')
                                        locadores[index].porcentagem_imovel = 0
                                        this.setState({ locadores: locadores })
                                    }
                                    else if (event.target.value < 0) {
                                        alert('Porcentagem deve ser maior ou igual a 0%')
                                        locadores[index].porcentagem_imovel = 0
                                        this.setState({ locadores: locadores })
                                    }
                                    else {
                                        this.preencheu(`locador${index}_porcentagem_imovel`)
                                        locadores[index].porcentagem_imovel = event.target.value
                                        this.setState({ locadores: locadores })
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <label style={{ marginTop: '30px', fontSize: '1.1em', fontWeight: 'bolder' }}>Dados bancários {index + 1}</label>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_banco`}>
                            <label style={{ width: '100%' }}>Banco: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].banco} onChange={(event) => {
                                    this.preencheu(`locador${index}_banco`)
                                    locadores[index].banco = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_agencia`}>
                            <label style={{ width: '100%' }}>Agência: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].agencia} onChange={(event) => {
                                    this.preencheu(`locador${index}_agencia`)
                                    locadores[index].agencia = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_conta`}>
                            <label style={{ width: '100%' }}>Conta: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].conta} onChange={(event) => {
                                    this.preencheu(`locador${index}_conta`)
                                    locadores[index].conta = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_corrente_poupanca`}>
                            <label style={{ width: '100%' }}>Corrente ou poupança: *</label><br />
                            <div className='grupo__campo__form'>
                                <select value={locadores[index].corrente_poupanca} onChange={(event) => {
                                    this.preencheu(`locador${index}_corrente_poupanca`)
                                    locadores[index].corrente_poupanca = event.target.value
                                    this.setState({ locadores: locadores })
                                }}>
                                    <option value={0}>Corrente</option>
                                    <option value={1}>Poupança</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_beneficiario_nome`}>
                            <label style={{ width: '100%' }}>Beneficiário Nome: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].beneficiario_nome} onChange={(event) => {
                                    this.preencheu(`locador${index}_beneficiario_nome`)
                                    locadores[index].beneficiario_nome = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='grupo__campo' name={`locador${index}_beneficiario_cpf_cnpj`}>
                            <label style={{ width: '100%' }}>Benediciário CPF/CNPJ: *</label><br />
                            <div className='grupo__campo__form'>
                                <input type='text' value={locadores[index].beneficiario_cpf_cnpj} onChange={(event) => {
                                    this.preencheu(`locador${index}_beneficiario_cpf_cnpj`)
                                    locadores[index].beneficiario_cpf_cnpj = event.target.value
                                    this.setState({ locadores: locadores })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ backgroundColor: '#A704BF', marginTop: '30px' }} />
            </>
        )
    }

    preencher = (name) => {
        if (
            !document.getElementsByName(name)[0].classList.contains("form--error")
        ) {
            document.getElementsByName(name)[0].classList.add("form--error");
        }
    };

    preencheu = (name) => {
        if (document.getElementsByName(name)[0].classList.contains('form--error')) {
            document.getElementsByName(name)[0].classList.remove('form--error')
        }
    };

    alugueis = async (contrato_id, locadores) => {
        var servico = this.state.servicos.filter((s) => {
            return s.nome == 'Aluguel'
        })[0].id
        var referencia

        var mes = moment().format('MM')
        var ano = moment().format('YYYY')

        var data_vencimento = moment(`${ano}-${mes}-${this.state.dia_vencimento_aluguel.toString().padStart(2, '0')}`).utc(false).format('YYYY-MM-DD')
        if (moment() > moment(data_vencimento)) {
            data_vencimento = moment(data_vencimento).add(1, 'months').utc(false).format('YYYY-MM-DD')
        }

        // Mês aberto
        let aux_data_vencimento = moment(data_vencimento).subtract(1, 'months').utc(false).format('YYYY-MM-DD')
        if (Number(this.state.aberto_fechado) == 0) {
            aux_data_vencimento = moment(data_vencimento).subtract(1, 'months').utc(false).format('YYYY-MM-DD')
        }
        let aux_aberto_fechado = false;

        while (moment(data_vencimento) <= moment(this.state.data_fim)) {
            if (moment(data_vencimento).utc(false).format('DD') !== this.state.dia_vencimento_aluguel.toString().padStart(2, '0') && moment(data_vencimento).utc(false).format('MM') !== '02') {
                mes = moment(data_vencimento).format('MM')
                ano = moment(data_vencimento).format('YYYY')
                data_vencimento = moment(`${ano}-${mes}-${this.state.dia_vencimento_aluguel.toString().padStart(2, '0')}`).utc(false).format('YYYY-MM-DD')
            }
            if (this.state.aberto_fechado == 1) {
                referencia = `Aluguel referente ${moment(data_vencimento).subtract(1, 'months').utc(false).startOf('month').format('DD/MM/YYYY')} a ${moment(data_vencimento).subtract(1, 'months').utc(false).endOf('month').format('DD/MM/YYYY')}`
            } else {
                // Mês aberto
                if (moment(data_vencimento).add(1, 'months').utc(false).format('DD') !== this.state.dia_vencimento_aluguel.toString().padStart(2, '0') && moment(data_vencimento).add(1, 'months').utc(false).format('MM') == '03') {
                    var mes_correcao = moment(data_vencimento).add(1, 'months').format('MM')
                    var ano_correcao = moment(data_vencimento).add(1, 'months').format('YYYY')
                    referencia = `Aluguel referente ${moment(data_vencimento).utc(false).format('DD/MM/YYYY')} a ${moment(`${ano_correcao}-${mes_correcao}-${this.state.dia_vencimento_aluguel.toString().padStart(2, '0')}`).subtract(1, 'days').utc(false).format('DD/MM/YYYY')}`
                } else {
                    if (!aux_aberto_fechado) {
                        aux_aberto_fechado = true;
                        referencia = `Aluguel referente ${moment(aux_data_vencimento).utc(false).format('DD/MM/YYYY')} a ${moment(aux_data_vencimento).add(1, 'months').subtract(1, 'days').utc(false).format('DD/MM/YYYY')}`
                    } else {
                        referencia = `Aluguel referente ${moment(data_vencimento).subtract(1, 'months').utc(false).format('DD/MM/YYYY')} a ${moment(data_vencimento).subtract(1, 'days').utc(false).format('DD/MM/YYYY')}`
                    }
                }
            }

            const respParcelas = await api_freedom.post('/financeiro/create', {
                contrato_id: contrato_id,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                servico_id: servico,
                data_vencimento: data_vencimento,
                valor: this.state.valor_aluguel,
                referencia: referencia,
                mes_ano: moment(data_vencimento).utc(false).format('YYYY-MM')
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (this.state.reter_ir) {
                locadores.forEach(async (locador) => {
                    const resultadoIR = calcularImpostoLocador(locador.porcentagem_imovel, +this.state.valor_aluguel, 0, locador.nome + "; CPF: " + locador.cpf_cnpj);
                    locador.resultadoIR = resultadoIR.resultadoIR;
                    locador.aliquotaIR = resultadoIR.aliquotaIR;
                    locador.valorPagarIR = resultadoIR.valorPagarIR;
                    locador.valorAluguelIR = resultadoIR.valorAluguelIR;

                    let financeiro;
                    if (parseFloat(String(locador.valorPagarIR).replace(',', '.')) > 0) {
                        financeiro = await api_freedom.post('/financeiro/create', {
                            contrato_id: contrato_id,
                            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                            servico_id: 6,
                            data_vencimento: data_vencimento,
                            valor: parseFloat(String(locador.valorPagarIR).replace(',', '.')),
                            mes_ano: moment(data_vencimento).utc(false).format('YYYY-MM'),
                            referencia: locador.resultadoIR,
                            pessoa_id: locador.id,
                        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                    }
                });
            }

            console.log("Parcelas", {
                contrato_id: contrato_id,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                servico_id: servico,
                data_vencimento: data_vencimento,
                valor: this.state.valor_aluguel,
                referencia: referencia,
                mes_ano: moment(data_vencimento).utc(false).format('YYYY-MM')
            }, respParcelas);
            data_vencimento = moment(data_vencimento).add(1, 'months').utc(false).format('YYYY-MM-DD')
        }

    }

    cadastrar = async () => {
        var cadastrar = 1
        var percentagem = 0

        this.state.locadores.map((l, i) => {
            var locadores = [
                [!l.cpf_cnpj, `locador${i}_cpf_cnpj`, 0],
                [!l.nome, `locador${i}_nome`, 0],
                [!l.email, `locador${i}_email`, 0],
                [!l.cep, `locador${i}_cep`, 0],
                [!l.endereco, `locador${i}_endereco`, 0],
                [!l.numero, `locador${i}_numero`, 0],
                [!l.bairro, `locador${i}_bairro`, 0],
                [!l.cidade, `locador${i}_cidade`, 0],
                [!l.estado, `locador${i}_estado`, 0],
                [!l.porcentagem_imovel || l.porcentagem_imovel == 0, `locador${i}_porcentagem_imovel`, 0],
                [!l.banco || l.banco == 0, `locador${i}_banco`, 0],
                [!l.agencia || l.agencia == 0, `locador${i}_agencia`, 0],
                [!l.conta || l.conta == 0, `locador${i}_conta`, 0],
                [!l.beneficiario_nome, `locador${i}_beneficiario_nome`, 0],
                [!l.beneficiario_cpf_cnpj, `locador${i}_beneficiario_cpf_cnpj`, 0]
            ]

            cadastrar = this.validarInputs(locadores, 1)

            percentagem += parseFloat(l.porcentagem_imovel)

            return null
        })

        if (this.state.locatario_cpf_cnpj && this.state.locatario_cpf_cnpj.length == 14 && !cpf.isValid(this.state.locatario_cpf_cnpj)) {
            cadastrar = 3
            this.preencher(`locatario_cpf_cnpj`)
        }

        if (this.state.locatario_cpf_cnpj && this.state.locatario_cpf_cnpj.length == 18 && !cnpj.isValid(this.state.locatario_cpf_cnpj)) {
            cadastrar = 3
            this.preencher(`locatario_cpf_cnpj`)
        }

        if (this.state.locatario_cpf_cnpj && this.state.locatario_cpf_cnpj.length !== 14 && this.state.locatario_cpf_cnpj.length !== 18) {
            cadastrar = 3
            this.preencher(`locatario_cpf_cnpj`)
        }

        this.state.locadores.map((l, i) => {

            if (l.cpf_cnpj && l.cpf_cnpj.length == 14 && !cpf.isValid(l.cpf_cnpj)) {
                cadastrar = 3
                this.preencher(`locador${i}_cpf_cnpj`)
                console.log('este 1')
            }
            if (l.cpf_cnpj && l.cpf_cnpj.length == 18 && !cnpj.isValid(l.cpf_cnpj)) {
                cadastrar = 3
                this.preencher(`locador${i}_cpf_cnpj`)
                console.log('este 2')
            }
            if (l.cpf_cnpj && l.cpf_cnpj.length !== 14 && l.cpf_cnpj.length !== 18) {
                cadastrar = 3
                this.preencher(`locador${i}_cpf_cnpj`)
                console.log('este 3')
            }

            return null
        })

        if (percentagem !== 100.00) {
            cadastrar = 4
        }

        if (cadastrar == 0) {
            alert(`Alguns campos obrigatórios não foram informados, para prosseguir preencha.`)
        }

        if (cadastrar == 2) {
            alert(`Verifique o valor de aluguel, talvez ele não tenha sido informado ou seu valor é de R$ 0,00.`)
        }

        if (cadastrar == 3) {
            alert(`CPF ou CNPJ inválido`)
        }

        if (cadastrar == 4) {
            alert(`A soma das porcentagens de cada locador referente ao imóvel não é igual a 100%`)
        }

        if (cadastrar == 1) {

            this.setState({
                modal_titulo: `Cadastrando o contrato`,
                modal_corpo: <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                    AGUARDE....
                </div>,
                modal_botoes: <></>
            })
            this.abrirModal()
            var locadores = this.state.locadores

            try {

                let imovel = await api_freedom.post('/imovel/create', {
                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                    endereco: this.state.imovel_endereco,
                    numero: this.state.imovel_numero,
                    complemento: this.state.imovel_complemento,
                    bairro: this.state.imovel_bairro,
                    cidade: this.state.imovel_cidade,
                    estado: this.state.imovel_estado,
                    cep: this.state.imovel_cep,
                    metragem: this.state.imovel_metragem,
                    agua: this.state.agua,
                    luz: this.state.luz,
                    condominio: this.state.condominio,
                    iptu: this.state.iptu
                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                if (this.state.locatario_id !== 0) {
                    await api_freedom.post('/pessoa/update', {
                        nome: this.state.locatario_nome,
                        cpf_cnpj: this.state.locatario_cpf_cnpj,
                        rg_ie: this.state.locatario_rg_ie,
                        email: this.state.locatario_email,
                        telefone: this.state.locatario_telefone,
                        celular: this.state.locatario_celular,
                        endereco: this.state.locatario_endereco,
                        numero: this.state.locatario_numero,
                        complemento: this.state.locatario_complemento,
                        bairro: this.state.locatario_bairro,
                        cidade: this.state.locatario_cidade,
                        uf: this.state.locatario_estado,
                        cep: this.state.locatario_cep,
                        id: this.state.locatario_id
                    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                }
                else {
                    let locatario = await api_freedom.post('/pessoa/create', {
                        imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                        nome: this.state.locatario_nome,
                        cpf_cnpj: this.state.locatario_cpf_cnpj,
                        rg_ie: this.state.locatario_rg_ie,
                        email: this.state.locatario_email,
                        telefone: this.state.locatario_telefone,
                        celular: this.state.locatario_celular,
                        endereco: this.state.locatario_endereco,
                        numero: this.state.locatario_numero,
                        complemento: this.state.locatario_complemento,
                        bairro: this.state.locatario_bairro,
                        cidade: this.state.locatario_cidade,
                        uf: this.state.locatario_estado,
                        cep: this.state.locatario_cep
                    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                    var locatario_id = locatario.data.data.insertId
                    this.setState({
                        locatario_id: locatario.data.data.insertId
                    })
                }

                this.state.locadores.map(async (l, i) => {
                    if (l.id !== 0) {
                        await api_freedom.post('/pessoa/update', {
                            nome: l.nome,
                            cpf_cnpj: l.cpf_cnpj,
                            rg_ie: l.rg_ie,
                            email: l.email,
                            telefone: l.telefone,
                            celular: l.celular,
                            endereco: l.endereco,
                            numero: l.numero,
                            complemento: l.complemento,
                            bairro: l.bairro,
                            cidade: l.cidade,
                            uf: l.estado,
                            cep: l.cep,
                            id: l.id
                        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                    }
                    else {
                        let locador = await api_freedom.post('/pessoa/create', {
                            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                            nome: l.nome,
                            cpf_cnpj: l.cpf_cnpj,
                            rg_ie: l.rg_ie,
                            email: l.email,
                            telefone: l.telefone,
                            celular: l.celular,
                            endereco: l.endereco,
                            numero: l.numero,
                            complemento: l.complemento,
                            bairro: l.bairro,
                            cidade: l.cidade,
                            uf: l.estado,
                            cep: l.cep
                        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                        console.log(locador.data.data.insertId)
                        locadores[i].id = locador.data.data.insertId
                    }

                    return null
                })

                console.log(parseInt(this.state.contem_garantia) == 1 && parseInt(this.state.tipo_garantia) == 2 ? this.state.fiador_id : null);

                let contrato = await api_freedom.post('/contrato/create', {
                    id_contrato: this.state.contrato_id,
                    imovel_id: imovel.data.data.insertId,
                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                    data_inicio: this.state.data_inicio,
                    data_fim: this.state.data_fim,
                    data_rescisao: this.state.data_rescisao,
                    tipo_locacao: this.state.tipo_locacao,
                    valor_aluguel: this.state.valor_aluguel,
                    is_taxa_fixa: this.state.is_taxa_fixa,
                    juros_por_dia: this.state.juros_por_dia,
                    multa: this.state.multa,
                    dia_transferencia: this.state.dia_transferencia,
                    somar_dia_transferencia: this.state.somar_dia_transferencia,
                    is_repasse_garantido: this.state.is_repasse_garantido,
                    dia_repasse_garantido: this.state.is_repasse_garantido ? parseInt(this.state.dia_repasse_garantido) : null,
                    dia_vencimento_aluguel: this.state.dia_vencimento_aluguel,
                    perc_taxa_administracao: this.state.is_taxa_fixa ? this.state.taxa_administracao_fixa : this.state.perc_taxa_administracao,
                    aberto_fechado: this.state.aberto_fechado,

                    // fiador: this.state.fiador,
                    // seguro: this.state.seguro,
                    // titulo: this.state.titulo,
                    tipo_garantia: this.state.contem_garantia == 1 ? this.state.tipo_garantia : 1,
                    contem_garantia: this.state.contem_garantia,
                    responsabilidade: this.state.contem_garantia == 1 && this.state.tipo_garantia == 4 ? this.state.responsabilidade : null,
                    valor_calcao: this.state.contem_garantia == 1 && this.state.tipo_garantia == 4 ? this.state.valor_calcao : null,
                    fiador: parseInt(this.state.contem_garantia) == 1 && parseInt(this.state.tipo_garantia) == 2 ? JSON.stringify(this.state.fiadores) : null,

                    seguro: parseInt(this.state.contem_garantia) == 1 && parseInt(this.state.tipo_garantia) == 1 ? this.state.seguro : null,

                    titulo: parseInt(this.state.contem_garantia) == 1 && parseInt(this.state.tipo_garantia) == 3 ? this.state.titulo : null,

                    vigencia_inicial_seguro: parseInt(this.state.contem_garantia) == 1 && (parseInt(this.state.tipo_garantia) == 1 || parseInt(this.state.tipo_garantia) == 3) ? moment(this.state.vigencia_inicial_seguro).format('YYYY-MM-DD') : null,

                    vigencia_final_seguro: parseInt(this.state.contem_garantia) == 1 && (parseInt(this.state.tipo_garantia) == 1 || parseInt(this.state.tipo_garantia) == 3) ? moment(this.state.vigencia_final_seguro).format('YYYY-MM-DD') : null,

                    valor_garantia: this.state.valor_garantia ? parseFloat(this.state.valor_garantia) : null,

                    instituicao_financeira: this.state.instituicao_financeira,

                    apolice: this.state.apolice,
                    nome_seguradora: this.state.seguradora,
                    vigencia: this.state.vigencia,
                    reter_ir: this.state.reter_ir,
                    reter_iss: this.state.reter_iss,
                    emitir_nfe: this.state.emitir_nfe,
                    obs_gerais: this.state.obs,
                    ativo_inativo: this.state.ativo_inativo,
                    cobrar_ted: this.state.cobrar_ted,
                    mes_indice_reajuste: this.state.mes_indice_reajuste,
                    indice: this.state.indice != '' ? this.state.indice : undefined,

                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                locadores.map(async (l, i) => {
                    await api_freedom.post('/pessoahasimovel/create', {
                        pessoa_id: l.id,
                        imovel_id: imovel.data.data.insertId,
                        percentagem: l.porcentagem_imovel
                    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                    await api_freedom.post('/contratohaspessoa/create', {
                        contrato_id: contrato.data.data.insertId,
                        imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                        pessoa_id: l.id,
                        tipo: 'Locador',
                        percentagem: l.porcentagem_imovel,
                        banco: l.banco,
                        agencia: l.agencia,
                        conta: l.conta,
                        corrente_poupanca: l.corrente_poupanca,
                        beneficiario_nome: l.beneficiario_nome,
                        beneficiario_cpf_cnpj: l.beneficiario_cpf_cnpj
                    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                })

                await api_freedom.post('/contratohaspessoa/create', {
                    contrato_id: contrato.data.data.insertId,
                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                    pessoa_id: this.state.locatario_id == 0 ? locatario_id : this.state.locatario_id,
                    tipo: 'Locatário',
                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                this.alugueis(contrato.data.data.insertId, locadores)

                this.setState({
                    modal_titulo: `Alterando o contrato`,
                    modal_corpo: <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                        Contrato criado com sucesso!
                    </div>,
                    modal_botoes: <></>
                })

                setTimeout(
                    () => { this.fecharModal(); this.props.history.push(`/freedom/contratos`) }
                    , 500);
            }
            catch (error) {
                this.fecharModal()
                alert('Ocorreu um erro')
                console.log(error.message)
            }

        }

    }

    slide = (arrow) => {
        var pags = document.getElementsByClassName('pag');
        var index = document.getElementsByClassName('wizard__item');

        var open;

        for (var i = 0; i < pags.length; i++) {

            if (pags[i].style.display == 'block') {
                open = i;
            }

            pags[i].style.display = 'none'

        }

        index[open].classList.remove('wizard__item--press')

        for (var i = 0; i < index.length; i++) {
            index[i].classList.remove('wizard__item--press')
        }

        var x = open + arrow

        for (var i = 0; i < x; i++) {
            index[i].classList.add('wizard__item--press')
        }

        pags[x].style.display = 'block';
        index[x].classList.add('wizard__item--press')
    }

    verificarEtapa1 = () => {
        const mes = moment().format('MM')
        const ano = moment().format('YYYY')
        const diaVencimento = this.state.dia_vencimento_aluguel.toString().padStart(2, '0');

        let data_vencimento = moment(`${ano}-${mes}-${diaVencimento}`).utc(false).format('YYYY-MM-DD');

        if (moment() > moment(data_vencimento)) {
            data_vencimento = moment(data_vencimento).add(1, 'months').utc(false).format('YYYY-MM-DD')
        }

        if (!moment(data_vencimento, 'YYYY-MM-DD', true).isValid()) {
            alert('Data de vencimento inválida: ' + data_vencimento);
            return;
        }

        const campoAValidar = this.state.is_taxa_fixa ? 'taxa_administracao_fixa' : 'perc_taxa_administracao';
        const refCampo = this.state.is_taxa_fixa ? 'taxa_administracao_fixa' : 'taxa_administracao';

        var inputs = [
            [!this.state.contrato_e, 'id_contrato', 0],
            [!this.state.valor_aluguel || this.state.valor_aluguel == 0, 'valor_aluguel', 2],
            [!this.state.tipo_locacao, 'tipo_locacao', 0],
            [
                !this.state.multa || this.state.valor_aluguel == 0,
                "multa",
                2,
            ],
            [
                !this.state.juros_por_dia || this.state.juros_por_dia == 0,
                "juros_por_dia",
                2,
            ],
            // [!this.state.perc_taxa_administracao, 'taxa_administracao', 0],
            [!(this.state[campoAValidar]), refCampo, 0], // Use a variável campoAValidar aqui
            [!this.state.dia_vencimento_aluguel, 'dia_vencimento', 0],
            [!this.state.instituicao_financeira, 'instituicao_financeira', ''],
            // [this.state.aberto_fechado !== 0 && this.state.aberto_fechado !== 1, 'mes', 0]
        ]

        this.validarInputs(inputs);
    }

    verificarEtapa2 = () => {

        var inputs = [
            [!this.state.imovel_cep, 'imovel_cep', 0],
            [!this.state.imovel_endereco, 'imovel_endereco', 0],
            [!this.state.imovel_numero, 'imovel_numero', 0],
            [!this.state.imovel_bairro, 'imovel_bairro', 0],
            [!this.state.imovel_cidade, 'imovel_cidade', 0],
            [!this.state.imovel_estado, 'imovel_estado', 0]
        ]

        this.validarInputs(inputs);
    }

    verificarEtapa3 = () => {

        var inputs = [
            [!this.state.locatario_cpf_cnpj, 'locatario_cpf_cnpj', 0],
            [!this.state.locatario_nome, 'locatario_nome', 0],
            [!this.state.locatario_email, 'locatario_email', 0],
            [!this.state.locatario_cep, 'locatario_cep', 0],
            [!this.state.locatario_endereco, 'locatario_endereco', 0],
            [!this.state.locatario_numero, 'locatario_numero', 0],
            [!this.state.locatario_bairro, 'locatario_bairro', 0],
            [!this.state.locatario_cidade, 'locatario_cidade', 0],
            [!this.state.locatario_estado, 'locatario_estado', 0]
        ]

        this.validarInputs(inputs);

    }

    verificaGarantias = () => {

        if (this.state.tipo_garantia == '-' && this.state.contem_garantia == 1) {
            this.preencher("tipo_garantia")
        }

        if (
            (this.state.contem_garantia == 2) ||
            (this.state.contem_garantia == 1 && this.state.tipo_garantia == 1 && (this.state.seguro != '' && this.state.vigencia_inicial_seguro && this.state.vigencia_final_seguro && this.state.valor_garantia != '')) ||
            (this.state.contem_garantia == 1 && this.state.tipo_garantia == 3 && (this.state.titulo != '' && this.state.vigencia_inicial_seguro && this.state.vigencia_final_seguro && this.state.valor_garantia != '')) ||
            (this.state.contem_garantia == 1 && this.state.tipo_garantia == 4 && (this.state.calcao != '' && this.state.responsabilidade != '')) ||
            (this.state.contem_garantia == 1 && this.state.tipo_garantia == 2 && this.state.fiadores && this.state.fiadores.every(fiador => fiador.fiador != '' && fiador.cpf_fiador != '' && fiador.fiador_id != ''))
        ) {
            this.slide(1);
        } else {
            if (this.state.contem_garantia == 1) {
                if (parseInt(this.state.tipo_garantia) == 1) {
                    if (!this.state.seguro) this.preencher("garantia_seguro");
                    if (!this.state.valor_garantia) this.preencher("valor_garantia");
                    if (!this.state.vigencia_inicial_seguro) this.preencher("vigencia_inicial_seguro");
                    if (!this.state.vigencia_final_seguro) this.preencher("vigencia_final_seguro");
                }
                else if (parseInt(this.state.tipo_garantia) == 2) {
                    this.state.fiadores.forEach((fiador, index) => {
                        if (!fiador.cpf_fiador) this.preencher(`cpf_fiador_${index}`);
                        if (!fiador.fiador) this.preencher(`nome_fiador_${index}`);
                    })
                } else if (parseInt(this.state.tipo_garantia) == 3) {
                    if (!this.state.titulo) this.preencher("titulo_seguro");
                    if (!this.state.valor_garantia) this.preencher("valor_garantia");
                    if (!this.state.vigencia_inicial_seguro) this.preencher("vigencia_inicial_seguro");
                    if (!this.state.vigencia_final_seguro) this.preencher("vigencia_final_seguro");
                } else if (this.state.tipo_garantia == 4) {
                    if (!this.state.responsabilidade) this.preencher("responsabilidade");
                    if (!this.state.valor_calcao) this.preencher("valor_calcao");
                }
            }
        }
    };

    verificaIncendio = () => {
        this.validarInputs([
            [!this.state.seguradora, 'seguradora', 0],
            [!this.state.apolice, 'apolice', 0],
            [!this.state.vigencia, 'vigencia', 0],
        ]);
    }

    validarInputs = (inputs, ultimo) => {
        var cadastrar = 1, completo = inputs.length;

        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i][0]) {
                cadastrar = inputs[i][2]
                this.preencher(inputs[i][1])
            } else {
                completo--;
            }
        }

        if (completo == 0 && !ultimo) {
            this.slide(1)
        }

        return cadastrar;
    }

    verificarContrato = (id) => {
        let contrato = this.state.contrato.filter(a => a.id == id);

        if (contrato.length > 0) {
            this.setState({ aviso: "ID de contrato existente!", contrato_e: false })
        } else {
            this.setState({ aviso: "", contrato_id: id, contrato_e: true })
        }
    }

    disable() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo == "Read") {
            return true
        } else {
            return false
        }
    }

    render() {
        return (
            <>
                <div>
                    <ul className="wizard pointer">
                        <li className="wizard__item wizard__item--press">
                            <span className="wizard__index">1</span>
                            <p className="wizard__p">Contrato</p>
                        </li>
                        <li className="wizard__item">
                            <span className="wizard__index">2</span>
                            <p className="wizard__p">Garantias</p>
                        </li>
                        <li className="wizard__item">
                            <span className="wizard__index">3</span>
                            <p className="wizard__p">Incêndio</p>
                        </li>
                        <li className="wizard__item">
                            <span className="wizard__index">4</span>
                            <p className="wizard__p">Imóvel</p>
                        </li>
                        <li className="wizard__item">
                            <span className="wizard__index">5</span>
                            <p className="wizard__p">Locatário</p>
                        </li>
                        <li className="wizard__item">
                            <span className="wizard__index">6</span>
                            <p className="wizard__p">Locador</p>
                        </li>
                    </ul>

                    {/* <label style={{fontSize: '1.4em', fontWeight: 'bolder'}}>Contrato</label><hr/> */}

                    <div className="pag" style={{ display: 'block' }}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`id_contrato`}>
                                    <label style={{ width: '100%' }}>ID contrato: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.contrato_id} onChange={e => {
                                            this.setState({ contrato_id: e.target.value, aviso: "" });
                                            this.preencheu(`id_contrato`)
                                        }} onBlur={e => this.verificarContrato(this.state.contrato_id)} />
                                    </div>
                                    <p className="my-3 px-4 text-danger">{this.state.aviso}</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`inicio`}>
                                    <label style={{ width: '100%' }}>Início: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='date' value={this.state.data_inicio} min={moment().format('YYYY-MM-DD')} onChange={(event) => {
                                            this.preencheu(`inicio`)
                                            this.setState({ data_inicio: event.target.value })
                                            if (moment(event.target.value) > moment(this.state.data_fim)) {
                                                this.setState({ data_fim: event.target.value })
                                            }
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`termino`}>
                                    <label style={{ width: '100%' }}>Término: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='date' value={this.state.data_fim} min={moment(this.state.data_inicio).format('YYYY-MM-DD')} onChange={(event) => {
                                            this.preencheu(`termino`)
                                            this.setState({ data_fim: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>

                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`valor_aluguel`}>
                                    <label style={{ width: '100%' }}>Valor de aluguel: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='number' value={this.state.valor_aluguel} onChange={(event) => {
                                            this.preencheu(`valor_aluguel`)
                                            this.setState({ valor_aluguel: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`tipo_locacao`}>
                                    <label style={{ width: '100%' }}>Tipo de locação: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.tipo_locacao} onChange={(event) => {
                                            this.preencheu(`tipo_locacao`)
                                            this.setState({ tipo_locacao: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">

                                <div className='grupo__campo' name={`multa`}>
                                    <>
                                        <label style={{ width: '100%' }}>% Multa: *</label><br />
                                        <div className='grupo__campo__form'>
                                            <select value={this.state.multa} onChange={(event) => {
                                                this.preencheu(`multa`)
                                                this.setState({ multa: event.target.value })
                                            }}>
                                                {this.state.porcentagem.map((p, i) => {
                                                    return <option key={i} value={i}>{i}%</option>
                                                })}
                                            </select>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="grupo__campo" name={`is_taxa_fixa`}>
                                    <br />
                                    <div class="d-flex">
                                        <label class="mt-2 checkbox-label">
                                            <input type="checkbox" name="taxa_fixa" checked={this.state.is_taxa_fixa} onClick={e => this.setState({ is_taxa_fixa: e.target.checked })} />
                                            <span class="checkbox-custom rectangular"></span>
                                        </label>
                                        <label className="ml-0 mt-2"><strong>É taxa fixa?</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`taxa_administracao_fixa`}>
                                    {this.state.is_taxa_fixa && (
                                        <>
                                            <label style={{ width: '100%' }}>Taxa fixa:</label><br />
                                            <div className='grupo__campo__form'>
                                                <input type='number' value={this.state.taxa_administracao_fixa} onChange={(event) => {
                                                    this.preencheu(`taxa_administracao_fixa`)
                                                    this.setState({ taxa_administracao_fixa: event.target.value })
                                                }} />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='grupo__campo' name={`taxa_administracao`}>
                                    {!this.state.is_taxa_fixa && (
                                        <>
                                            <label style={{ width: '100%' }}>% Taxa de administração: *</label><br />
                                            <div className='grupo__campo__form'>
                                                <select value={this.state.perc_taxa_administracao} onChange={(event) => {
                                                    this.preencheu(`taxa_administracao`)
                                                    this.setState({ perc_taxa_administracao: event.target.value })
                                                }}>
                                                    {this.state.porcentagem.map((p, i) => {
                                                        return <option key={i} value={i}>{i}%</option>
                                                    })}
                                                </select>
                                            </div>
                                        </>
                                    )}
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="grupo__campo" name={`juros_por_dia`}>
                                    <label style={{ width: "100%" }}>Juros por dia: </label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <input
                                            type="number"
                                            min='0'
                                            step={0.01}
                                            value={this.state.juros_por_dia}
                                            onChange={(event) => {
                                                this.preencheu(`juros_por_dia`)
                                                this.setState({ juros_por_dia: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="grupo__campo" name={`dia_repasse_garantido`}>
                                    <br />
                                    <div class="d-flex">
                                        <label class="mt-2 checkbox-label">                                            <input type="checkbox" name="ret_iss" checked={this.state.is_repasse_garantido} onClick={e => this.setState({ is_repasse_garantido: e.target.checked })} />
                                            <span class="checkbox-custom rectangular"></span>
                                        </label>
                                        <label className="ml-0 mt-2"><strong>Repasse Garantido</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`dia_repasse`}>
                                    <label style={{ width: '100%' }}>Dia para repasse garantido: </label><br />
                                    <div className='grupo__campo__form'>
                                        <select
                                            disabled={!this.state.is_repasse_garantido}
                                            value={this.state.dia_repasse_garantido} onChange={(event) => {
                                                this.preencheu(`dia_repasse`)
                                                this.setState({ dia_repasse_garantido: event.target.value })
                                            }}>
                                            {this.state.dias.map((d, i) => {
                                                return <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`dia_transferencia`}>
                                    <label style={{ width: '100%' }}>Dia para transferência: </label><br />
                                    <div className='grupo__campo__form'>
                                        <select
                                            value={this.state.dia_transferencia} onChange={(event) => {
                                                this.setState({ dia_transferencia: event.target.value })
                                            }}>
                                            {this.state.dias.map((d, i) => {
                                                return <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`somar_dia_transferencia`}>
                                    <>
                                        <label style={{ width: '100%' }}>Somar dias a transferência: </label><br />
                                        <div className='grupo__campo__form'>
                                            <input type='number' min={0} max={10} value={this.state.somar_dia_transferencia} onChange={(event) => {
                                                this.setState({ somar_dia_transferencia: event.target.value })
                                            }} />
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>

                        <div className='row'>

                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`dia_vencimento`}>
                                    <label style={{ width: '100%' }}>Dia de vencimento do aluguel: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <select value={this.state.dia_vencimento_aluguel} onChange={(event) => {
                                            this.preencheu(`dia_vencimento`)
                                            this.setState({ dia_vencimento_aluguel: event.target.value })
                                        }}>
                                            {this.state.dias.map((d, i) => {
                                                return <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`mes`}>
                                    <label style={{ width: '100%' }}>Mês: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <select value={this.state.aberto_fechado} onChange={(event) => {
                                            this.preencheu(`mes`)
                                            this.setState({ aberto_fechado: event.target.value })
                                            console.log(this.state.aberto_fechado);
                                        }}>
                                            <option value={0}>Aberto</option>
                                            <option value={1}>Fechado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="grupo__campo">
                                    <label style={{ width: "100%" }}>Ativo / Inativo: </label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <select
                                            value={this.state.ativo_inativo}
                                            onChange={(event) => {
                                                this.setState({ ativo_inativo: event.target.value });
                                            }}
                                        >
                                            <option value={0}>Inativo</option>
                                            <option value={1}>Ativo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row'>

                            <div className="col-md-4">
                                <div className="grupo__campo">
                                    <label style={{ width: "100%" }}>Cobrar Ted: </label>
                                    <br />
                                    <div className="grupo__campo__form" name={`ted`}>
                                        <select
                                            value={this.state.cobrar_ted}
                                            onChange={(event) => {
                                                this.setState({ cobrar_ted: event.target.value });
                                            }}
                                        >
                                            <option value={1}>Sim</option>
                                            <option value={0}>Não</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="grupo__campo" name={`mes_reajuste`}>
                                    <label style={{ width: "100%" }}>Mês Reajuste: *</label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <select
                                            value={this.state.mes_indice_reajuste}
                                            onChange={(event) => {
                                                this.preencheu(`mes_reajuste`);
                                                this.setState({
                                                    mes_indice_reajuste: event.target.value,
                                                });
                                            }}
                                        >
                                            <option value={0}>Não Selecionado</option>
                                            <option value={1}>Janeiro</option>
                                            <option value={2}>Fevereiro</option>
                                            <option value={3}>Março</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Maio</option>
                                            <option value={6}>Junho</option>
                                            <option value={7}>Julho</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Setembro</option>
                                            <option value={10}>Outubro</option>
                                            <option value={11}>Novembro</option>
                                            <option value={12}>Dezembro</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="grupo__campo" name={'rescisao'}>
                                    <label style={{ width: '100%' }}>Data Rescisão: </label>
                                    <div className="grupo__campo__form">
                                        <input type="date" value={this.state.data_rescisao} onChange={(event) => {
                                            this.setState({ data_rescisao: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <div className='grupo__campo' name={`taxa_administracao`}>
                            <label style={{ width: '100%' }}>% Taxa de administração: *</label><br />
                            <div className='grupo__campo__form'>
                                <select value={this.state.perc_taxa_administracao} onChange={(event) => {
                                    this.preencheu(`taxa_administracao`)
                                    this.setState({ perc_taxa_administracao: event.target.value })
                                }}>
                                    {this.state.porcentagem.map((p, i) => {
                                        return <option key={i} value={i}>{i}%</option>
                                    })}
                                </select>
                            </div>
                        </div> */}

                        <div className='row'>
                            <div className="col-md-4">
                                <div className="grupo__campo" name={`instituicao_financeira`}>
                                    <label style={{ width: "100%" }}>Instituição Financeira:</label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <select
                                            value={this.state.instituicao_financeira}
                                            onChange={(event) => {
                                                this.preencheu(`instituicao_financeira`)
                                                this.setState({ instituicao_financeira: event.target.value });
                                            }}
                                        >
                                            <option value={''}>Selecione</option>
                                            <option value={'fitbank'}>Fitbank</option>
                                            <option value={'sicoob'}>Sicoob</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="grupo__campo" name={`Índice`}>
                                    <label style={{ width: "100%" }}>Índice:</label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <select
                                            value={this.state.indice}
                                            onChange={(event) => {
                                                this.preencheu(`Índice`)
                                                this.setState({ indice: event.target.value });
                                            }}
                                        >
                                            <option value={''}>Selecione</option>
                                            {
                                                this.state.indices.map((indice, i) => {
                                                    return <option key={indice.id} value={indice.nome}>{indice.nome}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='grupo__campo' name={`obs`}>
                                    <label style={{ width: '100%' }}>Observações Gerais</label><br />
                                    <div className='grupo__campo__form'>
                                        <textarea type='text' value={this.state.obs} onChange={(event) => {
                                            this.preencheu(`obs`)
                                            this.setState({ obs: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-4 pb-4">
                            <div className='col-md-4'>
                                <div class="col-md-12 d-flex">
                                    <label class="mb-0 checkbox-label">
                                        <input type="checkbox" name="ret_ir" checked={this.state.reter_ir} onClick={e => this.setState({ reter_ir: e.target.checked })} />
                                        <span class="checkbox-custom rectangular"></span>
                                    </label>
                                    <label className="ml-3"><strong>Reter IR</strong></label>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div class="col-md-12 d-flex">
                                    <label class="mb-0 checkbox-label">
                                        <input type="checkbox" name="emitir_nfe" checked={this.state.emitir_nfe} onClick={e => this.setState({ emitir_nfe: e.target.checked })} />
                                        <span class="checkbox-custom rectangular"></span>
                                    </label>
                                    <label className="ml-3"><strong>Emitir nota fiscal</strong></label>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div class="col-md-12 d-flex">
                                    <label class="mb-0 checkbox-label">
                                        <input type="checkbox" name="ret_iss" checked={this.state.reter_iss} onClick={e => this.setState({ reter_iss: e.target.checked })} />
                                        <span class="checkbox-custom rectangular"></span>
                                    </label>
                                    <label className="ml-3"><strong>Reter ISS</strong></label>
                                </div>
                            </div>
                        </div>

                        <div class="wizard__dots">
                            <div></div>
                            <button class="button btn__primary" onClick={() => this.verificarEtapa1()}>Próximo</button>
                        </div>
                    </div>

                    {/* <label style={{marginTop: '30px', fontSize: '1.4em', fontWeight: 'bolder'}}>Imóvel</label><hr/> */}

                    <div className="pag">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="grupo__campo">
                                    <label style={{ width: "100%" }}>Garantia: </label>
                                    <br />
                                    <div className="grupo__campo__form" name={`dia_vencimento`}>
                                        <select
                                            defaultValue={this.state.contem_garantia}
                                            onChange={(a) => {
                                                this.setState({ contem_garantia: a.target.value })
                                                if (a.target.value == 1) {
                                                    this.setState({
                                                        tipo_garantia: 1
                                                    })
                                                }
                                            }
                                            }
                                            value={this.state.contem_garantia}
                                        >
                                            <option value="1">Contêm garantia</option>
                                            <option value="2">Não contêm garantia</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {parseInt(this.state.contem_garantia) == 1 && (
                            <>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="grupo__campo">
                                            <label style={{ width: "100%" }}>Tipo Garantia: </label>
                                            <br />
                                            <div className="grupo__campo__form" name={`tipo_garantia`}>
                                                <select
                                                    name="tipo_garantia"
                                                    defaultValue={this.state.tipo_garantia}
                                                    onChange={(a) => {
                                                        this.setState({
                                                            tipo_garantia: a.target.value,
                                                            seguro: "",
                                                            fiadores: [{ fiador: '', cpf_fiador: '', fiador_id: '', telefone: '', celular: '' }],
                                                            titulo: "",
                                                            vigencia_inicial_seguro: "",
                                                            vigencia_final_seguro: "",
                                                            responsabilidade: '',
                                                            valor_calcao: '',
                                                            valor_garantia: "",
                                                        })
                                                    }}
                                                    value={this.state.tipo_garantia}
                                                >
                                                    <option value="-"></option>
                                                    <option value="1">Seguro fiança</option>
                                                    <option value="2">Fiador</option>
                                                    <option value="3">Título</option>
                                                    <option value="4">Calção</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="grupo__campo">
                                            {parseInt(this.state.tipo_garantia) == 1 && (
                                                <>
                                                    <label style={{ width: "100%" }}>Seguro fiança: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`garantia_seguro`}
                                                    >
                                                        <input
                                                            name=""
                                                            onChange={(a) => {
                                                                this.preencheu(`garantia_seguro`)
                                                                this.setState({ seguro: a.target.value })
                                                            }
                                                            }
                                                            value={this.state.seguro}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {parseInt(this.state.tipo_garantia) == 3 && (
                                                <>
                                                    <label style={{ width: "100%" }}>Título: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`titulo_seguro`}
                                                    >
                                                        <input
                                                            name=""
                                                            onChange={(a) => {
                                                                this.preencheu(`titulo_seguro`)
                                                                this.setState({ titulo: a.target.value })
                                                            }
                                                            }
                                                            value={this.state.titulo}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {parseInt(this.state.tipo_garantia) == 4 && (
                                                <>
                                                    <label style={{ width: "100%" }}>Calção: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`valor_calcao`}
                                                    >
                                                        <input
                                                            name=""
                                                            type="input"
                                                            onChange={(a) => {
                                                                this.preencheu(`valor_calcao`)
                                                                this.setState({ valor_calcao: a.target.value })
                                                            }
                                                            }
                                                            value={this.state.valor_calcao}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>



                                    {parseInt(this.state.tipo_garantia) == 4 && (
                                        <>
                                            <div className="col-md-4">
                                                <div className="grupo__campo">
                                                    <label style={{ width: "100%" }}>Responsabilidade: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`responsabilidade`}
                                                    >
                                                        <input
                                                            name=""
                                                            type="input"
                                                            onChange={(a) => {
                                                                this.preencheu(`responsabilidade`)
                                                                this.setState({ responsabilidade: a.target.value })
                                                            }
                                                            }
                                                            value={this.state.responsabilidade}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {(parseInt(this.state.tipo_garantia) == 3 || parseInt(this.state.tipo_garantia) == 1) && (
                                        <>
                                            <div className="col-md-4">
                                                <div className="grupo__campo">
                                                    <label style={{ width: "100%" }}>Data Inicial: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`vigencia_inicial_seguro`}
                                                    >
                                                        <input
                                                            name=""
                                                            type="date"
                                                            onChange={(a) => {
                                                                this.preencheu(`vigencia_inicial_seguro`)
                                                                this.setState({ vigencia_inicial_seguro: a.target.value })
                                                            }
                                                            }
                                                            value={moment(this.state.vigencia_inicial_seguro).format('YYYY-MM-DD')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="grupo__campo">
                                                    <label style={{ width: "100%" }}>Data Final: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`vigencia_final_seguro`}
                                                    >
                                                        <input
                                                            name=""
                                                            type="date"
                                                            onChange={(a) => {
                                                                this.preencheu(`vigencia_final_seguro`)
                                                                this.setState({ vigencia_final_seguro: a.target.value })
                                                            }
                                                            }
                                                            value={moment(this.state.vigencia_final_seguro).format('YYYY-MM-DD')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="grupo__campo">
                                                    <label style={{ width: "100%" }}>Valor Garantia: </label>
                                                    <br />
                                                    <div
                                                        className="grupo__campo__form"
                                                        name={`valor_garantia`}
                                                    >
                                                        <input
                                                            name=""
                                                            type="number"
                                                            onChange={(a) => {
                                                                this.preencheu(`valor_garantia`)
                                                                this.setState({ valor_garantia: a.target.value })
                                                            }
                                                            }
                                                            value={this.state.valor_garantia}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>

                                {parseInt(this.state.tipo_garantia) == 2 && (

                                    <div>
                                        {this.state.fiadores.map((fiador, index) => (

                                            <div className="row" key={index}>
                                                <div className="col-md-3">
                                                    <div className="grupo__campo">
                                                        <label style={{ width: "100%" }}>CPF Fiador: </label>
                                                        <br />
                                                        <div
                                                            className="grupo__campo__form"
                                                            name={`cpf_fiador_${index}`}
                                                        >
                                                            <input
                                                                name={`cpf_fiador`}
                                                                onChange={(event) => {
                                                                    this.handleInputChange(index, event)
                                                                    this.preencheu(`cpf_fiador_${index}`)
                                                                    console.log(fiador.fiador)

                                                                    if (fiador.fiador != '') {
                                                                        this.preencheu(`nome_fiador_${index}`)
                                                                    }
                                                                }}
                                                                value={fiador.cpf_fiador}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="grupo__campo">
                                                        <label style={{ width: "100%" }}>Nome Fiador: </label>
                                                        <br />
                                                        <div
                                                            className="grupo__campo__form "
                                                            name={`nome_fiador_${index}`}
                                                        >

                                                            <input
                                                                name="fiador"
                                                                className=".ml-1"
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                                value={fiador.fiador}
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="grupo__campo">
                                                        <label style={{ width: "100%" }}>Telefone: </label>
                                                        <br />
                                                        <div
                                                            className="grupo__campo__form "
                                                            name={`telefone_fiador_${index}`}
                                                        >

                                                            <input
                                                                name="telefone_fiador"
                                                                className=".ml-1"
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                                value={fiador.telefone}
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="grupo__campo">
                                                        <label style={{ width: "100%" }}>Celular: </label>
                                                        <br />
                                                        <div
                                                            className="grupo__campo__form "
                                                            name={`celular_fiador_${index}`}
                                                        >

                                                            <input
                                                                name="celular_fiador"
                                                                className=".ml-1"
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                                value={fiador.celular}
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="grupo__campo">
                                                        <br></br>
                                                        {index == 0 ? (
                                                            <></>
                                                        ) : (
                                                            <button
                                                                class="button btn__primary mx-2"
                                                                onClick={() => {
                                                                    this.handleDeleteFiador(index)
                                                                }}>Excluir Fiador</button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </>
                        )}


                        {parseInt(this.state.tipo_garantia) == 2 && (

                            <div class="wizard__dots d-flex flex-row-reverse">
                                <button
                                    class="button btn__primary"

                                    onClick={this.handleAddFiador}>Adicionar Fiador</button>

                            </div>
                        )}
                        <div class="wizard__dots">
                            <button
                                class="button btn__primary--outline"
                                onClick={() => this.slide(-1)}
                            >
                                Voltar
                            </button>
                            <div>
                                <button
                                    class="button btn__primary"
                                    onClick={() => this.verificaGarantias()}
                                >
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="pag">
                        <div className="row">
                            <div className="col-md-4">
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Nome seguradora: </label><br />
                                    <div className='grupo__campo__form' name={`seguradora`}>
                                        <input name="" onChange={a => this.setState({ seguradora: a.target.value })} value={this.state.seguradora} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Nº de apólice: </label><br />
                                    <div className='grupo__campo__form' name={`apolice`}>
                                        <input type="number" onChange={a => this.setState({ apolice: a.target.value })} value={this.state.apolice} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Vigência: </label><br />
                                    <div className='grupo__campo__form' name={`vigencia`}>
                                        <input type="date" onChange={a => this.setState({ vigencia: a.target.value })} value={this.state.vigencia} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wizard__dots">
                            <button class="button btn__primary--outline" onClick={() => this.slide(-1)}>Voltar</button>
                            <button class="button btn__primary" onClick={() => this.verificaIncendio()}>Próximo</button>
                        </div>
                    </div>

                    <div className="pag">
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`imovel_cep`}>
                                    <label style={{ width: '100%' }}>CEP: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.imovel_cep} onChange={this.changeCepImovel} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='grupo__campo' name={`imovel_endereco`}>
                                    <label style={{ width: '100%' }}>Endereço: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.imovel_endereco} onChange={(event) => {
                                            this.preencheu(`imovel_endereco`)
                                            this.setState({ imovel_endereco: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`imovel_numero`}>
                                    <label style={{ width: '100%' }}>Número: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.imovel_numero} onChange={(event) => {
                                            this.preencheu(`imovel_numero`)
                                            this.setState({ imovel_numero: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Complemento: </label><br />
                                    <div className='grupo__campo__form' >
                                        <input type='text' value={this.state.imovel_complemento} onChange={(event) => {
                                            this.setState({ imovel_complemento: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`imovel_bairro`}>
                                    <label style={{ width: '100%' }}>Bairro: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.imovel_bairro} onChange={(event) => {
                                            this.preencheu(`imovel_bairro`)
                                            this.setState({ imovel_bairro: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`imovel_cidade`}>
                                    <label style={{ width: '100%' }}>Cidade: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.imovel_cidade} onChange={(event) => {
                                            this.preencheu(`imovel_cidade`)
                                            this.setState({ imovel_cidade: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`imovel_estado`}>
                                    <label style={{ width: '100%' }}>Estado: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <select value={this.state.imovel_estado} onChange={(event) => {
                                            this.preencheu(`imovel_estado`);
                                            this.setState({ imovel_estado: event.target.value })
                                        }}>
                                            <option>Selecione</option>
                                            <option value='AC'>Acre</option>
                                            <option value='AL'>Alagoas</option>
                                            <option value='AP'>Amapá</option>
                                            <option value='AM'>Amazonas</option>
                                            <option value='BA'>Bahia</option>
                                            <option value='CE'>Ceará</option>
                                            <option value='DF'>Distrito Federal</option>
                                            <option value='ES'>Espírito Santo</option>
                                            <option value='GO'>Goiás</option>
                                            <option value='MA'>Maranhão</option>
                                            <option value='MT'>Mato Grosso</option>
                                            <option value='MS'>Mato Grosso do Sul</option>
                                            <option value='MG'>Minas Gerais</option>
                                            <option value='PA'>Pará</option>
                                            <option value='PB'>Paraíba</option>
                                            <option value='PR'>Paraná</option>
                                            <option value='PE'>Pernambuco</option>
                                            <option value='PI'>Piauí</option>
                                            <option value='RJ'>Rio de Janeiro</option>
                                            <option value='RN'>Rio Grande do Norte</option>
                                            <option value='RS'>Rio Grande do Sul</option>
                                            <option value='RO'>Rondônia</option>
                                            <option value='RR'>Roraima</option>
                                            <option value='SC'>Santa Catarina</option>
                                            <option value='SP'>São Paulo</option>
                                            <option value='SE'>Sergipe</option>
                                            <option value='TO'>Tocantins</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Metragem: </label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='number' value={this.state.imovel_metragem} onChange={(event) => {
                                            this.setState({ imovel_metragem: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="grupo__campo" name={`IPTU`}>
                                    <label style={{ width: "100%" }}>IPTU:</label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <input
                                            type="text"
                                            value={this.state.iptu}
                                            onChange={(event) => {
                                                this.setState({ iptu: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="grupo__campo" name={`condominio`}>
                                    <label style={{ width: "100%" }}>Condomínio:</label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <input
                                            type="text"
                                            value={this.state.condominio}
                                            onChange={(event) => {
                                                this.setState({ condominio: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="grupo__campo">
                                    <label style={{ width: "100%" }}>Água: </label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <input
                                            type="text"
                                            value={this.state.agua}
                                            onChange={(event) => {
                                                this.setState({ agua: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="grupo__campo">
                                    <label style={{ width: "100%" }}>Luz: </label>
                                    <br />
                                    <div className="grupo__campo__form">
                                        <input
                                            type="text"
                                            value={this.state.luz}
                                            onChange={(event) => {
                                                this.setState({ luz: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="wizard__dots">
                            <button class="button btn__primary--outline" onClick={() => this.slide(-1)}>Voltar</button>
                            <button class="button btn__primary" onClick={() => this.verificarEtapa2()}>Próximo</button>
                        </div>
                    </div>

                    {/* <label style={{marginTop: '30px', fontSize: '1.4em', fontWeight: 'bolder'}}>Locatário</label><hr/> */}

                    <div className="pag">
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`locatario_cpf_cnpj`}>
                                    <label style={{ width: '100%' }}>CPF/CNPJ: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' maxLength={18} value={this.state.locatario_cpf_cnpj}
                                            onChange={async (event) => {
                                                this.preencheu(`locatario_cpf_cnpj`)

                                                var cpf_cnpj
                                                if (event.target.value.replace(/[^\d]/g, '').length <= 11) {
                                                    cpf_cnpj = event.target.value.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                                                }
                                                else {
                                                    cpf_cnpj = event.target.value.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                                                }
                                                this.setState({ locatario_cpf_cnpj: cpf_cnpj })

                                                if (event.target.value.replace(/[^\d]/g, '').length >= 11 || event.target.value.replace(/[^\d]/g, '').length <= 14) {
                                                    this.setState({ avisoTxt: '' })
                                                    if (event.target.value.replace(/[^\d]/g, '').length == 11 && !cpf.isValid(event.target.value.replace(/[^\d]/g, ''))) {
                                                        this.setState({ avisoTxt: 'CPF inválido' })
                                                    }
                                                    if (event.target.value.replace(/[^\d]/g, '').length == 14 && !cnpj.isValid(event.target.value.replace(/[^\d]/g, ''))) {
                                                        this.setState({ avisoTxt: 'CNPJ inválido' })
                                                    }

                                                    var pessoa = await this.verificarPessoa(cpf_cnpj)

                                                    if (pessoa) {
                                                        this.setState({
                                                            locatario_id: pessoa.id,
                                                            locatario_nome: pessoa.nome,
                                                            locatario_rg_ie: pessoa.rg_ie,
                                                            locatario_email: pessoa.email,
                                                            locatario_telefone: pessoa.telefone,
                                                            locatario_celular: pessoa.celular,
                                                            locatario_cep: pessoa.cep,
                                                            locatario_endereco: pessoa.endereco,
                                                            locatario_bairro: pessoa.bairro,
                                                            locatario_cidade: pessoa.cidade,
                                                            locatario_estado: pessoa.uf,
                                                            locatario_numero: pessoa.numero,
                                                            locatario_complemento: pessoa.complemento,
                                                        })
                                                    }
                                                }
                                                else {
                                                    this.setState({
                                                        locatario_id: 0,
                                                        locatario_nome: '',
                                                        locatario_rg_ie: '',
                                                        locatario_email: '',
                                                        locatario_telefone: '',
                                                        locatario_celular: '',
                                                        locatario_cep: '',
                                                        locatario_endereco: '',
                                                        locatario_bairro: '',
                                                        locatario_cidade: '',
                                                        locatario_estado: '',
                                                        locatario_numero: '',
                                                        locatario_complemento: ''
                                                    })
                                                }
                                            }} />
                                    </div>
                                    <span>{this.state.avisoTxt}</span>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='grupo__campo' name={`locatario_nome`}>
                                    <label style={{ width: '100%' }}>Nome: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_nome} onChange={(event) => {
                                            this.preencheu(`locatario_nome`)
                                            this.setState({ locatario_nome: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>RG: </label><br />
                                    <div className='grupo__campo__form' >
                                        <input type='text' value={this.state.locatario_rg_ie} onChange={(event) => {
                                            this.setState({ locatario_rg_ie: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='grupo__campo' name={`locatario_email`}>
                                    <label style={{ width: '100%' }}>Email: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_email} onChange={(event) => {
                                            this.preencheu(`locatario_email`)
                                            this.setState({ locatario_email: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Telefone: </label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_telefone} onChange={(event) => {
                                            this.setState({ locatario_telefone: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Celular: </label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_celular} onChange={(event) => {
                                            this.setState({ locatario_celular: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`locatario_cep`}>
                                    <label style={{ width: '100%' }}>CEP: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_cep} onChange={this.changeCepLocatario} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='grupo__campo' name={`locatario_endereco`}>
                                    <label style={{ width: '100%' }}>Endereço: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_endereco} onChange={(event) => {
                                            this.preencheu(`locatario_endereco`)
                                            this.setState({ locatario_endereco: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`locatario_numero`}>
                                    <label style={{ width: '100%' }}>Número: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_numero} onChange={(event) => {
                                            this.preencheu(`locatario_numero`)
                                            this.setState({ locatario_numero: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo'>
                                    <label style={{ width: '100%' }}>Complemento: </label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_complemento} onChange={(event) => {
                                            this.setState({ locatario_complemento: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`locatario_bairro`}>
                                    <label style={{ width: '100%' }}>Bairro: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_bairro} onChange={(event) => {
                                            this.preencheu(`locatario_bairro`)
                                            this.setState({ locatario_bairro: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`locatario_cidade`}>
                                    <label style={{ width: '100%' }}>Cidade: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <input type='text' value={this.state.locatario_cidade} onChange={(event) => {
                                            this.preencheu(`locatario_cidade`)
                                            this.setState({ locatario_cidade: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='grupo__campo' name={`locatario_estado`}>
                                    <label style={{ width: '100%' }}>Estado: *</label><br />
                                    <div className='grupo__campo__form'>
                                        <select value={this.state.locatario_estado} onChange={(event) => {
                                            this.preencheu(`locatario_estado`)
                                            this.setState({ locatario_estado: event.target.value })
                                        }}>
                                            <option>Selecione</option>
                                            <option value='AC'>Acre</option>
                                            <option value='AL'>Alagoas</option>
                                            <option value='AP'>Amapá</option>
                                            <option value='AM'>Amazonas</option>
                                            <option value='BA'>Bahia</option>
                                            <option value='CE'>Ceará</option>
                                            <option value='DF'>Distrito Federal</option>
                                            <option value='ES'>Espírito Santo</option>
                                            <option value='GO'>Goiás</option>
                                            <option value='MA'>Maranhão</option>
                                            <option value='MT'>Mato Grosso</option>
                                            <option value='MS'>Mato Grosso do Sul</option>
                                            <option value='MG'>Minas Gerais</option>
                                            <option value='PA'>Pará</option>
                                            <option value='PB'>Paraíba</option>
                                            <option value='PR'>Paraná</option>
                                            <option value='PE'>Pernambuco</option>
                                            <option value='PI'>Piauí</option>
                                            <option value='RJ'>Rio de Janeiro</option>
                                            <option value='RN'>Rio Grande do Norte</option>
                                            <option value='RS'>Rio Grande do Sul</option>
                                            <option value='RO'>Rondônia</option>
                                            <option value='RR'>Roraima</option>
                                            <option value='SC'>Santa Catarina</option>
                                            <option value='SP'>São Paulo</option>
                                            <option value='SE'>Sergipe</option>
                                            <option value='TO'>Tocantins</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="wizard__dots">
                            <button class="button btn__primary--outline" onClick={() => this.slide(-1)}>Voltar</button>
                            <button class="button btn__primary" onClick={() => this.verificarEtapa3()}>Próximo</button>
                        </div>
                    </div>

                    <div className="pag">
                        <div>
                            <button className='button btn__primary'
                                onClick={() => {
                                    this.setState({
                                        locadores: this.state.locadores.concat({
                                            id: 0,
                                            cpf_cnpj: '',
                                            nome: '',
                                            rg_ie: '',
                                            email: '',
                                            telefone: '',
                                            celular: '',
                                            cep: '',
                                            endereco: '',
                                            bairro: '',
                                            cidade: '',
                                            estado: '',
                                            numero: '',
                                            complemento: '',
                                            porcentagem_imovel: 0,
                                            banco: '',
                                            agencia: '',
                                            conta: '',
                                            corrente_poupanca: 0,
                                            beneficiario_nome: '',
                                            beneficiario_cpf_cnpj: ''
                                        })
                                    })
                                }}
                            >Locadores <i style={{ fontSize: '0.9em' }} className='fas fa-plus' /></button>
                            <hr />
                        </div>

                        {this.state.locadores.map(this.carregarLocadores)}

                        <div class="wizard__dots">
                            <button class="button btn__primary--outline" onClick={() => this.slide(-1)}>Voltar</button>
                            <button className='button btn__primary'
                                onClick={() => {
                                    this.cadastrar()
                                }}
                            >Cadastrar</button>
                        </div>

                    </div>

                    {/* <div className='row' style={{padding: '10px'}}>
                    <div className='col-md-12'>
                        <button className='btn__freedom btn--active float-right' style={{fontSize: '1.2em', width: '150px', height: '40px'}}
                            onClick={() => {
                                this.cadastrar()
                            }} 
                        >Cadastrar</button>
                    </div>
                </div> */}

                </div>
                <div className="content-modal" id="modal" style={{ display: 'none' }}>
                    <div className="modal">
                        <div className="modal-head">
                            {this.state.modal_titulo}
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                {this.state.modal_corpo}
                            </div>
                        </div>
                        <div className="modal-foot">
                            {this.state.modal_botoes}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CadastrarContrato;
