
import React from "react"
import {api_freedom} from '../services/apis'
// import jwt_decode from 'jwt-decode'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'
import '../assets/styles/relatorio.css'
	class RelatorioInadimplentesPdf extends React.Component {

	constructor(props) {
		super(props)

	try{
		this.state = {
			loading: false,
			inPrint: false,
			lista: [],
			dataMin: '',
			dataMax: '',
			pesquisa: props.match !== undefined ? jwt_decode.decode(props.match.params.identificador).pesquisa : jwt_decode.decode(props.props)
		}
	}
	catch(erro){
		
	}

}

fotprint = () => {
	this.setState({inPrint: true})

	setTimeout(() => {
			window.print()

			this.setState({inPrint: false})
	}, 350);
}

	listarInadimplentes = async () => {
		// setLoading(true)
		try{
			this.setState({ loading: true })
				let inadimplencia = await api_freedom.get(`/boleto/relatorioinadimplencia-pdf`, {
					params: this.state.pesquisa,
					headers: {"authorization": localStorage.getItem('autenticado_freedom')}
				})
				if(inadimplencia.data.data.data.indexOf('TOKEN') === -1){
					// setLista(inadimplencia.data.data)
					this.setState({ 
						lista: inadimplencia.data.data.data,
						dataMin: inadimplencia.data.data.periodo_data_min,
						dataMax: inadimplencia.data.data.periodo_data_max
					})
				}
				this.setState({ loading: false })
		}
		catch(error){
				console.log(error.message)
				this.setState({ loading: false })
		}
		// setLoading(false)
	}

	async componentDidMount(){
		await this.listarInadimplentes()
	}
	// useEffect(() => {
	// 	listarInadimplentes();
	// }, [])

	render() {
	return (
		<>
			<div className='relatorio_inadimplentes' id='relatorio_inadimplentes'>
				<div style={{color: '#000000'}}>
					<div style={{
						width: '100%',
						height: '100%',
						margin: '0',
						boxSizing: 'border-box',
						fontFamily: 'sans-serif',
						backgroundColor: '#f9f9f9',
						fontSize: '18px'}}>
						<div style={{
							width: '100%',
							padding: '0px 15px',
							paddingTop: '30px',
							// marginLeft: 'auto',
							// marginRight: 'auto',
							backgroundColor: '#fff',
							borderBottom: '1px solid #eee'
						}}>
							<div style={{display: 'flex'}}>
								<div style={{width: '70%', marginTop: 'auto'}}>
									{/* <img src={epar} style={{marginLeft: '5rem'}} /> */}
									<img src={require('../assets/imgs/mobilelogo.png')} style={{marginLeft: '5rem', marginTop: 5}} alt="" width={50} />
									<img src={require('../assets/imgs/epar_texto.png')} style={{marginLeft: '1rem'}} alt="" width={70} />
								</div>
								<div style={{width: '10%'}}>
									<div>
										<p style={{fontSize: '2rem'}}>NOME DA IMOBILIÁRIA:</p>											
									</div>
									<div>
										<p style={{fontSize: '2rem'}}>Data:</p>
									</div>
								</div>
								<div style={{width: '20%'}}>
									<div>
										<p style={{fontSize: '2rem'}}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>											
									</div>
									<div>
										<p style={{fontSize: '2rem'}}><strong>{moment().format('DD/MM/YYYY')}</strong></p>
									</div>
								</div>
							</div>

							<div style={{margin: '20px 0px 0px 0px'}}>
								<table style={{width: '100%'}}>
									<tr>
										<td className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '5px'}} colSpan="12">
											<strong>{`
													RELATÓRIO DE INADIMPLÊNCIA - PERÍODO DE: 
													${moment(this.state.dataMin).format('DD/MM/YYYY')}
													ATÉ 
													${moment(this.state.dataMax).format('DD/MM/YYYY')}
											`}</strong>
										</td>
										<td  id="printPageButton" className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '5px'}} colSpan="2">
											{
												!this.state.loading ? (
													<button className='button btn__primary--outline'
														onClick={() => {
															this.fotprint()
														}} 
														><i class="fas fa-file-pdf" />{" "}
														PDF
													</button>
												) : null
											}
										</td>
									</tr>
								</table>
							</div>
							
							<div className='table-responsive'>
								<table className='table_relatorio' style={{width: '100%'}}>
									<thead>
										<tr>
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>VENCIMENTO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>#CONTRATO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>TIPO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>PRAZO CONTRATO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '250px', color: '#FFF'}}>LOCATÁRIO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '200px', color: '#FFF'}}>ENDEREÇO DO IMÓVEL</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>VALOR EM ABERTO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>VALOR CORRIGIDO</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>ALTEROU DATA</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>REGUA COBRANÇA</th>					
											<th align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF'}}>GARANTIA</th>					
										</tr>
									</thead>
									<tbody>
										{
											this.state.loading ? (
												<tr>
													<td className='text-center' style={{margin: '0px', fontSize: '16px', color: '#000', padding: '10px'}} colSpan={12}>
														Carregando...
													</td>
												</tr>
											) :
											this.state.lista.map((item, index) => (
												<tr id={'pop'+index} key={index} style={{backgroundColor: '#CCC', wordBreak: 'break-word'}}>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{moment(item.data_vencimento_boleto).format('DD/MM/YYYY')}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{item.contrato_id}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{item.tipo_locacao}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>
														<div>{moment(item.data_inicio).format('DD/MM/YYYY')}</div>
														<div>{moment(item.data_fim).format('DD/MM/YYYY')}</div>
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '250px'}}>
														<div>{item.locatario_nome}</div>
														<div>{item.locatario_cpf_cnpj}</div>
														<div>{item.locatario_telefone.split(';')[0]}</div>
														<div>{item.locatario_email.toLowerCase().split(';')[0]}</div>
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '200px'}}>
														{/* <div>{`${item.imovel.endereco}, ${item.imovel.numero}, ${item.imovel.bairro}, ${item.imovel.cidade}, ${item.imovel.estado}`}</div> */}
														{item.imovel_endereco}
													</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{parseFloat(item.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{
														parseFloat((item.valor_boleto_corrigido)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
													}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{moment(item.data_vencimento_boleto).format('D') === item.dia_vencimento_aluguel ? 'NÃO' : 'SIM'}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'center'}}>{`${moment(item.email_enviado).format('DD/MM/YYYY')}: Envio de e-mail`}</td>
													<td align="left" style={{fontSize: '12px', padding: '5px', textAlign: 'left'}}>
														<div>{item.tipo_garantia}</div>
														{
															item.tipo_garantia === 'FIADOR' ? 
																<>
																	<div>{item.fiador.toUpperCase()}</div> 
																	<div>{item.cpf_cnpj_fiador}</div> 
																</>
															: item.tipo_garantia === 'SEGURO FIANÇA' ? ( 
																<>
																	<div>{item.seguro.toUpperCase()}</div> 
																	<div>{`APOLICE #${item.apolice}`}</div> 
																</>
															)
															: item.tipo_garantia === 'TÍTULO' ? ( 
																<div>{item.titulo.toUpperCase()}</div> 
															) : null
														}
													</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{/* <div>
					<table style={{width: '100%'}}>
						<tr>
							<td>
								Vencimento
							</td>
							<td>
								Informar a data de vencimento do boleto. Poderemos filtrar por data
							</td>
						</tr>
					</table>
				</div> */}
			</div>
		</>
	);
								}
}


export default RelatorioInadimplentesPdf;
