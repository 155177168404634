
import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'

class Servicos extends React.Component {

  constructor(props) {
    super(props)
    if(!localStorage.getItem('autenticado_freedom')){
        props.history.push('/')
    } 

    this.state = {
        servicos: [],
        pagina: 1, 
        quantidade_paginas: 1,
        mensagem: 'Carregando ....',
        total_servicos: 0,
        acao: '',  
        modal_titulo: '',
        modal_corpo: '',
        modal_botoes: '',
        nome: '',
        plano_contas: null,
        conceder_contrato: 0,
        credito_debito: 1,
        gerar_boleto: 0,
        cobranca_recorrente: 0,
        descontar_repasse: 0,
        somar_repasse: 0,
        emitido_demonstrativo: 1,
        calculo_taxa_administracao: 0,
        id: ''
    }

  }

  componentDidMount(){
    this.servicos()
  }

  servicos = async () => {
    try{
        let servicos = await api_freedom.get(`/servico/all`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        if(servicos.data.data.indexOf('TOKEN') === -1){
            this.setState({ 
                servicos: servicos.data.data,
                total_servicos: servicos.data.data.length, 
                quantidade_paginas: Math.ceil(servicos.data.data.length / 10),
                mensagem: 'Nenhum registro encontrado.'
            })
        }
    }
    catch(error){
        console.log(error.message)
    }
  }

  listarServicos = (record, index) => {
    return (
        <tr key={index}>
            <td className="tbody__text"><p>{record.id}</p></td>
            <td className="tbody__text"><p>{record.nome}</p> </td>
            <td className="tbody__text"><p>{record.plano_contas}</p></td>
            <td className="tbody__text"><p>{record.credito_debito === 1 ? 'Crédito' : 'Débito'}</p></td>
            <td className="tbody__text"><p>{record.gerar_boleto === 1 ? 'Sim' : record.gerar_boleto === 0 ? 'Não' : ''}</p></td>
            <td className="tbody__text"><p>{record.somar_repasse === 1 ? 'Sim' : record.somar_repasse === 0 ? 'Não' : ''}</p></td>
            <td className="tbody__text"><p>{record.descontar_repasse === 1 ? 'Sim' : record.descontar_repasse === 0 ? 'Não' : ''}</p></td>
            <td className="tbody__text"><p>{record.emitido_demonstrativo === 1 ? 'Locatário' : record.emitido_demonstrativo === 2 ? 'Proprietário' : record.emitido_demonstrativo === 3 ? 'Locatário e Proprietário' : ''}</p></td>
            <td className="tbody__text"><p>{record.calculo_taxa_administracao === 1 ? 'Sim' : record.calculo_taxa_administracao === 0 ? 'Não' : ''}</p></td>
            <td className="tbody__text">
                <button className='button btn__primary--outline'
                    onClick={() => {
                        this.alterarServico(record)
                    }} 
                ><i className='fas fa-pen'/></button>
            </td>
            <td className="tbody__text">
                <button className="button btn__primary--outline"
                    onClick={() => {
                        this.deletarServico(record)
                    }} 
                >Deletar</button>
            </td>
        </tr>
    )
  }

  addServico = () => {
    
    this.setState({
        modal_titulo: 'Novo serviço',
        modal_botoes: <>
            <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
            <button className='button btn__primary' onClick={this.adicionarServico}>Adicionar</button>
        </>,
        modal_corpo: <div>
            <div style={{marginTop: '1%'}}>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Nome do serviço: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='text' defaultValue={''} onChange={(event) => {
                            this.setState({nome: event.target.value})
                        }}/>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Plano de contas: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='text' defaultValue={null} onChange={(event) => {
                            this.setState({plano_contas: event.target.value})
                        }}/>
                    </div>
                </div>
                {/* <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Conceder contrato: </label><br/>
                    <div className='grupo__input'>
                    <select defaultValue={0} onChange={(event) => {
                        this.setState({conceder_contrato: event.target.value})
                    }}>
                        <option value={0}>Não</option>
                        <option value={1}>Sim</option>
                    </select>
                    </div>
                </div> */}
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Crédito ou débito: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={1} onChange={(event) => {
                            this.setState({credito_debito: event.target.value})
                        }}>
                            <option value={1}>Crédito</option>
                            <option value={0}>Débito</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Gerar boleto: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={0} onChange={(event) => {
                            this.setState({gerar_boleto: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
                {/* <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Cobrança recorrente: </label><br/>
                    <div className='grupo__input'>
                    <select defaultValue={0} onChange={(event) => {
                        this.setState({cobranca_recorrente: event.target.value})
                    }}>
                        <option value={0}>Não</option>
                        <option value={1}>Sim</option>
                    </select>
                    </div>
                </div> */}
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Somar quando houver repasse: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={0} onChange={(event) => {
                            this.setState({somar_repasse: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Descontar quando houver repasse: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={0} onChange={(event) => {
                            this.setState({descontar_repasse: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Emetido no demonstrativo: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={1} onChange={(event) => {
                            this.setState({emitido_demonstrativo: event.target.value})
                        }}>
                            <option value={1}>Locatário</option>
                            <option value={2}>Proprietário</option>
                            <option value={3}>Locatário e Proprietário</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Calcular na taxa de administração: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={0} onChange={(event) => {
                            this.setState({calculo_taxa_administracao: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
            </div>
        </div> 
    })
    this.abrirModal()
  }

  adicionarServico = async () => {
    if(this.state.nome && this.state.credito_debito){
        let servico = await api_freedom.post('/servico/create', {
            nome: this.state.nome,
            plano_contas: this.state.plano_contas,
            conceder_contrato: this.state.conceder_contrato,
            automatico: 0,
            credito_debito: this.state.credito_debito,
            gerar_boleto: this.state.gerar_boleto,
            cobranca_recorrente: this.state.cobranca_recorrente,
            descontar_repasse: this.state.descontar_repasse,
            somar_repasse: this.state.somar_repasse,
            emitido_demonstrativo: this.state.emitido_demonstrativo,
            calculo_taxa_administracao: this.state.calculo_taxa_administracao
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(servico.data.data.insertId){
            alert('Inserido com sucesso')
            this.servicos()
            this.setState({
                modal_titulo: '',
                modal_corpo: '',
                modal_botoes: '',
                nome: '',
                plano_contas: null,
                conceder_contrato: 0,
                credito_debito: 1,
                gerar_boleto: 0,
                cobranca_recorrente: 0,
                descontar_repasse: 0,
                somar_repasse: 0,
                emitido_demonstrativo: 1,
                calculo_taxa_administracao: 0
            })
            this.fecharModal()
        }
        else{
            alert('Ocorreu um erro, tente novamente')
        }
    }
    else{
        alert('Informe pelo menos o nome e se é crédito ou débito para prosseguir')
    }
  }

  abrirModal = () => {
    var modal = document.getElementById('modal');
    modal.style.display = "flex";
  }

  fecharModal = () => {
    var modal = document.getElementById('modal');
    modal.style.display = "none";
  }

  deletarServico = async (servico) => {
    let deletado = await api_freedom.post('/servico/delete', {
        id: servico.id,
    }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

    console.log(deletado.data.data)
    if (!deletado.data.data) {
        alert('Não é possivel deletar serviços com boletos vinculados')
    } else {
        this.servicos()
        alert('Serviço deletado com sucesso')
    }
  }

  alterarServico = (servico) => {

    this.setState({
        id: servico.id,
        nome: servico.nome,
        plano_contas: servico.plano_contas,
        conceder_contrato: servico.conceder_contrato,
        credito_debito: servico.credito_debito,
        gerar_boleto: servico.gerar_boleto,
        cobranca_recorrente: servico.cobranca_recorrente,
        descontar_repasse: servico.descontar_repasse,
        somar_repasse: servico.somar_repasse,
        emitido_demonstrativo: servico.emitido_demonstrativo,
        calculo_taxa_administracao: servico.calculo_taxa_administracao
    })
    
    this.setState({
        modal_titulo: `Alterar o serviço ${servico.nome}`,
        modal_botoes: <>
            <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
            <button hidden={this.disable_button()} className='button btn__primary' onClick={this.alterarServicoAPI}>Alterar</button>
        </>,
        modal_corpo: <div>
            <div style={{marginTop: '1%'}}>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Nome do serviço: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='text' defaultValue={servico.nome} onChange={(event) => {
                            this.setState({nome: event.target.value})
                        }} readOnly/>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Plano de contas: </label><br/>
                    <div className='grupo__campo__form'>
                        <input type='text' defaultValue={servico.plano_contas} onChange={(event) => {
                            this.setState({plano_contas: event.target.value})
                        }}/>
                    </div>
                </div>
                {/* <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Conceder contrato: </label><br/>
                    <div className='grupo__input'>
                    <select defaultValue={0} onChange={(event) => {
                        this.setState({conceder_contrato: event.target.value})
                    }}>
                        <option value={0}>Não</option>
                        <option value={1}>Sim</option>
                    </select>
                    </div>
                </div> */}
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Crédito ou débito: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={servico.credito_debito} onChange={(event) => {
                            this.setState({credito_debito: event.target.value})
                        }}>
                            <option value={1}>Crédito</option>
                            <option value={0}>Débito</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Gerar boleto: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={servico.gerar_boleto} onChange={(event) => {
                            this.setState({gerar_boleto: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
                {/* <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Cobrança recorrente: </label><br/>
                    <div className='grupo__input'>
                    <select defaultValue={0} onChange={(event) => {
                        this.setState({cobranca_recorrente: event.target.value})
                    }}>
                        <option value={0}>Não</option>
                        <option value={1}>Sim</option>
                    </select>
                    </div>
                </div> */}
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Somar quando houver repasse: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={servico.somar_repasse} onChange={(event) => {
                            this.setState({somar_repasse: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Descontar quando houver repasse: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={servico.descontar_repasse} onChange={(event) => {
                            this.setState({descontar_repasse: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Emetido no demonstrativo: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={servico.emitido_demonstrativo} onChange={(event) => {
                            this.setState({emitido_demonstrativo: event.target.value})
                        }}>
                            <option value={1}>Locatário</option>
                            <option value={2}>Proprietário</option>
                            <option value={3}>Locatário e Proprietário</option>
                        </select>
                    </div>
                </div>
                <div className='grupo__campo'>
                    <label style={{width: '100%'}}>Calcular na taxa de administração: </label><br/>
                    <div className='grupo__campo__form'>
                        <select defaultValue={servico.calculo_taxa_administracao} onChange={(event) => {
                            this.setState({calculo_taxa_administracao: event.target.value})
                        }}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </select>
                    </div>
                </div>
            </div>
        </div> 
    })
    this.abrirModal()
  }

  alterarServicoAPI = async () => {
    if(this.state.nome && this.state.credito_debito && this.state.id){
        let servico = await api_freedom.post('/servico/update', {
            nome: this.state.nome,
            plano_contas: this.state.plano_contas,
            conceder_contrato: this.state.conceder_contrato,
            automatico: 0,
            credito_debito: this.state.credito_debito,
            gerar_boleto: this.state.gerar_boleto,
            cobranca_recorrente: this.state.cobranca_recorrente,
            descontar_repasse: this.state.descontar_repasse,
            somar_repasse: this.state.somar_repasse,
            emitido_demonstrativo: this.state.emitido_demonstrativo,
            calculo_taxa_administracao: this.state.calculo_taxa_administracao,
            id: this.state.id
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(servico.data.data.affectedRows === 1){
            alert('Alterado com sucesso')
            this.servicos()
            this.setState({
                modal_titulo: '',
                modal_corpo: '',
                modal_botoes: '',
                nome: '',
                plano_contas: null,
                conceder_contrato: 0,
                credito_debito: 1,
                gerar_boleto: 0,
                cobranca_recorrente: 0,
                descontar_repasse: 0,
                somar_repasse: 0,
                emitido_demonstrativo: 1,
                calculo_taxa_administracao: 0,
                id: ''
            })
            this.fecharModal()
        }
        else{
            alert('Ocorreu um erro, tente novamente')
        }
    }
    else{
        alert('Informe pelo menos o nome e se é crédito ou débito para prosseguir')
    }
  }

  disable_button() {
    if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
        return true
    } else {
        return false
    }
  }

  render() {
    return (
      <>
        <div>

            <button hidden={this.disable_button()} className=' btn__primary button'
            onClick={this.addServico}>Adicionar serviço</button>

            {this.state.servicos.length > 0 && 
                <div style={{padding: '1vw', marginBottom: '-20px'}}>
                    <label>Total de servicos: {this.state.total_servicos}</label>
                </div>
            }
            <div className='table mt-4'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                <div class="thead__item">
                                    <label>Serviço</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Nome</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Plano de contas</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Crédito ou débito</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Gerar boleto</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Somar repasse</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Descontar repasse</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Emitido no <br/> demonstrativo</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Calcular na taxa <br/> de administração</label>
                                </div>
                            </th>
                            <th colSpan={2}>
                                <div class="thead__item">
                                    <label>Ações</label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.servicos.length === 0 && <tr><th className='text-center' colSpan='10'>{this.state.mensagem}</th></tr>}
                        {this.state.servicos.slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(this.listarServicos)}
                    </tbody>
                </table>
            </div>
            {this.state.servicos.length > 0 && <div className='pagination'>
                <div className="pagination__arrow" onClick={() => {
                    this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                }}>
                    {'<'}
                </div>
                <div className="pagination__numbers">
                    {
                        [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                            ){
                                return (
                                    <p className={this.state.pagina === index + 1 ? 'pagination__index' : ''} onClick={() => {
                                        this.setState({pagina: index + 1})
                                    }}>
                                        {index + 1}
                                    </p>
                                )
                            }
                            else{
                                return null
                            }
                        })
                    }
                </div>
                <div className="pagination__arrow" onClick={() => {
                    this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                }}>
                    {'>'}
                </div>
            </div>}
        </div>
        <div className="content-modal" id="modal" style={{display: 'none'}}>
            <div className="modal">
                <div className="modal-head">
                    {this.state.modal_titulo}
                </div>
                <div className="modal-body">
                    <div className='modal-scroll'>
                        {this.state.modal_corpo}
                    </div>
                </div>
                <div className="modal-foot">
                    {this.state.modal_botoes}
                </div>
            </div>
        </div>
      </>
    );
  }
}

export default Servicos;
