import React, { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';

const ModalErroGerarBoleto = (props) => {

  const [permissao, setPermissao] = useState(jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "ReadWriteMaster" ? true : false);


  useEffect(() => {}, []);

  
  return (
    <>
    <div className="content-modal" id="modal" style={{display: "flex"}}>
        <div className="modal">

      <div className="modal-head">{props.dados}</div>
        <div className="modal-body">
          <div className="modal-scroll">
            <div className="d-flex">
              <label style={{ marginRight: 10 }}><strong>Success:</strong></label>
              <label>{props.mensagem?.Success}</label>
            </div>
            <div className="d-flex">
              <label style={{ marginRight: 10 }}><strong>Message:</strong></label>
              <label>{props.mensagem?.Message}</label>
            </div>
            <div className="d-flex">
              <label style={{ marginRight: 10 }}><strong>Validation:</strong></label>
              <label>{props.mensagem?.Validation[0]?.Value}</label>
            </div>
            {permissao ? (
              <>
                <label style={{ marginRight: 10 }}><strong>Payload:</strong></label>
                <textarea
                  className="w-100"
                  rows="14"
                  readOnly>
                  {props?.payload}
                </textarea>
              </>
            ) : null}
          </div>
        </div>
      <div className="modal-foot">
        <button
          className="button btn__primary--outline"
          onClick={props.fechar}
        >
          Fechar
        </button>
      </div>
      </div>
      </div>
    </>
  )
}

export default ModalErroGerarBoleto