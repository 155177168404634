import React, { useEffect, useState } from "react";
import { api_cep, api_freedom } from "../services/apis";
import jwt_decode from "jwt-decode";
import { cpf, cnpj } from "cpf-cnpj-validator";
import moment from "moment";

const AdicionarPessoa = (props) => {

  const [id, setId] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [nome, setNome] = useState("");
  const [rgIe, setRgIe] = useState("");
  const [email, setEmail] = useState("");
  const [dataNasc, setDataNasc] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");


  const verificarPessoa = async (cpf_cnpj) => {
    let pessoa = await api_freedom.post(
      `/pessoa/cpfcnpj`,
      {
        cpf_cnpj: cpf_cnpj,
        imobiliaria_id: jwt_decode(localStorage.getItem("autenticado_freedom"))
          .imobiliaria_id,
      },
      {
        headers: { authorization: localStorage.getItem("autenticado_freedom") },
      }
    );

    if (pessoa.data.data.length > 0) {
      return pessoa.data.data[0];

    }

    return false;
  };

  const adicionarPessoa = async () => {

    if (!nome) {
      preencher("pessoa_nome");
      return 0;
    }

    if (!cpfCnpj) {
      preencher("pessoa_cpf_cnpj");
      return 0;
    }

    if (!rgIe) {
      preencher("pessoa_rg_ie");
      return 0;
    }

    if (!email) {
      preencher("pessoa_email");
      return 0;
    }

    // if(!telefone){
    //   preencher("pessoa_telefone");
    //   return 0;
    // }

    // if(!celular){
    //   preencher("pessoa_celular");
    //   return 0;
    // }

    if (!cep) {
      preencher("pessoa_cep");
      return 0;
    }

    if (!endereco) {
      preencher("pessoa_endereco");
      return 0;
    }

    if (!numero) {
      preencher("pessoa_numero");
      return 0;
    }

    if (!bairro) {
      preencher("pessoa_bairro");
      return 0;
    }

    if (!cidade) {
      preencher("pessoa_cidade");
      return 0;
    }

    if (!estado) {
      preencher("pessoa_estado");
      return 0;
    }

    try {

      const response = await api_freedom.post(
        "/pessoa/create",
        {
          imobiliaria_id: jwt_decode(
            localStorage.getItem("autenticado_freedom")
          ).imobiliaria_id,
          nome: nome,
          cpf_cnpj: cpfCnpj,
          rg_ie: rgIe,
          email: email,
          data_nasc: dataNasc ? dataNasc : null,
          telefone: telefone,
          celular: celular,
          endereco: endereco,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          cidade: cidade,
          uf: estado,
          cep: cep,
        },
        {
          headers: {
            authorization: localStorage.getItem("autenticado_freedom"),
          },
        }
      );
      // console.log(response);

      alert("Pessoa adicionada com sucesso")
      props.fechar()
      props.atualizar()
    } catch (error) {
      props.fechar()
      console.log(error.message);
      alert("Ocorreu um erro");
    }
  }

  const preencher = (name) => {
    if (!document.getElementsByName(name)[0].classList.contains('input-active-border')) {
      document.getElementsByName(name)[0].classList.add('input-active-border')
    }
  }

  const preencheu = (name) => {
    if (document.getElementsByName(name)[0].classList.contains("input-active-border")) {
      document.getElementsByName(name)[0].classList.remove("input-active-border");
    }
  };

  const changeCepPessoa = (event) => {

    if (event.target.value.replace(/\D/g, "").length === 8) {
      api_cep
        .get(`/${event.target.value.replace(/\D/g, "")}/json`)
        .then((dados_cep) => {
          setCep(dados_cep.data.cep);
          setEndereco(dados_cep.data.logradouro);
          setBairro(dados_cep.data.bairro);
          setCidade(dados_cep.data.localidade);
          setEstado(dados_cep.data.uf);
          setNumero("");
          setComplemento("");
        });
    } else {
      setEndereco("");
      setBairro("");
      setCidade("");
      setEstado("");
      setNumero("");
      setComplemento("");
    }
    setCep(event.target.value);
  };


  useEffect(() => { }, [cpfCnpj]);


  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div className="modal">

          <div className="modal-head">Adicionar Pessoa</div>
          <div className="modal-body">
            <div className="modal-scroll">
              <div style={{ marginTop: "1%" }}>
                <div className='grupo__campo' name={`pessoa_nome`}>
                  <label style={{ width: '100%' }}>Nome *: </label>
                  <div className='grupo__campo__form'>
                    <input type='text' value={nome} onChange={(event) => {
                      preencheu(`pessoa_nome`)
                      setNome(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_cpf_cnpj`}>
                  <label style={{ width: '100%' }}>CPF/CNPJ *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={cpfCnpj} onChange={async (event) => {
                      preencheu(`pessoa_cpf_cnpj`)
                      // setCpfCnpj(event.target.value)
                      var cpf_cnpj;
                      if (
                        event.target.value.replace(/[^\d]/g, "").length <= 11
                      ) {
                        cpf_cnpj = event.target.value
                          .replace(/[^\d]/g, "")
                          .replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          );
                      } else {
                        cpf_cnpj = event.target.value
                          .replace(/[^\d]/g, "")
                          .replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            "$1.$2.$3/$4-$5"
                          );
                      }
                      setCpfCnpj(cpf_cnpj)

                      if (
                        event.target.value.replace(/[^\d]/g, "").length ===
                        11 ||
                        event.target.value.replace(/[^\d]/g, "").length === 14
                      ) {
                        if (
                          event.target.value.replace(/[^\d]/g, "").length ===
                          11 &&
                          !cpf.isValid(
                            event.target.value.replace(/[^\d]/g, "")
                          )
                        ) {
                          alert("CPF inválido");
                        }
                        if (
                          event.target.value.replace(/[^\d]/g, "").length ===
                          14 &&
                          !cnpj.isValid(
                            event.target.value.replace(/[^\d]/g, "")
                          )
                        ) {
                          alert("CNPJ inválido");
                        }

                        var pessoa = await verificarPessoa(cpf_cnpj);

                        if (pessoa) {
                          alert("CPF/CNPJ já cadastrado.")
                          setCpfCnpj("")
                        }
                      }
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_rg_ie`}>
                  <label style={{ width: '100%' }}>RG *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={rgIe} onChange={(event) => {
                      preencheu(`pessoa_rg_ie`)
                      setRgIe(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_email`}>
                  <label style={{ width: '100%' }}>Email *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={email} onChange={(event) => {
                      preencheu(`pessoa_email`)
                      setEmail(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_email`}>
                  <label style={{ width: '100%' }}>Data de Nascimento *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='date' value={dataNasc} onChange={(event) => {
                      if (event.target.value.length <= 10) {
                        preencheu(`pessoa_email`)
                        setDataNasc(event.target.value)
                      }
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_telefone`}>
                  <label style={{ width: '100%' }}>Telefone: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={telefone} onChange={(event) => {
                      preencheu(`pessoa_telefone`)
                      setTelefone(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_celular`}>
                  <label style={{ width: '100%' }}>Celular: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={celular} onChange={(event) => {
                      preencheu(`pessoa_celular`)
                      setCelular(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_cep`}>
                  <label style={{ width: '100%' }}>CEP *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={cep}
                      onChange={(event) => {
                        preencheu(`pessoa_cep`)
                        // setCep(event.target.value)
                        changeCepPessoa(event)
                      }}
                    />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_endereco`}>
                  <label style={{ width: '100%' }}>Endereço *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={endereco} onChange={(event) => {
                      preencheu(`pessoa_endereco`)
                      setEndereco(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_numero`}>
                  <label style={{ width: '100%' }}>Número *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={numero} onChange={(event) => {
                      preencheu(`pessoa_numero`)
                      setNumero(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_complemento`}>
                  <label style={{ width: '100%' }}>Complemento: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={complemento} onChange={(event) => {
                      preencheu(`pessoa_complemento`)
                      setComplemento(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_bairro`}>
                  <label style={{ width: '100%' }}>Bairro *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={bairro} onChange={(event) => {
                      preencheu(`pessoa_bairro`)
                      setBairro(event.target.value)
                    }} />
                  </div>
                </div>
                <div className='grupo__campo' name={`pessoa_cidade`}>
                  <label style={{ width: '100%' }}>Cidade *: </label><br />
                  <div className='grupo__campo__form'>
                    <input type='text' value={cidade} onChange={(event) => {
                      preencheu(`pessoa_cidade`)
                      setCidade(event.target.value)
                    }} />
                  </div>
                </div>
                <div className="grupo__campo" name={`pessoa_estado`}>
                  <label style={{ width: "100%" }}>Estado: *</label>
                  <br />
                  <div className="grupo__campo__form">
                    <select
                      value={estado}
                      onChange={(event) => {
                        preencheu(`pessoa_estado`)
                        setEstado(event.target.value)
                      }}
                    >
                      <option>Selecione</option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={props.fechar}
            >
              Fechar
            </button>
            <button
              className="button btn__primary"
              onClick={adicionarPessoa}
            >
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdicionarPessoa