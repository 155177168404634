
import React from "react"
import { api_freedom } from '../services/apis'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import calcularImpostoLocador from '../util/calcularImpostoLocador';

class AlterarBoleto extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            boleto: {},
            contrato: [],
            descricao_boleto: [],
            descricao_boleto_new: [],
            mensagem: 'Carregando ....',
            total_cobrancas: 0,
            acao: '',
            servicos: [],
            servico: '',
            valor_servico: 0,
            vencimento_servico: '',
            referencia_servico: '',
            mes_ano_servico: '',
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: '',
            vencimento_boleto: '2020-01-01',
            vencimento_boleto_new: '2020-01-01',
            locadores: [],
            locador: {},
            despesas_dedutiveis: 0,
            locadorSelecionado: null,
            vencimento: '',
            mes_ano: '',
            valorAluguelIR: 0,
            load: false,
        }

    }

    componentDidMount() {
        this.contrato()
        this.cobrancas()
        this.servicos()
    }

    contrato = async () => {
        try {
            let contrato = await api_freedom.get(`/contrato/detalhe/${this.props.match.params.contrato_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (contrato.data.data.indexOf('TOKEN') === -1) {
                this.setState({ contrato: contrato.data.data[0] })
                const locadores = contrato.data.data[0].pessoas.filter((p) => {
                    return p.tipo === 'Locador'
                });
                locadores.forEach((locador) => {
                    const resultadoIR = calcularImpostoLocador(locador.percentagem, contrato.data.data[0].valor_aluguel, 0, locador.nome + "; CPF: " + locador.cpf_cnpj);
                    locador.resultadoIR = resultadoIR.resultadoIR;
                    locador.aliquotaIR = resultadoIR.aliquotaIR;
                    locador.valorPagarIR = resultadoIR.valorPagarIR;
                    locador.valorAluguelIR = resultadoIR.valorAluguelIR;
                });

                this.setState({
                    locadores: contrato.data.data[0].pessoas.filter((p) => {
                        return p.tipo === 'Locador'
                    }),
                    valorAluguelIR: contrato.data.data[0].valor_aluguel,
                    locadorSelecionado: locadores.length > 0 ? locadores[0] : null,
                })
                if (jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id !== contrato.data.data[0].imobiliaria_id) {
                    this.props.history.push('/freedom/contratos')
                }
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    cobrancas = async () => {
        try {
            let parcelas = await api_freedom.get(`/financeiro/idcontrato/${this.props.match.params.contrato_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (parcelas.data.data.indexOf('TOKEN') === -1) {
                var parcela = parcelas.data.data.filter((p) => {
                    return p.id === parseInt(this.props.match.params.parcela_id) && p.boleto_id
                })[0]
                if (!parcela) {
                    alert('Parcela não tem boleto')
                    this.props.history.push(`/freedom/parcelas/${this.props.match.params.contrato_id}`)
                }
                if (parcela.pago) {
                    alert('Parcela paga')
                    this.props.history.push(`/freedom/parcelas/${this.props.match.params.contrato_id}`)
                }
                var descricao_boleto = parcelas.data.data.filter((p) => {
                    return p.mes_ano === parcela.mes_ano && p.gerar_boleto === 1
                })
                var descricao_boleto_new = parcelas.data.data.filter((p) => {
                    return p.mes_ano === parcela.mes_ano && p.gerar_boleto === 1
                })

                let boleto = await api_freedom.get(`/boleto/id/${descricao_boleto[0].boleto_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                this.setState({
                    descricao_boleto: descricao_boleto,
                    descricao_boleto_new: descricao_boleto_new,
                    mensagem: 'Nenhum registro encontrado.',
                    boleto: boleto.data.data[0],
                    vencimento_boleto: boleto.data.data[0].data_vencimento,
                    vencimento_boleto_new: boleto.data.data[0].data_vencimento
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    servicos = async () => {
        try {
            let servicos = await api_freedom.get(`/servico/all`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (servicos.data.data.indexOf('TOKEN') === -1) {
                this.setState({
                    servicos: servicos.data.data,
                    servico: servicos.data.data.filter((s) => {
                        return s.automatico !== 1
                    })[0].id
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    listarDescricao = (record, index) => {

        var valor_boleto = 0
        this.state.descricao_boleto_new.map((d, i) => {
            if (i <= index) {
                if (d.credito_debito === 1) {
                    valor_boleto += d.valor
                }
                else {
                    valor_boleto -= d.valor
                }
            }
            return null
        })


        return (
            <tr key={index}>
                <td>{this.props.match.params.contrato_id}</td>
                <td>{moment(record.data_vencimento).utc(false).format('DD/MM/YYYY')}</td>
                <td>{record.referencia ? record.referencia : `${record.nome} ${record.pessoa_id ? ` para ${record.pessoa_nome}` : ''}`}</td>
                <td>{record.credito_debito === 1 ? record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                <td style={{ color: 'red' }}>{record.credito_debito === 0 ? `-${record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : ''}</td>
                <td>{valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td>{record.nome === 'Aluguel' ? `Não é possível excluir esse serviço` :
                    <button className='btn__freedom btn--active' style={{ marginTop: '-1px', width: '180px', height: '30px' }}
                        onClick={() => {
                            this.setState({
                                modal_titulo: `Excluir o serviço referente ${record.nome}`,
                                modal_corpo: <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                                    Deseja realmente prosseguir?
                                </div>,
                                modal_botoes: <>
                                    <button className='btn__freedom btn--active' style={{ height: '30px', borderRadius: '50px' }} onClick={() => { this.fecharModal() }}>Não</button>
                                    <button className='btn__freedom btn--normal' style={{ height: '30px', borderRadius: '50px' }} onClick={() => { this.fecharModal(); this.excluirServico(index) }}>Sim</button>
                                </>
                            })
                            this.abrirModal()
                        }}
                    >Excluir serviço</button>}</td>
            </tr>
        )
    }

    abrirModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "flex";
    }

    fecharModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "none";
    }

    abrirModalIR = () => {
        var modal = document.getElementById('modalIR');
        modal.style.display = "flex";
    }

    fecharModalIR = () => {
        var modal = document.getElementById('modalIR');
        modal.style.display = "none";
    }

    ajustesParcela = (id) => {
        var vencimento = moment(this.state.descricao_boleto.filter((p) => {
            return p.id === id
        })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

        var mes_ano = this.state.descricao_boleto.filter((p) => {
            return p.id === id
        })[0].mes_ano

        this.setState({
            vencimento_servico: vencimento,
            mes_ano_servico: mes_ano,
            modal_titulo: 'Retenção de IR',
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={this.salvarAjustes}>Salvar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Serviço: </label><br />
                        <select className='grupo__campo__form' onChange={(event) => {
                            this.setState({ servico: event.target.value })
                        }}>
                            {this.state.servicos.filter((servico) => {
                                return servico.automatico !== 1 && servico.id !== 6
                            }).map((s) => {
                                return (
                                    <option key={s.nome} value={s.id}>{s.nome}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Valor a cobrar pelo serviço: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='number' defaultValue={0} min='0' onChange={(event) => {
                                this.setState({ valor_servico: event.target.value })
                            }} />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Referência do serviço: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='text' defaultValue={''} onChange={(event) => {
                                this.setState({ referencia_servico: event.target.value })
                            }} placeholder='Exemplo: Serviço referente ao período 01/01/2020 a 31/01/2020' />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Data de vencimento: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='date' value={vencimento} readOnly />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Mês e ano de cobrança: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='month' value={mes_ano} readOnly />
                        </div>
                    </div>
                </div>
            </div>
        })
        this.abrirModal()
    }

    handleLocadorChange = (event) => {
        const locadorId = parseInt(event.target.value);
        const locadorSelecionado = this.state.locadores.find(
            (locador) => locador.id === locadorId
        );
        this.setState({ locadorSelecionado });
    };


    handleInputChange = (event) => {
        const { id, value } = event.target;
        const locadorSelecionado = {
            ...this.state.locadorSelecionado,
            [id]: value,
        };
        this.setState({ locadorSelecionado });
        const locadores = this.state.locadores.map((locador) => {
            if (locador.id === locadorSelecionado.id) {
                return locadorSelecionado;
            }
            return locador;
        });
        this.setState({ locadores });
    };

    salvarAjustes = async () => {
        if (this.state.valor_servico && parseFloat(this.state.valor_servico) !== 0) {
            var detalhes = this.state.descricao_boleto_new
            var servico = this.state.servicos.filter((s) => {
                return s.id === parseInt(this.state.servico)
            })[0]
            detalhes.push({
                gerar_boleto: servico.gerar_boleto,
                credito_debito: servico.credito_debito,
                nome: servico.nome,
                servico_id: servico.id,
                data_vencimento: this.state.vencimento_servico,
                valor: parseFloat(this.state.valor_servico),
                mes_ano: this.state.mes_ano_servico,
                referencia: this.state.referencia_servico
            })
            this.fecharModal()
            this.setState({ descricao_boleto_new: detalhes })

        }
        else {
            alert('Informe o valor cobrado para o serviço')
        }
    }

    excluirServico = (index) => {
        var detalhes = this.state.descricao_boleto_new
        detalhes.splice(index, 1)
        this.setState({ descricao_boleto_new: detalhes })
    }

    servicosInserirBanco = () => {
        var inserir = []
        this.state.descricao_boleto_new.map((d, index) => {
            if (!d.id) {
                inserir.push(index)
            }
            return null
        })
        return inserir
    }

    servicosRemoverBanco = () => {
        var remover = []
        for (var i = 0; i < this.state.descricao_boleto.length; i++) {
            var existe = false
            for (var j = 0; j < this.state.descricao_boleto_new.length; j++) {
                if (this.state.descricao_boleto_new[j].id && this.state.descricao_boleto_new[j].id === this.state.descricao_boleto[i].id) {
                    existe = true
                }
            }
            if (!existe) {
                remover.push(this.state.descricao_boleto[i].id)
            }
        }
        return remover
    }

    calcularValorBoleto = (descricoes) => {
        var valor_boleto = 0
        descricoes.filter((p) => {
            return p.gerar_boleto === 1
        }).map((d) => {
            if (d.credito_debito === 1) {
                valor_boleto += d.valor
            }
            else {
                valor_boleto -= d.valor
            }
            return null
        })
        return valor_boleto
    }

    alterarBoleto = async () => {
        var inserir = this.servicosInserirBanco()
        var remover = this.servicosRemoverBanco()
        var valor_boleto_old = this.calcularValorBoleto(this.state.descricao_boleto)
        var valor_boleto_new = this.calcularValorBoleto(this.state.descricao_boleto_new)
        var descontar = valor_boleto_old - valor_boleto_new

        var cont_inserir = 0
        var cont_remover = 0
        var data = false
        var valor = false
        if (valor_boleto_new > valor_boleto_old) {
            alert(`Novo valor do boleto (${valor_boleto_new.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}) é maior que o valor atual (${valor_boleto_old.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}).\nPortanto, não será possível alterar esse boleto.`)
            this.contrato()
            this.cobrancas()
            this.servicos()
        }
        else {
            if (inserir.length > 0 || remover.length > 0 || moment(this.state.vencimento_boleto_new).utc(false).format('YYYY-MM-DD') !== moment(this.state.vencimento_boleto).utc(false).format('YYYY-MM-DD')) {
                this.setState({ load: true })
                inserir.map(async (i) => {
                    let create = await api_freedom.post('/financeiro/create', {
                        contrato_id: this.props.match.params.contrato_id,
                        imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                        servico_id: this.state.descricao_boleto_new[i].servico_id,
                        data_vencimento: this.state.descricao_boleto_new[i].data_vencimento,
                        valor: this.state.descricao_boleto_new[i].valor,
                        mes_ano: this.state.descricao_boleto_new[i].mes_ano,
                        referencia: this.state.descricao_boleto_new[i].referencia,
                    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                    await api_freedom.post('/boletohasfinanceiro/create', {
                        boleto_id: this.state.descricao_boleto.filter((p) => {
                            return p.id === parseInt(this.props.match.params.parcela_id)
                        })[0].boleto_id,
                        financeiro_id: create.data.data.insertId
                    }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                    cont_inserir++
                    if (cont_inserir === inserir.length && cont_remover === remover.length && data && valor) {
                        this.atualizarPagina()
                    }
                    return null
                })
                remover.map(async (r) => {
                    var descricao_excluir = this.state.descricao_boleto.filter((d) => {
                        return d.id === r
                    })

                    if (descricao_excluir.length > 0) {

                        await api_freedom.post('/boletohasfinanceiro/delete', {
                            boleto_id: descricao_excluir[0].boleto_id,
                            financeiro_id: descricao_excluir[0].id,
                        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                        await api_freedom.post('/financeiro/delete', {
                            id: descricao_excluir[0].id
                        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                        cont_remover++
                        if (cont_inserir === inserir.length && cont_remover === remover.length && data && valor) {
                            this.atualizarPagina()
                        }
                    }
                    else {
                        cont_remover++
                    }
                    return null
                })

                if (moment(this.state.vencimento_boleto_new).utc(false).format('YYYY-MM-DD') !== moment(this.state.vencimento_boleto).utc(false).format('YYYY-MM-DD')) {
                    let atualizar_vencimento = await api_freedom.post('/boleto/atualizarvencimento', {
                        document_number: this.state.descricao_boleto.filter((d) => {
                            return d.id === parseInt(this.props.match.params.parcela_id)
                        })[0].document_number,
                        data_vencimento: moment(this.state.vencimento_boleto_new).utc(false).format('YYYY-MM-DD'),
                    }, {
                        headers: { "authorization": localStorage.getItem('autenticado_freedom') }
                    })

                    if (atualizar_vencimento.data.data.Success === 'false') {
                        alert(atualizar_vencimento.data.data.Message)
                        this.contrato()
                        this.cobrancas()
                        this.servicos()
                    }
                    else {
                        data = true
                        if (cont_inserir === inserir.length && cont_remover === remover.length && data && valor) {
                            this.atualizarPagina()
                        }
                    }
                }
                else {
                    data = true
                    if (cont_inserir === inserir.length && cont_remover === remover.length && data && valor) {
                        this.atualizarPagina()
                    }
                }

                let atualizar_valor = await api_freedom.post('/boleto/atualizarvalor', {
                    document_number: this.state.descricao_boleto.filter((d) => {
                        return d.id === parseInt(this.props.match.params.parcela_id)
                    })[0].document_number,
                    novo_valor: valor_boleto_new,
                    descontar: descontar,
                }, {
                    headers: { "authorization": localStorage.getItem('autenticado_freedom') }
                })

                if (atualizar_valor.data.data.Success === 'false') {
                    alert(atualizar_valor.data.data.Message)
                    this.contrato()
                    this.cobrancas()
                    this.servicos()
                }
                else {
                    valor = true
                    if (cont_inserir === inserir.length && cont_remover === remover.length && data && valor) {
                        this.atualizarPagina()
                    }
                }
                this.setState({ load: false })
            }
            else {
                this.setState({ load: false })
                alert(`Não há alterações no boleto`)
            }
        }
    }

    atualizarPagina = () => {
        alert(`Boleto alterado`)
        this.contrato()
        this.cobrancas()
        this.servicos()
    }

    render() {
        return (
            <>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <label>Total de cobranças: {this.state.descricao_boleto_new.length}</label><br />
                            <label style={{ marginTop: '10px' }}><strong>Vencimento do boleto:</strong></label><br />
                            <input type='date' value={moment(this.state.vencimento_boleto_new).utc(false).format('YYYY-MM-DD')} min={moment(this.state.vencimento_boleto).utc(false).format('YYYY-MM-DD')} onChange={(event) => {
                                this.setState({ vencimento_boleto_new: event.target.value })
                            }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className='button btn__primary'
                                onClick={() => {
                                    this.setState({ servico: 6 })
                                    const id = parseInt(this.props.match.params.parcela_id);
                                    const vencimento = moment(this.state.descricao_boleto.filter((p) => {
                                        return p.id === id
                                    })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

                                    const mes_ano = this.state.descricao_boleto.filter((p) => {
                                        return p.id === id
                                    })[0].mes_ano

                                    this.setState({
                                        vencimento: vencimento,
                                        mes_ano: mes_ano,
                                    })
                                    this.abrirModalIR()
                                }}
                            >
                                Retenção de IR
                            </button>
                            <button className='button btn__primary'
                                onClick={() => {
                                    this.ajustesParcela(parseInt(this.props.match.params.parcela_id))
                                }}
                            >
                                Concessão de serviços
                            </button>
                        </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th># Contrato</th>
                                    <th>Data</th>
                                    <th>Histórico / Descrição</th>
                                    <th>Crédito</th>
                                    <th>Débito</th>
                                    <th>Saldo</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.descricao_boleto_new.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                {this.state.descricao_boleto_new.map(this.listarDescricao)}
                            </tbody>
                        </table>
                        <div style={{ marginTop: '30px', paddingRight: '1vw', float: 'right' }}>
                            <button className='button btn__primary'
                                onClick={() => {
                                    this.alterarBoleto()
                                }}
                            >
                                Alterar boleto
                            </button>
                        </div>
                    </div>
                </div>
                <div className="content-modal" id="modal" style={{ display: 'none' }}>
                    <div className="modal">
                        <div className="modal-head">
                            {this.state.modal_titulo}
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                {this.state.modal_corpo}
                            </div>
                        </div>
                        <div className="modal-foot">
                            {this.state.modal_botoes}
                        </div>
                    </div>
                </div>

                <div className="content-modal" id="modalIR" style={{ display: 'none' }}>
                    <div className="modal" style={{ width: '60%' }}>
                        <div className="modal-head">
                            Serviço Retenção de IR
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                <div style={{ marginTop: '1%' }}>
                                    <div>
                                        <div className='grupo__campo'>
                                            <div>
                                                <label htmlFor="servicoIR">
                                                    Serviço:
                                                </label>
                                                <select
                                                    id="servicoIR"
                                                    readOnly
                                                    onChange={(event) => {
                                                        this.setState({ servico: event.target.value })
                                                    }}
                                                >
                                                    {this.state.servicos.filter((servico) => {
                                                        return servico.automatico !== 1 && servico.id === 6
                                                    }).map((s) => {
                                                        return (
                                                            <option key={s.nome} value={s.id}>{s.nome}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='grupo__campo'>
                                            <div>
                                                <label htmlFor="locadores">Locadores:</label>
                                                <select id="locadores" onChange={this.handleLocadorChange}>
                                                    {this.state.locadores.map((locador, index) => (
                                                        <option key={locador.id} value={locador.id} defaultChecked={index === 0}>
                                                            {locador.nome} ({locador.percentagem}%)
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Valor Aluguel: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        readOnly
                                                        type='text'
                                                        value={this.state.valorAluguelIR}
                                                        onChange={this.handleInputChange}
                                                        placeholder='1.000,00' />
                                                </div>
                                            </div>
                                            {this.state.locadorSelecionado && (
                                                <>
                                                    <div className='grupo__campo'>
                                                        <label style={{ width: '100%' }}>Despesas dedutíveis (ao alterar os valores dedutíveis é necessário refazer o cálculo): </label>
                                                        <br />
                                                        <div className='grupo__campo__form'>
                                                            <input
                                                                type='number'
                                                                value={this.state.despesas_dedutiveis}
                                                                onChange={(e) => this.setState({ despesas_dedutiveis: e.target.value })}
                                                                placeholder='1000'
                                                            />
                                                        </div>
                                                    </div>
                                                    <button
                                                        className='button btn__primary'
                                                        onClick={() => {
                                                            this.state.locadores.forEach((locador) => {
                                                                const resultadoIR = calcularImpostoLocador(locador.percentagem, this.state.valorAluguelIR, this.state.despesas_dedutiveis, locador.nome + "; CPF: " + locador.cpf_cnpj);
                                                                locador.resultadoIR = resultadoIR.resultadoIR;
                                                                locador.aliquotaIR = resultadoIR.aliquotaIR;
                                                                locador.valorPagarIR = resultadoIR.valorPagarIR;
                                                                locador.valorAluguelIR = resultadoIR.valorAluguelIR;
                                                            });
                                                            this.forceUpdate();
                                                        }}
                                                    >
                                                        Calcular com as despesas dedutíveis
                                                    </button>
                                                </>
                                            )}
                                        </>
                                        <hr></hr>
                                    </div>
                                    {this.state.locadorSelecionado && (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flewDirection: 'row',
                                                }}
                                            >
                                                <div className='grupo__campo'>
                                                    <label style={{ width: '100%' }}>Porcentagem: </label>
                                                    <br />
                                                    <div className='grupo__campo__form'>
                                                        <input
                                                            readOnly
                                                            type='text'
                                                            value={this.state.locadorSelecionado.percentagem.toFixed(2).replace('.', ',') + "%"}
                                                            onChange={this.handleInputChange}
                                                            placeholder='100%'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='grupo__campo'>
                                                    <label style={{ width: '100%' }}>Alíquota: </label>
                                                    <br />
                                                    <div className='grupo__campo__form'>
                                                        <input
                                                            readOnly
                                                            type='text'
                                                            value={this.state.locadorSelecionado.aliquotaIR.toFixed(2).replace('.', ',') + "%"}
                                                            onChange={this.handleInputChange}
                                                            placeholder='100%'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Valor IR: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        type="number"
                                                        id="valorPagarIR"
                                                        value={this.state.locadorSelecionado.valorPagarIR}
                                                        onChange={this.handleInputChange}
                                                        placeholder='100'
                                                    />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Descrição: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        type="text"
                                                        id="resultadoIR"
                                                        value={this.state.locadorSelecionado.resultadoIR}
                                                        onChange={this.handleInputChange}
                                                        placeholder='IR...'
                                                    />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Data de vencimento: </label><br />
                                                <div className='grupo__campo__form'>
                                                    <input type='date' value={this.state.vencimento} readOnly />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Mês e ano de cobrança: </label><br />
                                                <div className='grupo__campo__form'>
                                                    <input type='month' value={this.state.mes_ano} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-foot">
                            <button
                                className='button btn__primary--outline'
                                onClick={this.fecharModalIR}
                            >
                                Fechar
                            </button>
                            <button
                                className='button btn__primary'
                                onClick={() => {
                                    const detalhes = this.state.descricao_boleto_new
                                    const servico = this.state.servicos.filter((s) => {
                                        return s.id === parseInt(this.state.servico)
                                    })[0]
                                    this.state.locadores.forEach((locador) => {
                                        if (parseFloat(String(locador.valorPagarIR).replace(',', '.')) > 0) {
                                            detalhes.push({
                                                gerar_boleto: servico.gerar_boleto,
                                                credito_debito: servico.credito_debito,
                                                nome: servico.nome,
                                                servico_id: servico.id,
                                                data_vencimento: this.state.vencimento,
                                                valor: parseFloat(String(locador.valorPagarIR).replace(',', '.')),
                                                mes_ano: this.state.mes_ano,
                                                referencia: locador.resultadoIR
                                            })
                                        }
                                    })
                                    this.fecharModalIR()
                                    this.setState({ descricao_boleto_new: detalhes })
                                }}
                            >
                                Salvar Cálculo
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="content-modal"
                    style={{ display: this.state.load ? "flex" : "none" }}
                >
                    <div className="modal" style={{ width: "auto" }}>
                        <img
                            src={require("../assets/imgs/loading.gif")}
                            alt=""
                            style={{ height: "200px" }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default AlterarBoleto;
