import React from "react"
import jwt_decode from 'jwt-decode'
import { api_freedom } from "../services/apis"
import jwt from 'jsonwebtoken'
import moment from 'moment'

class ImprimirRelatorioRepassePage extends React.Component {
    constructor(props){
        super(props)

        let data = jwt_decode(this.props.match.params.data, "PRINTER")

        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            servicos: data.servicos,
            dataInicio: data.datainicio,
            dataFim: data.datafim,
            mensagem: 'Carregando ....',
            loading: false,
            servicoslist: [],
            dados: [],
            inPrint: false
        }
        
        this.getContratos()
    }

    twoValue = (d) => {
        if (!d) return d

        if (d.length == 1) return `0${d}`

        return d
    }

    convertMesAno = (d) => {
        if (!d) return d

        let [ano, mes] = d.split("-")

        return `${mes}/${ano}`
    }

    getContratos = async () => {
        this.setState({ loading: true })
        const response = await api_freedom.post("/contrato/listbytime", {
            datainicio: this.state.dataInicio,
            datafim: this.state.dataFim,
            servicos: this.state.servicos,
            imob: this.state.dados_login.imobiliaria_id
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(response.status === 200){
            if(response.data.data.length > 0){
                this.setState({
                    dados: response.data.data,
                    servicoslist: response.data.servicos,
                    loading: false
                })
            } else {
                this.setState({ 
                    loading: false,
                    mensagem: 'Nenhum registro encontrado.'
                })
            }
        } else{
            this.setState({ 
                loading: false,
                mensagem: 'Nenhum registro encontrado.'
            })
        }

    }

    fotprint = () => {
        this.setState({inPrint: true})

        setTimeout(() => {
            window.print()

            this.setState({inPrint: false})
        }, 350);
    }

    genCSV = () => {
        let csv = 'Contrato,Ativo,Valor boleto,Vencimento boleto,Boleto Pago,';

        for (const i of this.state.servicoslist) {
            if ([2, 17].includes(i.id)) continue

            csv += i.nome + ","
        }

        csv += "Taxa de Administração,Taxa contrato,Repasse de aluguel,Competência"
        csv += "\n"
    
        for (const i of this.state.dados) {
            csv += `${i.contrato_id},${i.ativo == 1 ? "Sim" : "Não"},"${i.boleto_valor ? i.boleto_valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '---'}",`
            csv += `${i.boleto_data_vencimento ? moment(i.boleto_data_vencimento ).format('DD/MM/YYYY') : "---"},${i.pago ? "Sim dia " + moment(i.pago ).format('DD/MM/YYYY').toString() : "Não"},`
            
            for (const j of this.state.servicoslist) {
                if ([2, 17].includes(j.id)) continue
                csv += `"${i.servicos[j.id] ? i.servicos[j.id].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '---'}",`
            }

            csv += `"${i.servicos[17] ? i.servicos[17].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '---'}",`
            csv += `${i.perc_taxa_administracao || 0}%,`
            csv += `"${i.servicos[2] ? i.servicos[2].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '---'}",`
            csv += `${this.convertMesAno(i.mes_ano)},`
            csv += "\n"
        }

        csv = csv.slice(0, csv.length - 1)
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'planilha.csv';
        hiddenElement.click();
    }

    showMultipleServices(e, id){
        let filterd = e.servicos.filter(x => x.servico == id)
        
        return filterd ? (
            <td>
                {
                    filterd.map(j => (
                        <p>
                            {j.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </p>
                    ))
                }
            </td>
        ) : '---';
    }

    sumColumn(id){
        let s = 0;

        for (const i of this.state.dados) {
            let servico = i.servicos.filter(x => x.servico == id)

            for (const j of servico) {
                s += j.valor
            }
        }

        return s;
    }

    render() {
        return <>
            <style type="text/css" media="print">
                @page {'{ size: landscape; }'}

                table, td, tr, th {'{font-size: 9px !important}'}
            </style>

            <div>
                <div>
                    <button 
                        className='tohidden btn__primary button'
                        onClick={() => {
                            let link = jwt.sign({ 
                                pesquisa: {
                                    data_fim: this.state.dataFim,
                                    data_inicio: this.state.dataInicio,
                                    servicos: this.state.servicos,
                                    imobiliaria_id: this.state.dados_login.imobiliaria_id
                                }
                            }, 'PRINTER')
                            window.open("/freedom/relatoriorepassespdf/" + link, "_blank")
                        }}
                        disabled={this.state.loading ? true : false}
                    >
                        Imprimir relatório
                    </button>
                    <button className='tohidden btn__primary button ml-2' onClick={this.genCSV}>Gerar CSV</button>

                    <p className="relatorio-title mt-4">Relatório de repasses</p>

                </div>
                <div className='table mt-4'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>
                                    <div class="thead__item">
                                        <label># Contrato</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Ativo</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Valor boleto</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Vencimento boleto</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Boleto Pago</label>
                                    </div>
                                </th>

                                {
                                    this.state.servicoslist.map(e => (
                                        [2, 17].includes(e.id) ? (null) : (
                                            <th>
                                                <div class="thead__item">
                                                    <label>{e.nome}</label>
                                                </div>
                                            </th>
                                        )
                                    ))
                                }
                                <th>
                                    <div class="thead__item">
                                        <label>Taxa de Administração</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Taxa contrato</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Repasse de aluguel</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Data repasse</label>
                                    </div>
                                </th>
                                <th>
                                    <div class="thead__item">
                                        <label>Competência</label>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.loading || this.state.dados.length === 0 ? (
                                    <tr><th className='text-center' colSpan={12}>{this.state.mensagem}</th></tr>
                                ) : (
                                    this.state.dados.map(e => (
                                        <tr>
                                            <td>{e.contrato_id}</td>
                                            <td>{e.ativo == 1 ? "Sim" : "Não"}</td>
                                            <td>{e.boleto_valor ? parseFloat(e.boleto_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "---"}</td>
                                            <td>{e.boleto_data_vencimento ? moment(e.boleto_data_vencimento ).format('DD/MM/YYYY') : "---"}</td>
                                            <td>{e.pago ? "Sim, dia " + moment(e.pago ).format('DD/MM/YYYY').toString() : "Não"}</td>
                                            {
                                                this.state.servicoslist.map(i => (
                                                    [2, 17].includes(i.id) ? (null) : (
                                                        this.showMultipleServices(e, i.id)
                                                    )
                                                ))
                                            } 
    
                                            <td>{e.servicos.find(x => x.servico == 17) ? parseFloat(e.servicos.find(x => x.servico == 17).valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "---"}</td>
                                            <td>{e.perc_taxa_administracao || 0}%</td>
                                            <td>{e.servicos.find(x => x.servico == 2) ? parseFloat(e.servicos.find(x => x.servico == 2).valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "---"}</td>
                                            <td>{e.payment_date ? moment(e.payment_date).format('DD/MM/YYYY') : '---'}</td>
                                            <td>{this.convertMesAno(e.mes_ano)}</td>
                                        </tr>
                                    ))                                    
                                )
                            }
                        </tbody>
                        {
                            this.state.loading || this.state.dados.length > 0 ? (
                                <tfoot>
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        {
                                            this.state.servicoslist.map(i => (
                                                [2, 17].includes(i.id) ? (null) : (
                                                    <td>
                                                        <strong>{parseFloat(this.sumColumn(i.id)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                                    </td>
                                                )
                                            ))
                                        }

                                        <td>
                                            <strong>{parseFloat(this.sumColumn(17)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                        </td>
                                        <td></td>
                                        <td>
                                            <strong>{parseFloat(this.sumColumn(2)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            ) : null
                        }
                    </table>
                </div>    
            </div>
        </>
    }
}

export default ImprimirRelatorioRepassePage