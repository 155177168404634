import React, { useEffect, useState } from "react";
import moment from "moment";

const ModalLogTecnico = (props) => {

  const [log, setLog] = useState(props?.log);


  useEffect(() => {
  }, []);


  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div className="modal-logs">

          <div className="modal-head">Log Técnico</div>
          <div className="modal-body">
            <div className="modal-scroll-logs">
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div class="thead__item">
                          <label>Comando Executado</label>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="tbody__text">{log.sqlQuery}</td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>
                        <div class="thead__item">
                          <label>Antes da Execução</label>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {
                        <td className="tbody__text w-100">{
                          JSON.parse(log.historic.replaceAll("[", "").replaceAll("]", "")) !== null ? Object.keys(JSON.parse(log.historic.replaceAll("[", "").replaceAll("]", ""))).map((item, key) => 
                          <div>{`${item}: ${JSON.parse(log.historic.replaceAll("[", "").replaceAll("]", ""))[item]}`}</div>
                        ) : "null"}
                        </td>
                      }
                    </tr>
                    {/* <td className="tbody__text">{log.historic.replaceAll("[","").replaceAll("{","").replaceAll(',','\r')}</td> */}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={() => props.fechar()}
            >
              Fechar
            </button>
            {/* <button
          className="button btn__primary"          
          style={{ width: "112px" }}
          onClick={gerarCSV}
        >
          Gerar CSV
        </button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalLogTecnico