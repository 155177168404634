import React from "react";
import { api_freedom } from "../services/apis";
import jwt_decode from 'jwt-decode';
import jwt from "jsonwebtoken";
import moment from "moment";
import Swal from "sweetalert2";

class RelatorioRescisaoPage extends React.Component {
   constructor(props) {
      super(props)

      if (!localStorage.getItem('autenticado_freedom')) {
         props.history.push('/')
      }

      this.state = {
         dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
         years: new Date().getFullYear(),
         months: new Date().getMonth() + 1,
         rescisoes: [],
         contratosComRescisao: [],
         mensagem: 'Carregando ....',
         total_contratos: 0,
         total_rescisoes: 0,
         quantidade_paginas: 1,
         buscar_contrato: '',
         buscar_tipo: '',
         buscar_dataInicio: '',
         buscar_dataTermino: '',
         buscar_locatario: '',
         buscar_dataFim: '',
         buscar_endereco: '',

         pagina: 1,
         por_pagina: 10,
         dataInicio: '',
         dataFim: '',
         ativos: '',

         loading: false,
         inPrint: false,
      }

      this.getDados()
   }

   componentDidMount(){
      this.getDados();
   }

   async componentDidUpdate(prevProps, prevState) {

      if (prevState.pagina !== this.state.pagina || prevState.ativos !== this.state.ativos || prevState.por_pagina !== this.state.por_pagina) {
         await this.getDados();
      }

   }

   getDados = async () => {
      this.setState({
         loading: true,
         mensagem: 'Carregando ....',
      })
      try {
         const response = await api_freedom.get('/contrato/relatoriorescisao', {
            headers: {'authorization': localStorage.getItem('autenticado_freedom')},
            params: {
               imobiliaria_id: this.state.dados_login.imobiliaria_id,
               pagina: this.state.pagina,
               limite: this.state.por_pagina,
               ativo: this.state.ativos,
               contrato_id: this.state.buscar_contrato,
               rua_imovel: this.state.buscar_endereco,
               locatario: this.state.buscar_locatario,
               data_inicio: this.state.buscar_dataInicio,
               data_rescisao: this.state.buscar_dataRescisao,
               data_termino: this.state.buscar_dataFim,
               tipo: this.state.buscar_tipo,
               periodo: this.state.dataInicio && this.state.dataFim ? `${this.state.dataInicio};${this.state.dataFim}` : ''
            }
         })

         if (response.data.data.data.indexOf('TOKEN') === -1) {

            this.setState({
               rescisoes: response.data.data.data,
               contratosComRescisao: this.filtrar(response.data.data.data),
               mensagem: 'Nenhum registro encontrado.',
               total_rescisoes: response.data.data.total,
               quantidade_paginas: response.data.data.total_paginas,
            })
         }

         this.setState({
            loading: false,
         })
      } catch (error) {
         console.log(error.message);
      }
   }

   disable() {
      if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
         return true
      } else {
         return false
      }
   }

   // qualquer coisa fazer a filtragem dentro de "getDados"
   filtrar = (c) => {
      return c.filter(rescindidos => rescindidos.data_rescisao !== null)
   }

   listarContratos = (record, index) => {
      return (
         <tr>
            <td>{record.id}</td>
            <td>{record.tipo_locacao}</td>
            <td>
               <div>{moment(record.data_inicio).format('DD/MM/YYYY')}</div>
            </td>
            <td>
               <div>{moment(record.data_fim).format('DD/MM/YYYY')}</div>
            </td>
            <td>
               {
                  record.pessoas.filter((p) => p.tipo === 'Locatário').map((locatario) => (
                     <div>{locatario?.nome}</div>
                  ))
               }
            </td>
            <td>{record.endereco}</td>
            <td>{moment(record.data_rescisao).format('DD/MM/YYYY')}</td>
            <td></td>
         </tr>
      )
   }

   relatorioToPDF = async (linkDemonstrativo) => {

      Swal.fire({
         title: 'Processando...',
      })
      Swal.showLoading()

      let { data } = await api_freedom.get(`demonstrativo/relatorioRescisao/pdf/${linkDemonstrativo}`)

      var hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/html' + ';base64,' + encodeURIComponent(data.data);
          hiddenElement.target = '_blank';
          hiddenElement.download = 'Relatorio_Rescisao.pdf';
          hiddenElement.click();

      Swal.close()

   }

   twoNumber = (d) => {
      return d.toString().length == 1 ? `0${d}` : d
   }

   changeDate = (e) => {
      this.setState({
         months: e.split('-')[1],
         years: e.split('-')[0],
      })

      this.state.months = e.split('-')[1]
      this.state.years = e.split('-')[0]

      this.getDados();
   }
   
   onChangePeriodo = () => {
      setTimeout(() => {
         if (this.state.dataInicio.length === 10 && this.state.dataFim.length === 10) {
            if (this.state.dataInicio < this.state.dataFim) {
               const dataInicial = moment(this.setState.dataInicio);
               const dataFinal = moment(this.setState.dataFim);
   
               if (dataInicial.isValid() && dataFinal.isValid()) {
                  this.getDados()
               }
            } else {
               alert('Data inicial deve ser menor que a data final.')
               this.setState({
                  dataFim: '',
               })
            }
         }
      }, 500)
   }

   fotprint = () => {
      this.setState({inPrint: true})

      setTimeout(() => {
         window.print()

         this.setState({inPrint: false})
      }, 350);
   }

   genCSV = () => {
      let csv = 'ID;Contrato;Locatário;Data de Rescisão;\n';

      for (const i of this.filtrar(this.state.rescisoes)) {
         const locatario = i.pessoas.find(element => element.tipo === 'Locatário')
         csv += `"${i.id}";"${i.id}";"${locatario.id}";"${i.data_rescisao}"\n`
      }

      csv = csv.slice(0, csv.length - 1)

      var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'relatorio_rescisao.csv';
        hiddenElement.click();
   }

   limparState = () => {
      this.setState({
         buscar_dataFim: '',
         buscar_dataInicio: '',
         buscar_dataRescisao: '',
      })
   }

   render() {
      return <>
         <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
               <h2 style={{display: 'none'}} className="toshow">Relatório de rescisão</h2>
               <div>
                  <button className="tohidden btn__primary button" onClick={(e) => {
                     var linkDemonstrativo = jwt.sign({
                        ano: this.state.ano,
                        contrato_id: e.contrato_id,
                        pessoa_id: e.pessoa_id,
                        pesquisa: {
                           imobiliaria_id: this.state.dados_login.imobiliaria_id,
                           pagina: this.state.pagina,
                           limite: this.state.por_pagina,
                           contrato_id: this.state.buscar_contrato,
                           tipo: this.state.buscar_tipo,
                           locatario: this.state.buscar_locatario,
                           endereco: this.state.buscar_endereco,
                           data_inicio: this.state.buscar_dataInicio,
                           data_fim: this.state.buscar_dataFim,
                           data_rescisao: this.state.buscar_dataRescisao,
                           data_termino: this.state.buscar_dataTermino,
                           periodo: this.state.dataInicio && this.state.dataFim ? `${this.state.dataInicio};${this.state.dataFim}` : ''
                        }
                     }, 'RESCISAO')
                     window.open(`/freedom/relatoriorescisaopdf/${linkDemonstrativo}`, '_blank');
                  }}>Imprimir relatório</button>
                  <button className="tohidden btn__primary button" onClick={this.genCSV}>Gerar CSV</button>
                  {/* <br />
                  <div className="grupo__campo__form grupo__campo--input--select">
                     <input type="month" value={this.state.years + '-' + this.twoNumber(this.state.months)} onChange={e => this.changeDate(e.target.value)} />
                  </div> */}
               </div>

               <div>
                  <div>
                     <label style={{margin: '10px 0px 0px 0px'}}>Por página</label>
                     <select
                        className="ml-2"
                        value={this.state.por_pagina}
                        onChange={(i) => {
                           this.setState({ por_pagina: i.target.value, pagina: 1 })
                        }}
                     >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                     </select>
                  </div>
               </div>
            </div>

            <div className="d-flex justify-content-between">
               <div>
                  <label style={{margin: '10px 0px 0px 10px'}}>Total de Rescisões: {this.state.total_rescisoes}</label>
               </div>
               <div>
                  <label>Página: {this.state.quantidade_paginas > 0 ? this.state.pagina : 0} de {this.state.quantidade_paginas}</label>
               </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
               <div style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 8}}>
                  <p className="relatorio-title">Relatório de rescisões</p>
               </div>
               <div className="d-flex tohidden">
                  <div className="grupo__campo d-flex align-items-center">
                     <label className="pr-0" style={{width: '100%', whiteSpace: 'pre'}}>Data Início: </label><br />
                     <div className="grupo__campo__form grupo__campo--input--select">
                        <input type="date" onChange={(event) => {
                           this.setState({dataInicio: event.target.value, pagina: 1})
                           if (event.target.value === '') {
                              this.getDados()
                           }
                           this.onChangePeriodo()
                        }} />
                     </div>
                  </div>
                  <div className="grupo__campo d-flex align-items-center">
                     <label className="pr-0" style={{width: '100%', whiteSpace: 'pre'}}>Data Fim: </label><br />
                     <div className="grupo__campo__form grupo__campo--input--select">
                        <input type="date" onChange={(event) => {
                           this.setState({dataFim: event.target.value, pagina: 1})
                           if (event.target.value === '') {
                              this.getDados()
                           }
                           this.onChangePeriodo()
                        }} />
                     </div>
                  </div>
               </div>
            </div> 

            <div className="table mt-4">
               <table className="table">
                  <thead>
                     <tr>
                        <th>
                           <div class='thead_item'>
                              <label for='fct'># Contrato</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="fct"
                                    type="number"
                                    value={this.state.buscar_contrato}
                                    onChange={(event) => {this.setState({buscar_contrato: event.target.valueAsNumber, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class='thead_item'>
                              <label for='ftp'>Tipo</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="ftp"
                                    type="text"
                                    value={this.state.buscar_tipo}
                                    onChange={(event) => {this.setState({buscar_tipo: event.target.value, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class='thead_item'>
                              <label for='fin'>Início Contrato</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="fin"
                                    type="date"
                                    value={this.state.buscar_dataInicio}
                                    onChange={(event) => {this.setState({buscar_dataInicio: event.target.value, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class='thead_item'>
                              <label for='ftm'>Término Contrato</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="ftm"
                                    type="date"
                                    value={this.state.buscar_dataFim}
                                    onChange={(event) => {this.setState({buscar_dataFim: event.target.value, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class='thead_item'>
                              <label for='fcl'>Locatário</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="fcl"
                                    type="text"
                                    value={this.state.buscar_locatario}
                                    onChange={(event) => {this.setState({buscar_locatario: event.target.value, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class='thead_item'>
                              <label for='fim'>Endereço Imóvel</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="fim"
                                    type="text"
                                    value={this.buscar_endereco}
                                    onChange={(event) => {this.setState({buscar_endereco: event.target.value, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class='thead_item'>
                              <label for='fdr'>Data Rescisão</label>
                              <div class='thead__item__input'>
                                 <i class='fas fa-search'></i>
                                 <input
                                    id="fdr"
                                    type="date"
                                    value={this.state.buscar_dataRescisao}
                                    onChange={(event) => {this.setState({buscar_dataRescisao: event.target.value, pagina: 1})}}
                                 />
                              </div>
                           </div>
                        </th>
                        <th>
                           <div class="thead__item mt-auto">
                              <button
                                 className="button btn__primary"
                                 style={{ marginTop: 0 }}
                                 onClick={() => this.getDados()}
                              >
                                 <i style={{ fontSize: "0.9em" }} className="fas fa-search"/>
                                 {" "}Pesquisar
                              </button>
                           </div>
                        </th>
                     </tr>
                  </thead>

                  <tbody>
                     {
                        this.state.loading ? (
                           <tr><th className="text-center" colSpan='8'>{this.state.mensagem}</th></tr>
                        ) :
                        this.state.contratosComRescisao.length === 0 ? (
                           <tr><th className="text-center" colSpan='8'>{this.state.mensagem}</th></tr>
                        ) : this.state.contratosComRescisao.map(this.listarContratos)
                     }
                  </tbody>
                  
               </table>
            </div>

            {
               this.state.inPrint ? (null) : (
                  this.state.contratosComRescisao.length > 0 &&
                  <div className="pagination">
                     {this.state.pagina === 1 ? null : (
                        <div className="pagination__arrow" onClick={() => {
                           this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                        }}>
                           {'<'}
                        </div>
                     )}
                     {this.state.pagina === 1 || this.state.pagina === 2 ? null : (
                        <div className="pagination__arrow ml-4" onClick={() => {
                           this.setState({pagina: 1})
                        }}>
                           {'1'}
                        </div>
                     )}
                     
                     <div className="pagination__numbers">
                        {
                           [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                              if ( (this.state.pagina === 1 && index + 1 < 4) ||
                                   (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                   (index + 1 === this.state.pagina - 1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                              ) {
                                 return (
                                    <p className={this.state.pagina === index + 1 ? 'pagination__index' : ''} onClick={() => {
                                       this.setState({pagina: index + 1})
                                    }}>
                                       {index + 1}
                                    </p>
                                 )
                              } else {
                                 return null
                              }
                           })
                        }
                     </div>

                     {this.state.pagina === this.state.quantidade_paginas || this.state.pagina === (this.state.quantidade_paginas - 1) ? null : (
                        <div className="pagination__arror mr-4" onClick={() => {
                           this.setState({pagina: this.state.quantidade_paginas})
                        }}>
                           {this.state.quantidade_paginas}
                        </div>
                     )}
                     {this.state.pagina === this.state.quantidade_paginas ? null : (
                        <div className="pagination__arrow" onClick={() => {
                           this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                        }}>
                           {'>'}
                        </div>
                     )}
                  </div>
               )
            }
         </div>
      </>
   }
}

export default RelatorioRescisaoPage