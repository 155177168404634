import React from 'react';
import jwt from 'jsonwebtoken'

class ContratosImportadosPage extends React.Component {
    constructor(props){
        super(props);

        let cont = []

        jwt.verify(localStorage.getItem("dataleakitem"), 'DATAS', (err, decoded) => {
            if (err){
                return window.location = '/freedom/contratos'
            }
            else{
                cont = decoded.data
            }
        })

        this.state = {
            contratos: cont
        }
    }

    render(){
        return (
            <>
                <div>
                    <div>
                        <p className="relatorio-title">Contratos importados</p>
                        
                    </div>
                    <div className='table mt-2'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    {
                                        this.state.contratos[0].map(e => (
                                            <th>
                                                {e}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contratos.slice(1, this.state.contratos.length).map(e => (
                                        <tr>
                                            {
                                                e.map(j => (
                                                    <td>
                                                        {
                                                            j == null || j == undefined ? '---' : !isNaN(new Date(j).getDate()) && j.toString().includes('-') ? new Date(j).toLocaleDateString() : j.toString()
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

export default ContratosImportadosPage