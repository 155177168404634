
import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import Swal from "sweetalert2"

class RelatorioInadimplencia extends React.Component {

    constructor(props) {
        super(props)
        
        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 

        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            inadimplencia: [],
            mensagem: 'Carregando ....',
            total_inadimplencia: 0,
            buscar_contrato: '',
            buscar_tipo: '',
            buscar_prazo_contrato: '',
            buscar_vencimento: '',
            buscar_imovel: '',
            buscar_proprietarios: '',
            buscar_endereco: '',
            buscar_locatario: '',
            buscar_inicio: '',
            buscar_termino: '',
            buscar_aluguel: '',
            buscar_valor_boleto: '',
            pagina: 1,
            dataInicio: '',
            dataFim: '',
            quantidade_paginas: 1,
            loading: true,
            por_pagina: 10,

            inPrint: false
        }
     

    }

    componentDidMount(){
        this.listarInadimplentes();
    }

    async componentDidUpdate(prevProps,prevState){
        
        if (prevState.pagina !== this.state.pagina || prevState.por_pagina !== this.state.por_pagina) {
            await this.listarInadimplentes();
        }

    }
  
    listarInadimplentes = async () => {
        this.setState({
            loading: true,
            mensagem: 'Carregando ....',
        })
        try{
            let inadimplencia = await api_freedom.get(`/boleto/relatorioinadimplencia/`, {
                params: {
                    imobiliaria_id: this.state.dados_login.imobiliaria_id,
                    pagina: this.state.pagina,
                    limite: this.state.por_pagina,
                    contrato_id: this.state.buscar_contrato,
                    tipo: this.state.buscar_tipo,
                    vencimento: this.state.buscar_vencimento,
                    locatario: this.state.buscar_locatario,
                    endereco: this.state.buscar_endereco,
                    valor_boleto: this.state.buscar_valor_boleto,
                    data_inicio: this.state.buscar_dataInicio,
                    data_fim: this.state.buscar_dataFim,
                    periodo: this.state.dataInicio && this.state.dataFim ? this.state.dataInicio+';'+this.state.dataFim : ''

                },
                headers: {"authorization": localStorage.getItem('autenticado_freedom')}
            })
            if(inadimplencia.data.data.data.indexOf('TOKEN') === -1){
                this.setState({ 
                    inadimplencia: inadimplencia.data.data.data, 
                    mensagem: 'Nenhum registro encontrado.',
                    total_inadimplencia: inadimplencia.data.data.total,
                    quantidade_paginas: inadimplencia.data.data.total_paginas
                })
                // this.quantidade_paginas = Math.ceil(inadimplencia.data.total_inadimplencia / 10) 
    
                // for(var i = 2; i <= Math.ceil(inadimplencia.data.total_inadimplencia / 10); i++){
                //     let inadimplencia_page = await api_freedom.get(`/boleto/relatorioinadimplencia/${this.state.dados_login.imobiliaria_id}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
                //     this.setState({inadimplencia: this.state.inadimplencia.concat(inadimplencia_page.data.data)})
                // }
            }
            this.setState({loading: false})
        }
        catch(error){
            console.log(error.message)
        }
    }

    disable() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    listarOsInadimplentes = (record, index) => {
        // const nome = JSON.parse(record.requisicao_fitbank).CustomerName
        
        return (
            <tr>
                <td>{record.contrato_id}</td>
                <td>{record.tipo_locacao}</td>
                <td>
                    <div>{moment(record.data_inicio).format('DD/MM/YYYY')}</div>
                </td>
                <td>
                    <div>{moment(record.data_fim).format('DD/MM/YYYY')}</div>
                </td>
                <td>{record.locatario_nome}</td>
                <td>{record.imovel_endereco}</td>
                <td>{moment(record.data_vencimento).format('DD/MM/YYYY')}</td>
                <td>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td></td>
            </tr>
        )
    }

    filtrar = (inadimplencia) => {
        var filtro = inadimplencia.filter((c) => {
            return this.filtrarInadimplentes(c)
        })
    
        this.quantidade_paginas = Math.ceil(filtro.length / 10)
    
        return filtro
    }

    filtrarInadimplentes = (inadimplencia) => {
        return this.state.dataFim ? moment(inadimplencia.data_vencimento).format('YYYY-MM-DD') >= this.state.dataInicio && moment(inadimplencia.data_vencimento).format('YYYY-MM-DD') <= this.state.dataFim : inadimplencia
    }

    relatorioToPDF = async (linkDemonstrativo) => {

        Swal.fire({
            title: 'Processando...',
        })
        Swal.showLoading()


        let {data} = await api_freedom.get(`demonstrativo/relatorioinadimplencia/pdf/${linkDemonstrativo}`)

        var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/html'+ ";base64," + encodeURIComponent(data.data);
            hiddenElement.target = '_blank';
            hiddenElement.download = `Relatorio_Inadimplencia.pdf`;
            hiddenElement.click();

        Swal.close()

    }

    onChangePeriodo = () => {
        setTimeout(() => {
            if(this.state.dataInicio.length === 10 && this.state.dataFim.length === 10){
                const dataInicial = moment(this.state.dataInicio)
                const dataFinal = moment(this.state.dataFim)
                console.log(dataInicial.isValid(), dataFinal.isValid());
                if(dataInicial.isValid() && dataFinal.isValid()){
                    this.listarInadimplentes()
                }
            }
        }, 500);
    }

    fotprint = () => {
        this.setState({inPrint: true})

        setTimeout(() => {
            window.print()

            this.setState({inPrint: false})
        }, 350);
    }

    genCSV = () => {
        let csv = '"Contrato","Locatário","Vencimento do boleto","Valor do boleto"\n';

        for (const i of this.filtrar(this.state.inadimplencia)) {
            let nome = JSON.parse(i.requisicao_fitbank).CustomerName

            csv += `"${i.contrato_id}","${nome}","${moment(i.data_vencimento).format('DD/MM/YYYY')}","${i.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}",\n`
        }

        csv = csv.slice(0, csv.length - 1)
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'relatorio_inadimplentes.csv';
        hiddenElement.click();
    }

    render() {
        return (
            <>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                            <button className='tohidden btn__primary button' onClick={(e) => {
                                var linkDemonstrativo = jwt.sign({ 
                                    ano: this.state.ano,
                                    contrato_id: e.contrato_id,
                                    pessoa_id: e.pessoa_id,
                                    pesquisa: {
                                        imobiliaria_id: this.state.dados_login.imobiliaria_id,
                                        pagina: this.state.pagina,
                                        limite: this.state.por_pagina,
                                        contrato_id: this.state.buscar_contrato,
                                        tipo: this.state.buscar_tipo,
                                        vencimento: this.state.buscar_vencimento,
                                        locatario: this.state.buscar_locatario,
                                        endereco: this.state.buscar_endereco,
                                        valor_boleto: this.state.buscar_valor_boleto,
                                        data_inicio: this.state.buscar_dataInicio,
                                        data_fim: this.state.buscar_dataFim,
                                        periodo: this.state.dataInicio && this.state.dataFim ? this.state.dataInicio+';'+this.state.dataFim : ''
                                    }
                                }, 'INADIMPLENTES')
                                // this.relatorioToPDF(linkDemonstrativo, e);
                                window.open(`/freedom/relatorioinadimplenciapdf/${linkDemonstrativo}`, '_blank');
                            }}>Imprimir relatório</button>
                            {/* <button className='tohidden btn__primary button' onClick={this.fotprint}>Imprimir relatório</button> */}
                            <button className='tohidden btn__primary button ml-2' onClick={this.genCSV}>Gerar CSV</button>
                        </div>
                        <div>
                            <div>
                                <label style={{margin: '10px 0px 0px 10px'}}>Por página</label>
                                <select className="ml-2" 
                                    value={this.state.por_pagina}
                                    onChange={(i) => {
                                        this.setState({ por_pagina: i.target.value, pagina: 1 })
                                    }}
                                >
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>                            
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <div>
                            <label style={{margin: '10px 0px 0px 10px'}}>Total de Inadimplentes: {this.state.total_inadimplencia}</label>                         
                        </div>
                        <div>
                            <label>Pagina: {this.state.quantidade_paginas > 0 ? this.state.pagina : 0} de {this.state.quantidade_paginas}</label>
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                        <div style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 8}}>
                            <p className="relatorio-title">Relatório de inadimplentes</p>
                        </div>
                        <div className="d-flex tohidden">
                            <div className='grupo__campo d-flex align-items-center'>
                                <label className="pr-0" style={{width: '100%', whiteSpace: 'pre'}}>Data Início: </label><br/>
                                <div className='grupo__campo__form grupo__campo--input--select'>
                                    <input type='date' onChange={(event) => {
                                        this.setState({dataInicio: event.target.value, pag: 1})
                                            console.log(event.target.value);
                                        if(event.target.value === ''){
                                            this.listarInadimplentes()
                                        }
                                        this.onChangePeriodo()
                                    }}/>
                                </div>    
                            </div>
                            <div className='grupo__campo d-flex align-items-center'>
                                <label className="pr-0" style={{width: '100%', whiteSpace: 'pre'}}>Data Fim: </label><br/>
                                <div className='grupo__campo__form grupo__campo--input--select'>
                                    <input type='date' onChange={(event) => {
                                        this.setState({dataFim: event.target.value, pag: 1})
                                        if(event.target.value === ''){
                                            this.listarInadimplentes()
                                        }
                                        this.onChangePeriodo()
                                    }}/>
                                </div>    
                            </div>
                        </div>    
                    </div>
                    
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label># Contrato</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='text' value={this.state.buscar_contrato} onChange={(event) => {this.setState({buscar_contrato: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Tipo</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='text' value={this.state.buscar_tipo} onChange={(event) => {this.setState({buscar_tipo: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Início Contrato</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='date' value={this.state.buscar_dataInicio} onChange={(event) => {this.setState({buscar_dataInicio: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Término Contrato</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='date' value={this.state.buscar_dataFim} onChange={(event) => {this.setState({buscar_dataFim: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Locatário</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='text' value={this.state.buscar_locatario} onChange={(event) => {this.setState({buscar_locatario: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Endereço Imóvel</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='text' value={this.state.buscar_endereco} onChange={(event) => {this.setState({buscar_endereco: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Vencimento do boleto</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fin" type="date" value={this.state.buscar_vencimento} onChange={e => this.setState({buscar_vencimento: e.target.value, pagina: 1})}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor do boleto</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='text' value={this.state.buscar_valor_boleto} onChange={(event) => {this.setState({buscar_valor_boleto: event.target.value, pagina: 1})}}/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item mt-auto">
                                            {/* <label></label> */}
                                            {/* <div class="thead__item__input">
                                            </div> */}
                                            <button
                                                className="button btn__primary"
                                                style={{ marginTop: 0 }}
                                                onClick={() => this.listarInadimplentes()}
                                                >    
                                                <i style={{ fontSize: "0.9em" }} className="fas fa-search" />
                                                {" "}Pesquisar
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.loading ? (
                                        <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>
                                    ) : 
                                    this.state.inadimplencia.length === 0 ? (
                                        <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>
                                    ) : this.state.inadimplencia.map(this.listarOsInadimplentes)
                                }
                                {/* {
                                    this.state.inPrint ? (
                                        this.filtrar(this.state.inadimplencia).map(this.listarOsInadimplentes)

                                    ) : (
                                        this.state.inadimplencia.map(this.listarOsInadimplentes)
                                    )
                                } */}
                            </tbody>
                        </table>
                    </div>    

                    {
                        this.state.inPrint ? (null) : (
                            this.state.inadimplencia.length > 0 && 
                            <div className='pagination'>
                                {this.state.pagina === 1 ? null : (
                                    <div className='pagination__arrow' onClick={() => {
                                        this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                    }}>
                                        {'<'}
                                    </div>
                                )}
                                {this.state.pagina === 1 || this.state.pagina === 2 ? null : (
                                    <div className="pagination__arrow ml-4" onClick={() => {
                                        this.setState({pagina: 1})
                                    }}>
                                        {'1'}
                                    </div>
                                )}

                                <div className="pagination__numbers">
                                    {
                                        [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                                (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                            ){
                                                return (
                                        <p className={this.state.pagina === index + 1 ? 'pagination__index' : ''} onClick={() => {
                                            this.setState({pagina: index + 1})
                                        }}>
                                            {index + 1}
                                        </p>
                                                )
                                            }
                                            else{
                                                return null
                                            }
                                        })
                                    }
                                </div>
                                {this.state.pagina === this.state.quantidade_paginas || this.state.pagina === (this.state.quantidade_paginas -1) ? null : (
                                    <div className="pagination__arrow mr-4" onClick={() => {
                                        this.setState({pagina: this.state.quantidade_paginas})
                                    }}>
                                        {this.state.quantidade_paginas}
                                    </div>
                                )}
                                {this.state.pagina === this.state.quantidade_paginas ? null : (
                                    <div className="pagination__arrow" onClick={() => {
                                        this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                                    }}>
                                        {'>'}
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
}

export default RelatorioInadimplencia;
