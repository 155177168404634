import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

const ModalErroGerarBoletoEmMassa = (props) => {
  const [permissao, setPermissao] = useState(
    jwt_decode(localStorage.getItem("autenticado_freedom")).permissao_tipo ===
      "ReadWriteMaster"
      ? true
      : false
  );

  useEffect(() => {
    console.log(props);
  }, []);

  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div
          style={{
            width: "70%",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: "5px",
            display: "block",
            position: "relative",
            marginTop: "2%",
            marginBottom: "2%"
          }}
        >
          <div className="modal-head">Erro ao gerar boletos</div>
          <div className="modal-body">
            <div className="modal-scroll">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div class="thead__item">
                        <label>Contrato ID</label>
                      </div>
                    </th>
                    <th>
                      <div class="thead__item">
                        <label>Financeiro ID</label>
                      </div>
                    </th>
                    <th>
                      <div class="thead__item">
                        <label>Imobiliária ID</label>
                      </div>
                    </th>
                    <th>
                      <div class="thead__item">
                        <label>Erro</label>
                      </div>
                    </th>
                    {permissao ? (
                      <th>
                        <div class="thead__item">
                          <label>Payload</label>
                        </div>
                      </th>                        
                    ) : null}
                    <th>
                      <div class="thead__item">
                        <label>URL Boleto</label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.erros.map((item, index) => (
                    <tr key={index}>
                      <td>{item.contrato_id}</td>
                      <td>{item.financeiro_id}</td>
                      <td>{item.imobiliaria_id} </td>
                      <td>{item.erro}</td>
                      {permissao ? (
                        <td>{item.payload}</td>                        
                      ) : null}
                      <td>{item.url_boleto}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={props.fechar}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalErroGerarBoletoEmMassa;
