import React, { useEffect, useState } from "react";
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'
import AdicionarMunicipio from "./AdicionarMunicipio";
import AlterarMunicipio from './AlterarMunicipio';

const MunicipiosDimob = (props) => {

  const [dadosLogin, setDadosLogin] = useState(jwt_decode(localStorage.getItem('autenticado_freedom')));
  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [modalAdicionar, setModalAdicionar] = useState("");
  const [modalAlterar, setModalAlterar] = useState("");

  const GetMunicipios = async () => {
    setLoading(true);
    setMensagem("Carregando ....")
    try {
      const response = await api_freedom.get(`/municipiosdimob/all`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}});

      if(response.status === 200){
        if(response.data.data.length === 0) {
          setMensagem("Nenhum registro encontrado.")
        }
        setMunicipios(response.data.data)
        setLoading(false);
      } else {
        setMensagem("Nenhum registro encontrado.")
        setLoading(false);
      }
    } catch (error) {
      setMensagem("Nenhum registro encontrado.")
      setLoading(false);
    }
  }

  const fechalModal = () => {
    setModalAdicionar("")
    setModalAlterar("")
  }

  useEffect(() => {
    GetMunicipios();
  }, []);
  
  return (
    <>
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between', margin: '0px 0px 10px 0px'}}>
          <div>
              <button class="button btn__primary" onClick={() => {
                setModalAdicionar(
                <AdicionarMunicipio 
                  fechar={fechalModal}
                  atualizar={GetMunicipios}
                />)
              }}>
                  Adicionar Município
              </button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                  <div class="thead__item">
                      <label>ID</label>
                  </div>
              </th>
              <th>
                  <div class="thead__item">
                      <label>Município</label>
                  </div>
              </th>
              <th>
                  <div class="thead__item">
                      <label>Estado</label>
                  </div>
              </th>
              <th>
                  <div class="thead__item">
                      <label>Cód. Dimob</label>
                  </div>
              </th>
              <th>
                  <div class="thead__item">
                      <label>Ações</label>
                  </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading || municipios.length === 0 ? (
              <tr><th className='text-center' colSpan='8'>{mensagem}</th></tr>
              ) : (municipios.map((item, index) => (
                <tr key={index}>
                  <td className="tbody__text">{item.id}</td>
                  <td className="tbody__text">{item.municipio}</td>
                  <td className="tbody__text">{item.estado}</td>
                  <td className="tbody__text">{item.codigo}</td>
                  <td>
                    <button
                      className="btn__primary--outline button"
                      onClick={() => {
                        setModalAlterar(
                          <AlterarMunicipio 
                            municipio={item}
                            fechar={fechalModal}
                            atualizar={GetMunicipios}
                          />
                        )
                      }}
                    >
                      Alterar
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {modalAdicionar && modalAdicionar}
        {modalAlterar && modalAlterar}
      </div>
    </>
  )
}

export default MunicipiosDimob