import React, { useState, useEffect } from "react";
import { api_freedom } from "../services/apis";
import moment from "moment"
import ModalLogTecnico from './ModalLogTecnico';
import jwt_decode from 'jwt-decode'

const LogsPage = () => {
  const [loading, setLoading] = useState(true);
  const [mensagem, setMensagem] = useState("Selecione uma das opções de pesquisa");
  const [logs, setLogs] = useState([]);
  const [contrato, setContrato] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [modalLogTecnico, setModalLogTecnico] = useState("");

  const GetLogs = async () => {
    const dateIni = new Date(dataInicio)
    const dateFim = new Date(dataFim)
    if(dateIni.getTime() > dateFim.getTime()) {alert("Data fim deve ser maior que data início"); return 0;}
    if(dataInicio && !dataFim) {alert("Data fim deve ser informada"); return 0;}
    if(!dataInicio && dataFim) {alert("Data início deve ser informada"); return 0;}
    setLoading(true);
    setMensagem("Carregando ....");
    try {
      const response = await api_freedom.get(`/logs/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, {headers: {"authorization": localStorage.getItem("autenticado_freedom")},
      params:{
        contrato_id: contrato ? contrato : null,
        data_inicio: dataInicio ? dataInicio : null,
        data_fim: dataFim ? dataFim : null
      }})

      if (response.status === 200) {
        if (response.data.data.length === 0) {
          setMensagem("Nenhum registro encontrado.");
        }
        setLogs(response.data.data);
        // console.log(response.data.data);
        setLoading(false);
      } else {
        setMensagem("Nenhum registro encontrado.");
        setLoading(false);
      }
    } catch (error) {
      setMensagem("Nenhum registro encontrado.");
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <p className="relatorio-title">Logs do sistema</p>
          </div>
          <div className="d-flex">
            <div class="grupo__campo ml-2">
              <label>Contrato:</label>
              <input type="text" class="grupo__input" onChange={(e) => setContrato(e.target.value)} />
            </div>
            <div class="grupo__campo ml-2">
              <label>Início:</label>
              <input type="date" class="grupo__input" onChange={(e) => setDataInicio(e.target.value)} />
            </div>
            <div class="grupo__campo ml-2">
              <label>Fim:</label>
              <input type="date" class="grupo__input" onChange={(e) => setDataFim(e.target.value)} />
            </div>
            <div class="grupo__campo ml-2">
              <button
                className="button btn__primary mx-2 mt-5"
                style={{ borderRadius: 30 }}
                onClick={() => GetLogs()}
                >    
                  <i style={{ fontSize: "0.9em" }} className="fas fa-search" />
                {" "}Pesquisar
              </button>
            </div>
          </div>
        </div>

        <div className="table mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Contrato</th>
                <th>Usuário</th>
                <th>Rota</th>
                <th>Ação</th>
                {/* <th>Historico</th> */}
                {/* <th>Mudanças</th> */}
                <th>Log Técnico</th>
              </tr>
            </thead>
            <tbody>
                {loading || logs.length === 0 ? (
                  <tr><th className='text-center' colSpan='8'>{mensagem}</th></tr>
                  ) : (logs.map((item, index) => (
                    <tr key={index}>
                      <td className="tbody__text">{moment(item.moment).format("DD/MM/YYYY - HH:mm:ss")}</td>
                      <td className="tbody__text">{item.contrato_id}</td>
                      <td className="tbody__text">{item.user_id}</td>
                      <td className="tbody__text">{item.route}</td>
                      <td className="tbody__text">{item.tipo_acao}</td>
                      {/* <td className="tbody__text">{
                        item.historic.match(/requisicao_fitbank(.*)}]}/i) ? 
                        item.historic.replace(item.historic.match(/requisicao_fitbank(.*)}]}/i)[0],"").replace("[{","").replace("}]","").replaceAll('"',"").replaceAll(":",": ").replaceAll(",",", ").slice(0, -2) :
                        item.historic.replace("[{","").replace("}]","").replaceAll('"',"").replaceAll(":",": ").replaceAll(",",", ")
                      }</td> */}
                      {/* <td className="tbody__text">{
                        item.objectQuery.match(/requisicao_fitbank(.*)}]}/i) ? 
                        item.objectQuery.replace(item.objectQuery.match(/requisicao_fitbank(.*)}]}/i)[0],"").replace("[{","").replace("}]","").replaceAll('"',"").replaceAll(":",": ").replaceAll(",",", ").slice(0, -2) :
                        item.objectQuery.replace("[{","").replace("}]","").replaceAll('"',"").replaceAll(":",": ").replaceAll(",",", ")
                      }</td> */}
                      <td className="tbody__text">
                        <button className='btn__freedom btn--normal' style={{marginTop: '-1px', width: '30px', height: '23px', marginLeft: '20px'}}
                          onClick={() => {
                            setModalLogTecnico(<ModalLogTecnico log={item} fechar={() => setModalLogTecnico("")} />)
                          }} 
                          ><i className='fas fa-book'/>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
            </tbody>
          </table>
        </div>
      </div>
      {modalLogTecnico && modalLogTecnico}
    </>
  );
};

export default LogsPage;
