
import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'
import moment from 'moment'

class ContratosAvencer extends React.Component {

  constructor(props) {
    super(props)
    if(!localStorage.getItem('autenticado_freedom')){
        props.history.push('/')
    } 

    this.state = {
        dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
        contratos: [],
        mensagem: 'Carregando ....',
        total_contratos: 0,
        buscar_contrato: '',
        buscar_imovel: '',
        buscar_proprietarios: '',
        buscar_locatario: '',
        buscar_inicio: '',
        buscar_termino: '',
        buscar_aluguel: '',
        pagina: 1
    }
     
    this.quantidade_paginas = 1

  }

  componentDidMount(){
    this.contratos()
  }

  contratos = async () => {
    try{
        let contratos = await api_freedom.get(`/contrato/idimobiliaria/${this.state.dados_login.imobiliaria_id}/1`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        if(contratos.data.data.indexOf('TOKEN') === -1){
            this.setState({ contratos: contratos.data.data, 
                mensagem: 'Nenhum registro encontrado.',
                total_contratos: contratos.data.total_contratos
            })

            this.quantidade_paginas = Math.ceil(contratos.data.total_contratos / 10) 

            for(var i = 2; i <= Math.ceil(contratos.data.total_contratos / 10); i++){
                let contratos_page = await api_freedom.get(`/contrato/idimobiliaria/${this.state.dados_login.imobiliaria_id}/${i}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
                this.setState({contratos: this.state.contratos.concat(contratos_page.data.data)})
            }
        }
    }
    catch(error){
        console.log(error.message)
    }
  }

  listarContratos = (record, index) => {
    var class_name = {};
    if (record.ativo === 0) {
        class_name = {fontWeight: 'normal', "textDecoration": "line-through"}
    }
    
    return (
        <tr style={class_name} key={index}>
            <td>{record.id}</td>
            <td>{record.pessoas.filter((p) => {
                return p.tipo === 'Locatário'
            }).map((locatario) => {
                return <div key={locatario.cpf_cnpj}><label style={class_name}>{`${locatario.nome} | ${locatario.cpf_cnpj}`}</label><br/></div>
            })}</td>
            <td>{record.valor_aluguel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
            <td>Indície</td>
            <td>Valor Corrigido</td>
        </tr>
    )
  }

  filtrar = (contratos) => {
    var filtro = contratos.filter((c) => {
        return this.filtrarContrato(c)
    })

    this.quantidade_paginas = Math.ceil(filtro.length / 10)

    return filtro
  }

  filtrarContrato = (contrato) => {
    return !this.state.buscar_contrato || (contrato.id && contrato.id.toString().toLowerCase().includes(this.state.buscar_contrato))
  }

  
    disable() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

  render() {
    return (
      <>
        <div>
            {this.state.contratos.length > 0 && 
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        Contrato: <input type='text' value={this.state.buscar_contrato} onChange={(event) => {
                            this.setState({buscar_contrato: event.target.value, pagina: 1})
                        }}/>
                    </div>    
                </div>
            }
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Contrato</th>
                            <th>Locatário</th>
                            <th>Valor Atual</th>
                            <th>Indície</th>
                            <th>Valor Corrigido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.filtrar(this.state.contratos).length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                        {this.filtrar(this.state.contratos).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(this.listarContratos)}
                    </tbody>
                </table>
            </div>    
            {this.filtrar(this.state.contratos).length > 0 && <div className='box-paginacao'>
                <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                    this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                }}>
                    {'<'}
                </div>
                {
                    [...Array(this.quantidade_paginas)].map((pagina, index) => {
                        if( (this.state.pagina === 1 && index + 1 < 4) || 
                            (this.state.pagina === this.quantidade_paginas && index + 1 > this.quantidade_paginas - 3) ||
                            (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                        ){
                            return (
                                <div key={index+1} className='paginacao text-center p-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#f2f2f2' : null, cursor: 'pointer'}}
                                onClick={() => {
                                    this.setState({pagina: index + 1})
                                }}>
                                    {index + 1}
                                </div>
                            )
                        }
                        else{
                            return null
                        }
                    })
                }
                <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                    this.setState({pagina: this.state.pagina < this.quantidade_paginas ? this.state.pagina + 1 : this.quantidade_paginas})
                }}>
                    {'>'}
                </div>
            </div>}
        </div>
      </>
    );
  }
}

export default ContratosAvencer;
