const calcularImpostoLocador = (percentual, valorAluguel, despesasDedutiveis, nomeLocador) => {
    // Alíquotas e parcelas a deduzir
    const tabelaIRPF = [
        { limite: 2112.00, aliquota: 0, parcelaDeduzir: 0 },
        { limite: 2826.65, aliquota: 0.075, parcelaDeduzir: 158.4 },
        { limite: 3751.06, aliquota: 0.15, parcelaDeduzir: 370.4 },
        { limite: 4664.68, aliquota: 0.225, parcelaDeduzir: 661.73 },
        { limite: Infinity, aliquota: 0.275, parcelaDeduzir: 884.96 }
    ];

    // Soma total dos valores recebidos pelos locadores
    const somaTotal = valorAluguel * percentual / 100;

    // Base de cálculo
    const baseCalculo = somaTotal - despesasDedutiveis;

    // Alíquota correspondente
    let aliquota = 0;
    let parcelaDeduzir = 0;
    for (let i = 0; i < tabelaIRPF.length; i++) {
        if (baseCalculo <= tabelaIRPF[i].limite) {
            aliquota = tabelaIRPF[i].aliquota;
            parcelaDeduzir = tabelaIRPF[i].parcelaDeduzir;
            break;
        }
    }

    // Imposto devido
    const impostoDevido = (baseCalculo * aliquota) - parcelaDeduzir;

    // Valor que o locador deve pagar
    const valorLocadorPagar = impostoDevido;

    // Retornar objeto JSON com os resultados
    return {
        resultadoIR: "Retenção de IR, " + nomeLocador + '; Valor R$' + valorLocadorPagar.toFixed(2).replace('.', ','),
        aliquotaIR: Number((aliquota * 100).toFixed(2)),
        valorPagarIR: Number(valorLocadorPagar.toFixed(2)),
        valorAluguelIR: Number(valorAluguel.toFixed(2))
    };
}

export default calcularImpostoLocador;