
import React from "react"
import {api_freedom} from '../services/apis'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import ModalErroGerarBoletoEmMassa from "./ModalErroGerarBoletoEmMassa"

class GerarBoletos extends React.Component {

  constructor(props) {
    super(props)
    if(!localStorage.getItem('autenticado_freedom')){
        props.history.push('/')
    } 

    this.state = {
        data_inicio: moment().format('YYYY-MM-DD'),
        data_fim: moment().add(1, 'months').format('YYYY-MM-DD'),
        mes_ano: moment().format('YYYY-MM'),
        boletos: [],
        mensagem: 'Carregando ....',
        total_boletos: 0,
        modal_titulo: '',
        modal_corpo: '',
        modal_botoes: '',
        blockBtn: false,
        modalErroGerarBoleto: ''
    }

  }

  componentDidMount(){
    this.boletos(moment().format('YYYY-MM-DD'), moment().add(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM')) 
  }

  boletos = async (inicio, fim, mes_ano) => {
    try{
        let boletos = await api_freedom.post(`/financeiro/vencimentomes`,
            {
                data_inicio: inicio,
                data_fim: fim,
                mes_ano: mes_ano,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
            },
            {headers: {"authorization": localStorage.getItem('autenticado_freedom')}
        })
        if(boletos.data.data.indexOf('TOKEN') === -1){
            if(boletos.data.data.filter((b) => {
                return b.nome === 'Aluguel'
            }).length === 0){
                this.fecharModal()
            } 
            this.setState({ boletos: boletos.data.data,
                            mensagem: 'Nenhum registro encontrado.',
                            total_boletos: boletos.data.data.filter((b) => {
                                return b.nome === 'Aluguel'
                            }).length
            })
        }
    }
    catch(error){
        console.log(error.message)
    }
  }


  listarBoletos = (record, index) => {
    var valor_boleto = 0

    this.state.boletos.filter((p) => {
        return p.contrato_id === record.contrato_id && p.gerar_boleto === 1 && p.mes_ano === record.mes_ano
    }).map((f) => {
        if(f.credito_debito === 1){
            valor_boleto += f.valor
        } 
        else{
            valor_boleto -= f.valor
        }
        return null
    })

    return (
        <tr key={index}>
            <td>{record.contrato_id}</td>
            <td>{record.id}</td>
            <td>{this.capitalize(new Date(record.mes_ano).toLocaleString('pt-BR', {month: 'long'}))} </td>
            <td>{moment(record.data_vencimento).utc(false).format('DD/MM/YYYY')}</td>
            <td>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
            <td>{record.boleto_valor ? record.boleto_valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                <button className='ml-3' style={{border: '1px solid #6b24d6', borderRadius: '2px', padding: '0px 10px', backgroundColor: '#6b24d6', color: 'white'}} 
                onClick={() => {this.props.history.push(`/freedom/detalheparcela/${record.contrato_id}/${record.id}`)}}> ? </button>
            </td>
            <td>{record.boleto_vencimento ? moment(record.boleto_vencimento).utc(false).format('DD/MM/YYYY') : 'Não gerado' }</td>
            <td>{record.pago ? `Sim, dia ${moment(record.pago).utc(false).format('DD/MM/YYYY')}` : `Não`}</td>
        </tr>
    )
  }

  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
   
  abrirModal = () => {
    var modal = document.getElementById('modal');
    modal.style.display = "flex";
  }

  fecharModal = () => {
    var modal = document.getElementById('modal');
    modal.style.display = "none";
  }

  fecharModalErro = () => {
    this.setState({ modalErroGerarBoleto: '' })
  }

  boletosGerar = async () => {
    if (this.state.blockBtn) return
    document.querySelector("#gen_boletos").disabled = true
    this.state.blockBtn = true

    this.setState({
        modal_titulo:   `Gerando boletos`,
        modal_corpo:    <div className='text-center' style={{padding: '2vw', fontSize: '1.1em'}}>
                            AGUARDE....
                        </div>,
        modal_botoes:   <></>
    })
    this.abrirModal()
    // this.state.boletos.filter((boleto) => {
    //     return boleto.nome === 'Aluguel'
    // }).map((b) => {
    await this.gerarBoleto()
    //     return null
    // })
    document.querySelector("#gen_boletos").disabled = false
    this.state.blockBtn = false
    this.fecharModal()
  }

  gerarBoleto = async () => {
    try{
        // let financeiro = await api_freedom.post(`/boleto/gerar`, {
        let financeiro = await api_freedom.post(`/financeiro/gerarboleto`, {
            data_inicio: this.state.data_inicio,
            data_fim: this.state.data_fim,
            mes_ano: this.state.mes_ano,
            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(financeiro.data.data.length > 0){
            this.boletos(this.state.data_inicio, this.state.data_fim, this.state.mes_ano)
        }
        if(financeiro.data.erro.length > 0){

            console.log(financeiro.data);

            this.setState({
                modalErroGerarBoleto: <ModalErroGerarBoletoEmMassa
                erros={financeiro.data.erro}
                fechar={this.fecharModalErro}
                />
            })
        }
    }catch(error){
        console.log(error.message)
    }
  }

  disable_button() {
    if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
        return true
    } else {
        return false
    }
  }

  render() {
    return (
      <>
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <div style={{padding: '0.2vw'}} className="grupo__campo">
                        Data inicial: 
                        <div className="grupo__campo__form grupo__campo--input--select">
                            <input type='date' style={{width: '150px'}} defaultValue={moment().format('YYYY-MM-DD')} 
                            onChange={(event) => {
                                this.setState({data_inicio: event.target.value, data_fim: moment(event.target.value).add(1, 'months').format('YYYY-MM-DD')})
                            }} />
                        </div>
                    </div>
                    {
                        this.state.data_inicio &&
                        <div style={{padding: '0.2vw'}} className="grupo__campo">
                            Data final:
                            <div className="grupo__campo__form grupo__campo--input--select">
                                <input style={{width: '150px'}} type='date' value={this.state.data_fim} 
                                    max={moment(this.state.data_inicio).add(1, 'months').format('YYYY-MM-DD')}
                                    min={moment(this.state.data_inicio).format('YYYY-MM-DD')}
                                    onChange={(event) => {
                                        this.setState({data_fim: event.target.value})
                                    }} 
                                />
                            </div>
                            
                        </div>
                    }
                    {
                        this.state.data_inicio &&
                        <div style={{padding: '0.2vw'}}  className="grupo__campo">
                            Mês e ano de cobrança: 
                            <div className="grupo__campo__form grupo__campo--input--select">
                                <input style={{width: '150px'}} type='month' value={this.state.mes_ano} 
                                    onChange={(event) => {
                                        this.setState({mes_ano: event.target.value})
                                    }} 
                                />
                            </div>
                        </div>
                    }
                    {
                        this.state.data_inicio &&
                        <div style={{padding: '0.2vw'}}>
                            <button className='btn__primary button'
                                onClick={(event) => {
                                    if(new Date(moment(this.state.data_inicio).add(1, 'months').format('YYYY-MM-DD')) >= new Date(moment(this.state.data_fim).format('YYYY-MM-DD'))){
                                        this.boletos(this.state.data_inicio, this.state.data_fim, this.state.mes_ano)
                                    }
                                    else{
                                        alert('Período execede 1 mês')
                                    }
                                }} 
                            >Filtrar</button>
                        </div>
                    }
                </div>
                {
                    this.state.total_boletos > 0 && 
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button hidden={this.disable_button()} className='btn__primary button'
                        id="gen_boletos"
                        onClick={() => {
                            this.setState({
                                modal_titulo:   `Gerar boletos`,
                                modal_corpo:    <div className='text-center' style={{padding: '2vw', fontSize: '1.1em'}}>
                                                    Deseja realmente prosseguir? <br/>Serão gerados {this.state.total_boletos} boletos
                                                </div>,
                                modal_botoes:   <>
                                                    <button className='button btn__primary--outline' onClick={() => {this.fecharModal()}}>Não</button>
                                                    <button className='button btn__primary' onClick={() => {this.fecharModal(); this.boletosGerar()}}>Sim</button>
                                                </>
                            })
                            this.abrirModal()
                        }}> Gerar boletos </button>
                    </div>
                }
            </div>    
            <div style={{padding: '1.3vw', marginBottom: '-20px'}}>
                <label>Total de boletos: {this.state.total_boletos}</label>
            </div>
            <div className='table mt-4'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                <div class="thead__item">
                                    <label># Contrato</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Parcela</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Mês referente</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Vencimento</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Valor do aluguel</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Valor do boleto</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Vencimento do boleto</label>
                                </div>
                            </th>
                            <th>
                                <div class="thead__item">
                                    <label>Pago?</label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.total_boletos === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                        {this.state.boletos.filter((p) => {
                            return p.nome === 'Aluguel'
                        }).map(this.listarBoletos)}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="content-modal" id="modal" style={{display: 'none'}}>
            <div className="modal">
                <div className="modal-head">
                    {this.state.modal_titulo}
                </div>
                <div className="modal-body">
                    <div className='modal-scroll'>
                        {this.state.modal_corpo}
                    </div>
                </div>
                <div className="modal-foot">
                    {this.state.modal_botoes}
                </div>
            </div>
        </div>
        {this.state.modalErroGerarBoleto && this.state.modalErroGerarBoleto}
      </>
    );
  }
}

export default GerarBoletos;
